const balanceContent = (params) => {};

const balanceObjects = {
  categories: [
    {
      name: 'Hidráulica',
      color: '#0090d1',
      legendtype: 'circle',
      order: 11,
      alias: false,
      indicators: [
        10063, 10064, 10066, 10067, 10068, 10069, 10070, 10071, 10072, 10271,
        10065,
      ],
    },
    {
      name: 'Eólica',
      color: '#6FB114',
      legendtype: 'circle',
      order: 9,
      alias: false,
      indicators: [
        10010, 10073, 10159, 10160, 10161, 10162, 10163, 10164, 10165, 10281,
        10158,
      ],
    },
    {
      name: 'Solar fotovoltaica',
      color: '#e48500',
      legendtype: 'circle',
      order: 8,
      alias: false,
      indicators: [84, 14, 119, 154, 189, 224, 259, 294, 329, 1413, 49],
    },
    {
      name: 'Solar térmica',
      color: '#ff0000',
      legendtype: 'circle',
      order: 7,
      alias: false,
      indicators: [85, 15, 120, 155, 190, 225, 260, 295, 330, 1414, 50],
    },
    {
      name: 'Otras renovables',
      color: '#8C5DA2',
      legendtype: 'circle',
      order: 5,
      alias: true,
      indicators: [
        10013, 10074, 10177, 10178, 10179, 10180, 10181, 10182, 10183, 10283,
        10176,
      ],
    },
    {
      name: 'Hibridación',
      color: '#54b8be',
      legendtype: 'circle',
      order: 6,
      alias: true,
      indicators: [
        2131, 2132, 2133, 2134, 2135, 2136, 2137, 2138, 2139, 2140, 2141,
      ],
    },
    {
      name: 'Nuclear',
      color: '#464394',
      legendtype: 'circle',
      order: 16,
      alias: false,
      indicators: [74, 4, 109, 144, 179, 214, 249, 284, 319, 1403, 39],
    },
    {
      name: 'Ciclo combinado',
      color: '#ffcc66',
      legendtype: 'circle',
      order: 13,
      alias: false,
      indicators: [79, 9, 114, 149, 184, 219, 254, 289, 324, 1408, 44],
    },
    {
      name: 'Carbón',
      color: '#ad5c34',
      legendtype: 'circle',
      order: 14,
      alias: false,
      indicators: [
        10008, 10167, 10169, 10170, 10171, 10172, 10173, 10174, 10175, 10282,
        10168,
      ],
    },
    {
      name: 'Fuel-gas',
      color: '#BA0F16',
      legendtype: 'circle',
      order: 14,
      alias: false,
      indicators: [
        10009, 10077, 10079, 10080, 10081, 10082, 10083, 10084, 10085, 10272,
        10078,
      ],
    },
    {
      name: 'Cogeneración',
      color: '#CFA2CA',
      legendtype: 'circle',
      order: 12,
      alias: false,
      indicators: [
        10011, 10086, 10088, 10089, 10090, 10091, 10092, 10093, 10094, 10273,
        10087,
      ],
    },
    {
      name: 'Residuos no renovables',
      color: '#A0A0A0',
      legendtype: 'circle',
      order: 4,
      alias: false,
      indicators: [
        10012, 10095, 10097, 10098, 10099, 10100, 10101, 10102, 10103, 10274,
        10096,
      ],
    },
    {
      name: 'Turbinación bombeo',
      color: '#007CF9',
      legendtype: 'circle',
      order: 10,
      alias: false,
      indicators: [73, 3, 108, 143, 178, 213, 248, 283, 318, 1402, 38],
    },
    {
      name: 'Consumo bombeo',
      color: '#3670B6',
      legendtype: 'circle',
      order: 3,
      alias: false,
      indicators: [95, 25, 130, 165, 200, 235, 270, 305, 340, 1424, 60],
    },
    {
      name: 'Entrega Baterías',
      color: '#9BC1DB',
      legendtype: 'circle',
      order: 15,
      alias: true,
      indicators: [
        2143, 2144, 2145, 2146, 2147, 2148, 2149, 2150, 2151, 2152, 2153,
      ],
    },
    {
      name: 'Carga Baterías',
      color: '#006699',
      legendtype: 'circle',
      order: 21,
      alias: true,
      indicators: [
        2154, 2155, 2156, 2157, 2158, 2159, 2160, 2161, 2162, 2163, 2164,
      ],
    },
    {
      name: 'Enlace Baleares',
      color: '#EB6539',
      legendtype: 'circle',
      order: 2,
      alias: false,
      indicators: [96, 26, 131, 166, 201, 236, 271, 306, 341, 1425, 61],
    },
    {
      name: 'Saldo Francia',
      color: '#3CB375',
      legendtype: 'circle',
      order: 20,
      alias: false,
      indicators: [
        10015, 10104, 10106, 10107, 10108, 10109, 10110, 10111, 10112, 10275,
        10105,
      ],
    },
    {
      name: 'Saldo Portugal',
      color: '#AA7BCB',
      legendtype: 'circle',
      order: 19,
      alias: false,
      indicators: [
        10014, 10113, 10115, 10116, 10117, 10118, 10119, 10120, 10121, 10276,
        10114,
      ],
    },
    {
      name: 'Saldo Marruecos',
      color: '#A0546D',
      legendtype: 'circle',
      order: 18,
      alias: false,
      indicators: [
        10016, 10122, 10124, 10125, 10126, 10127, 10128, 10129, 10130, 10277,
        10123,
      ],
    },
    {
      name: 'Saldo Andorra',
      color: '#A2D468',
      legendtype: 'circle',
      order: 17,
      alias: false,
      indicators: [
        10017, 10131, 10133, 10134, 10136, 10137, 10138, 10139, 10140, 10278,
        10132,
      ],
    },
    {
      name: 'Ajuste de programas',
      color: '#8C0000',
      legendtype: 'circle',
      order: 1,
      alias: true,
      indicators: [
        10025, 10196, 10198, 10199, 10200, 10201, 10202, 10203, 10204, 10285,
        10197,
      ],
    },
    {
      name: 'Demanda Peninsular',
      color: '#00D7D9',
      legendtype: 'line',
      order: 30,
      alias: false,
      indicators: [
        10027, 10141, 10143, 10144, 10145, 10146, 10147, 10148, 10149, 10279,
        10142,
      ],
    },
  ],
  programs: [
    {
      name: 'P48',
      indicators: [
        10063, 10010, 84, 85, 10013, 2131, 74, 79, 10008, 10009, 10011, 10012,
        73, 95, 2143, 2154, 10464, 96, 10015, 10014, 10016, 10017, 10026, 10025,
        10027,
      ],
    },
    {
      name: 'PBF',
      indicators: [
        10064, 10073, 14, 15, 10074, 2132, 4, 9, 10167, 10077, 10086, 10095, 3,
        25, 2144, 2155, 10465, 26, 10104, 10113, 10122, 10131, 10186, 10196,
        10141,
      ],
    },
    {
      name: 'PHF1',
      indicators: [
        10066, 10159, 119, 120, 10177, 2133, 109, 114, 10169, 10079, 10088,
        10097, 108, 130, 2145, 2156, 10467, 131, 10106, 10115, 10124, 10133,
        10188, 10198, 10143,
      ],
    },
    {
      name: 'PHF2',
      indicators: [
        10067, 10160, 154, 155, 10178, 2134, 144, 149, 10170, 10080, 10089,
        10098, 143, 165, 2146, 2157, 10468, 166, 10107, 10116, 10125, 10134,
        10189, 10199, 10144,
      ],
    },
    {
      name: 'PHF3',
      indicators: [
        10068, 10161, 189, 190, 10179, 2135, 179, 184, 10171, 10081, 10090,
        10099, 178, 200, 2147, 2158, 10469, 201, 10108, 10117, 10126, 10136,
        10190, 10200, 10145,
      ],
    },
    {
      name: 'PHF4',
      indicators: [
        10069, 10162, 224, 225, 10180, 2136, 214, 219, 10172, 10082, 10091,
        10100, 213, 235, 2148, 2159, 10470, 236, 10109, 10118, 10127, 10137,
        10191, 10201, 10146,
      ],
    },
    {
      name: 'PHF5',
      indicators: [
        10070, 10163, 259, 260, 10181, 2137, 249, 254, 10173, 10083, 10092,
        10101, 248, 270, 2149, 2160, 10471, 271, 10110, 10119, 10128, 10138,
        10192, 10202, 10147,
      ],
    },
    {
      name: 'PHF6',
      indicators: [
        10071, 10164, 294, 295, 10182, 2138, 284, 289, 10174, 10084, 10093,
        10102, 283, 305, 2150, 2161, 10472, 306, 10111, 10120, 10129, 10139,
        10193, 10203, 10148,
      ],
    },
    {
      name: 'PHF7',
      indicators: [
        10072, 10165, 329, 330, 10183, 2139, 319, 324, 10175, 10085, 10094,
        10103, 318, 340, 2151, 2162, 10473, 341, 10112, 10121, 10130, 10140,
        10194, 10204, 10149,
      ],
    },
    {
      name: 'PHFC',
      indicators: [
        10271, 10281, 1413, 1414, 10283, 2140, 1403, 1408, 10282, 10272, 10273,
        10274, 1402, 1424, 2152, 2163, 10474, 1425, 10275, 10276, 10277, 10278,
        10284, 10285, 10279,
      ],
    },
    {
      name: 'PVP',
      indicators: [
        10065, 10158, 49, 50, 10176, 2141, 39, 44, 10168, 10078, 10087, 10096,
        38, 60, 2153, 2164, 10466, 61, 10105, 10114, 10123, 10132, 10187, 10197,
        10142,
      ],
    },
  ],
  totalCategories: [
    {
      alias: 'Demanda Peninsular',
      classId: 'total',
      ids: [
        10027, 10141, 10143, 10144, 10145, 10146, 10147, 10148, 10149, 10279,
        10142,
      ],
    },
    {
      alias: 'Almacenamiento total',
      classId: 'total',
      ids: [
        10464, 10465, 10466, 10467, 10468, 10469, 10470, 10471, 10472, 10473,
        10474,
      ],
    },
    {
      alias: 'Saldo interconexiones',
      classId: 'total',
      ids: [
        10026, 10186, 10188, 10189, 10190, 10191, 10192, 10193, 10194, 10284,
        10187,
      ],
    },
    { alias: 'Total Generación', classId: 'total' },
  ],
  orderList: {
    P48: [
      10014, 10015, 10017, 10016, 95, 96, 74, 10009, 10008, 79, 10011, 10063,
      73, 10010, 84, 85, 10013, 10012, 10025, 10026, 10027, 2131, 2143, 2154,
      10464,
    ],
    PBF: [
      10113, 10104, 10131, 10122, 25, 26, 4, 10077, 10167, 9, 10086, 10064, 3,
      10073, 14, 15, 10074, 10095, 10196, 10186, 10141, 2132, 2144, 2155, 10465,
    ],
    PHF1: [
      10115, 10106, 10133, 10124, 130, 131, 109, 10079, 10169, 114, 10088,
      10066, 108, 10159, 119, 120, 10177, 10097, 10198, 10188, 10143, 2133,
      2145, 2156, 10467,
    ],
    PHF2: [
      10116, 10107, 10134, 10125, 165, 166, 144, 10080, 10170, 149, 10089,
      10067, 143, 10160, 154, 155, 10178, 10098, 10199, 10189, 10144, 2134,
      2146, 2157, 10468,
    ],
    PHF3: [
      10117, 10108, 10136, 10126, 200, 201, 179, 10081, 10171, 184, 10090,
      10068, 178, 10161, 189, 190, 10179, 10099, 10200, 10190, 10145, 2135,
      2147, 2158, 10469,
    ],
    PHF4: [
      10118, 10109, 10137, 10127, 235, 236, 214, 10082, 10172, 219, 10091,
      10069, 213, 10162, 224, 225, 10180, 10100, 10201, 10191, 10146, 2136,
      2148, 2159, 10470,
    ],
    PHF5: [
      10119, 10110, 10138, 10128, 270, 271, 249, 10083, 10173, 254, 10092,
      10070, 248, 10163, 259, 260, 10181, 10101, 10202, 10192, 10147, 2137,
      2149, 2160, 10471,
    ],
    PHF6: [
      10120, 10111, 10139, 10129, 305, 306, 284, 10084, 10174, 289, 10093,
      10071, 283, 10164, 294, 295, 10182, 10102, 10203, 10193, 10148, 2138,
      2150, 2161, 10472,
    ],
    PHF7: [
      10121, 10112, 10140, 10130, 340, 341, 319, 10085, 10175, 324, 10094,
      10072, 318, 10165, 329, 330, 10183, 10103, 10204, 10194, 10149, 2139,
      2151, 2162, 10473,
    ],
    PHFC: [
      10276, 10275, 10278, 10277, 1424, 1425, 1403, 10272, 10282, 1408, 10273,
      10271, 1402, 10281, 1413, 1414, 10283, 10274, 10285, 10284, 10279, 2140,
      2152, 2163, 10474,
    ],
    PVP: [
      10114, 10105, 10132, 10123, 60, 61, 39, 10078, 10168, 44, 10087, 10065,
      38, 10158, 49, 50, 10176, 10096, 10197, 10187, 10142, 2141, 2153, 2164,
      10466,
    ],
  },
  colorList: [
    '#AA7BCB',
    '#3CB375',
    '#A2D468',
    '#A0546D',
    '#23A2D8',
    '#FF8282',
    '#464394',
    '#ba0f16',
    '#ad5c34',
    '#ffcc66',
    '#00a150',
    '#0090d1',
    '#86c6e3',
    '#6fb124',
    '#e48500',
    '#ff0000',
    '#B4D490',
    '#717171',
    '#8c0000',
    '#00D7D9',
    '#00D7D9',
  ],
};

export { balanceContent, balanceObjects };
