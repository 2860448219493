import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { store } from '../app.init';
import statsImg from '../assets/images/stats.svg';
import GeoChart from '../pages/analysis/chart/analysis-geo-chart';
import {
  getArrayOfHoursForBalance,
  renderBalanceGraph,
  calculateHoursPerDay,
  renderBalanceErniGraph,
  renderGraph,
} from '../pages/balance/balance.component';
import { balanceObjects } from '../pages/balance/balance.content';
import { servicesBalance } from '../pages/balance/balance.services';
import { templateChart1 } from '../pages/charts-data/chart1.template';
import { templateChart17 } from '../pages/charts-data/chart17.template';
import { templateChart18 } from '../pages/charts-data/chart18.template';
import { templateChart2 } from '../pages/charts-data/chart2.template';
import { templateChart20 } from '../pages/charts-data/chart20.template';
import { templateChart21 } from '../pages/charts-data/chart21.template';
import {
  fillValues,
  gestionDemandaService,
} from '../pages/gestion-demanda/gestionDemanda.service';
import { gestionDemandaRenderContent } from '../pages/gestion-demanda/renderContent';
import {
  calculateSaldo,
  calculateSaldoMi,
  calculateSaldoMulti,
  fillPrintVals,
  fillSimpleVals,
} from '../pages/intercambios-internacionales/intercambiosInternacionales.component';
import { intercambiosInternacionalesContent } from '../pages/intercambios-internacionales/intercambiosInternacionalesContent';
import MapaIntercambiosInternacionales from '../pages/intercambios-internacionales/mapa.intercambios-internacionales';
import { servicesMercadosPrecios } from '../pages/mercados-precios/mercadosPrecios.services';
import { mercadosPreciosContent } from '../pages/mercados-precios/mercadosPreciosContent';
import { componentTab } from '../pages/tab/tab.component';
import { getColour } from '../shared/components/chart/chart-helpers';
import { componentSelects } from '../shared/components/selects/selects.component';
import { componentTabs } from '../shared/components/tabs/tabs.component';
import { componentTimeSelector } from '../shared/components/time-selector/time-selector.component';
import { MAGNITUD_SYMBOLS } from '../shared/enum/magnitud-symbols';
import { sRender } from './render';
import { getUrlParams } from './url';
import { Utils } from './utils';
import { TIME_VALUES } from '../shared/enum/time-values';
import TemplateEmbedIndicatorsProgramBalance from '../pages/embed-indicators/embed-indicators-complex-graphs/embed-indicators-program-balance.template';
import { processValues } from '../pages/mercados-precios/mercadosPreciosRenders';
import {
  proccessData,
  processPreData,
  renderColumnSelected,
  showTable,
  updateTable,
} from '../pages/saldo-fronteras/saldoFronteras.component';
import { saldoFronterasContent } from '../pages/saldo-fronteras/saldoFronterasContent';

/**
 * Charts es un objeto donde cada key es el id del widget y el value
 * es una funcion donde se declara toda las opciones del widget y se
 * invoca, esto esta hecho para poder reutilizar los widgets en la página
 * My Esios y no tener que duplicar el codigo
 */
let isImbedPage = window.location.pathname.includes('insertado');

let charts = {
  1: async (data) => {
    document.getElementById('widget1').innerHTML = '';

    document
      .getElementById('widget1')
      .insertAdjacentHTML('afterbegin', templateChart1());

    const piePotencia = (indicators, disponible) => {
      const filterPie = [472, 473, 474, 475, 476, 477, 478, 479];
      const colorPie = [
        '#0090d1',
        '#86c6e3',
        '#464394',
        '#ad5c34',
        '#9C7562',
        '#ffcc66',
        '#ba0f16',
        '#e66c71',
      ];
      /** LISTA DE DATA QUE SE RENDERIZARÁ EN EL PIE DE PONTENCIA Y EN LA LEYENDA */
      const pieDataRender = indicators
        .filter((el) => filterPie.includes(el.id))
        .map((elemnt, index) => {
          return {
            id: elemnt.id,
            composited: elemnt.composited,
            alias: elemnt.alias,
            name: elemnt.short_name,
            value: elemnt.values.length > 0 ? elemnt.values[0].y : '-',
            color: colorPie[index],
          };
        });
      /** CONFIGURACIÓN BASE PARA CREAR EL PIE */
      let configPie = {
        target: d3.select('#widget1 .piePotencia').node(),
        innerArrow: true,
        innerRadius: 0.6,
        hoverFade: 1,
      };

      /** INSTANCIA Y CREACIÓN DEL PIE */
      let pie = new Charicharts.Pie(configPie, pieDataRender);

      /* CREACIÓN DEL TEXTO CENTRAL EN EL PIE  */
      const piePotenciaHtml = document.querySelector('#widget1 .piePotencia');
      piePotenciaHtml.insertAdjacentHTML(
        'beforeend',
        `  
      <div class="chartLegend">
        <h3 class="titlePie"></h3>
        <div class="valuePie">
        </div>
      </div>`
      );

      /** OBTENEMOS LOS ELEMENTOS DEL TITULO Y EL VALOR PARA ACTUALZIARLOS CADA VEZ QUE REALIZEMOS UN HOVER POR EL PIE */
      let pieTitleHtml = document.querySelector(
        '#widget1 .piePotencia>.chartLegend>.titlePie'
      );
      let pieValueHtml = document.querySelector(
        '#widget1 .piePotencia>.chartLegend>.valuePie'
      );

      const hoverPie = (title, value, pie) => {
        /** FUNCION PORPIA DEL PIE PARA REALIZAR EL HOVER Y ACTUALIZAR EL TEXTO CENTRAL */
        pie.on('Pie-piece/mouseover', function (info) {
          title.innerText = info.data.name;
          value.innerHTML = `
        ${info.value === 0 ? '-' : Utils.toNumber(info.value.toFixed(1), 1)}
        <div class="unit">MW</div>
      `;
        });
      };

      hoverPie(pieTitleHtml, pieValueHtml, pie);

      /**CREACIÓN DE LEYENDA */
      let ulLegend = d3
        .select('#widget1 .legend-potencial-pie')
        .append('ul')
        .attr('class', 'legend')
        .style('margin-top', '30px');

      let keys = ulLegend
        .selectAll('.key')
        .data(pieDataRender)
        .enter()
        .append('li')
        .attr('class', 'legend-item');

      keys
        .html(
          (item, index) =>
            `
      <div style="display: flex; align-item:center; color: #444444">
      <span class="legend-circle" style="background: ${
        colorPie[index]
      }; dislpay: flex; height: 0.625rem; width: 0.625rem; border-radius: 50%"></span>
      ${item.name}
      </div>
      <div class="value is-number">
      ${
        item.value === 0 || (disponible === undefined) === '-'
          ? '-'
          : Utils.toNumber((item.value * 100) / disponible, 1, 'comma')
      }
      
      <span class="unit">%</span></div>
      </div>
      `
        )
        .on('mouseover', function (d, i) {
          if (pie.innerArrow) {
            pie.innerArrow.moveTo(d.id);
          } // Evento que relaciona el hover y anima el grafico.
        });
    };

    const indicators = data.indicators;
    const colorBar = ['#85B200', '#CF4634'];

    const disponible = indicators.find((item) => item.id == 10001);
    const valorDisponible =
      disponible.values.length === 0 ? 0 : disponible.values[0]?.y;

    let sumaTotal = 0;
    const filterBar = [10001, 462, 10003];
    const filterBarA = [10001, 462];
    piePotencia(indicators, valorDisponible);

    const arrGeneConBar = indicators
      .map((elemnt, index) => {
        sumaTotal += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
        return {
          id: elemnt.id,
          composited: elemnt.composited,
          alias: elemnt.alias,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
          color: colorBar[index],
        };
      })
      .filter((el) => filterBar.includes(el.id));
    const barAux =
      indicators
        .map((b, index) => {
          let difference = 0;
          if (b.values.length > 0) {
            if (b.id == 10001) {
              b.values.y = b.values[0].y;
            } else if (b.id == 462) {
              arrGeneConBar.map((a) => {
                if (a.id == 10001) {
                  difference = a.value - difference;
                }
                if (a.id == 10003) {
                  difference = a.value - difference;
                }
              });
              b.values.y = difference;
            }
          }

          return {
            id: b.id,
            name: b.short_name,
            value: b.values.length > 0 ? b.values.y : 0,
            color: colorBar[index],
          };
        })
        .filter((b) => filterBarA.includes(b.id)) || [];
    let optionsBarA = {
      target: d3.select('#widget1 .widget-summary-bar.gen-bar').node(),
      innerArrow: true,
      innerRadius: 0.7,
      hoverFade: 1,
    };
    let barPercentage = new Charicharts.PercentageBar(optionsBarA, barAux);
    let graphUpLegends = document.querySelector(
      '#widget1 .widget-summary.gyc-summary .dispIndi'
    );

    graphUpLegends.innerHTML = '';
    arrGeneConBar.map((el, index) => {
      if (el.id == 10001) {
        graphUpLegends.innerHTML += `
        <div class="widget-summary-item">
        <span class="legend-circle" style="background: ${
          colorBar[index]
        };" ></span>
      <div class="label"> ${el.name}</div>
      <div class="value has-padding">
        <div class="is-number">
        ${el.value === 0 ? '-' : Utils.toNumber(el.value.toFixed(1), 1)} 
          <span class="unit">MW</span>
        </div>
      </div>
      </div>
        `;
      }

      if (el.id == 462) {
        let difference = 0;

        arrGeneConBar.map((b) => {
          if (b.id == 10001) {
            difference = b.value - difference;
          }
          if (b.id == 10003) {
            difference = b.value - difference;
          }
        });
        el.value = difference;
        graphUpLegends.innerHTML += `
        <div class="widget-summary-item">
          <span
            class="legend-circle" style="background:  ${colorBar[index]};"
           ></span>
            <div class="label">${el.name}</div>
              <div class="value has-padding">
                <div class="is-number">
                
                ${el.value === 0 ? 0 : Utils.toNumber(el.value.toFixed(1), 1)} 
                    <span class="unit">MW</span>
                </div>
              </div>
        </div>
  
        `;
      }
    });

    let graphUpLegendsD = document.querySelector(
      '#char1 .widget-summary-item.instalada span'
    );

    graphUpLegendsD.innerHTML = '';
    arrGeneConBar.map((el) => {
      if (el.id == 10003) {
        graphUpLegendsD.innerHTML += `
        
        <span class="legend-circle" style="background-color: #ffffff;"></span>
        <div class="label">${el.name}</div>
                      <div class="value has-padding">
                        <div class="is-number">
                        ${Utils.toNumber(el.value.toFixed(1), 1)} 
                          <span class="unit">MW</span>
                        </div>
                      </div>
                    </div>
  
        `;
      }
    });
    if (data.indicators[0].values.length === 0) {
      document.querySelector('#rectPiePotencia').classList.remove('hidden');
      document.querySelector('.piePotencia svg').classList.add('hidden');
      document
        .querySelector('.piePotencia .chartLegend')
        .classList.add('hidden');
    } else {
      document.querySelector('#rectPiePotencia').classList.add('hidden');
      document
        .querySelector('.piePotencia .chartLegend')
        .classList.remove('hidden');
    }
  },
  2: async (
    data,
    currentDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY')
  ) => {
    const indicators = data.indicators;
    const graphColors = ['#FFCF09', '#74BA05', '#DF4A32'];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const lastDate = [];
    const setColors = (id) => {
      if (id == 1293) {
        return '#FFCF09';
      } else if (id == 544) {
        return '#74BA05';
      } else if (id == 545) {
        return '#DF4A32';
      }
    };
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: el.step,
        color: setColors(el.id) || getColour(index),
      };
    });
    Utils.orderObjectsArrayByKey(indicators, [1293, 544, 545], 'id');

    let pathArr = window.location.pathname
      .split('/')
      .includes(i18n.t('routes.embed'));
    var options = {
      target: d3.select('#widget2').node(),
      margin: pathArr ? '40 45 25 45' : '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          date = moment(date).toDate();
          var minutes = date.getUTCMinutes();
          minutes = Math.floor(minutes / step) * step;
          date.setUTCMinutes(minutes, 0, 0);
          return date;
        },
        initXValue: function () {
          return lastDate.at(-1);
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 1],

        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };

    const arrGeneConGC = indicators.map((elemnt, index) => {
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : null,
        color: setColors(elemnt.id) || getColour(index),
      };
    });

    let graphLibreLegendsC = document.querySelector(
      '#char2 .widget-legend.in-line.visual-legend ul'
    );

    graphLibreLegendsC.innerHTML = '';
    arrGeneConGC.map((el, index) => {
      graphLibreLegendsC.innerHTML +=
        el.value !== null
          ? `
       <li class="legend-item">
                          <div class="item">
                            <span
                              class="legend-icon-eye icon-eye"
                              data-id=${el.id}
                              style="background-color: ${el.color};"
                            ></span>
                            <span class="label"> ${el.name}</span>
                          </div>
                        </li>
         `
          : '';
    });

    /** */

    Utils.orderObjectsArrayByKey(parsedData, [544, 545, 1293], 'id');
    var chart = new Charicharts.Chart(options, parsedData);
    Utils.orderObjectsArrayByKey(parsedData, [1293, 544, 545], 'id');
    Utils.setNoDataStatus(data, '2');
    let graphLibreLegendsD = document.querySelector(
      '#char2 .gyc-summary.highlighted-summary .in-line'
    );
    let graphLibreLegendV = document.querySelector(
      '#char2 .value.is-big.variacion'
    );
    let showRemainder;
    let a = 0;
    let b = 0;
    let remainder = 0;
    graphLibreLegendV.innerHTML = '';
    parsedData.map((el) => {
      if (el.id == 545) {
        a = el.y;
      }
      if (el.id == 1293) {
        b = el.y;
      }
      remainder = Math.round(((b - a) / b) * 100);
    });

    graphLibreLegendV.innerHTML += `
      <div class="value is-big-number is-bold">
        ${
          Number.isFinite(remainder) || showRemainder ? remainder : '-'
        } <span class="unit is-small-number"> %</span>
        </div>
          `;
    graphLibreLegendsD.innerHTML = '';
    parsedData.map((el) => {
      if (el.y == null) {
        showRemainder = false;
        graphLibreLegendsD.innerHTML += ``;
      } else {
        showRemainder === true;
        graphLibreLegendsD.innerHTML += `
        <div class="widget-summary-item">
          <div class="value">
            <div class="is-number first">
              ${Utils.toNumber(el.y, 0, 'comma')}<span class="unit"> MW </span>
            </div>
          </div>
          <div class="label">${el.name}</div>
        </div>`;
      }
    });
    let grapTime = document.querySelector('#char2 .mod-date');
    let embedhour = document.querySelector('#char2 #embed-hour');
    let embedDatetime = document.querySelector('#char2 #datepicker');
    chart.on('Trail/moved', function (parsedData, d) {
      if (grapTime) {
        grapTime.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
      }

      if (embedhour) {
        embedhour.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
      }
      if (embedDatetime) {
        embedDatetime.value = moment(d)
          .tz('Europe/Madrid')
          .format('DD / MM / YYYY');
      }
      graphLibreLegendsD.innerHTML = '';
      parsedData.map((el) => {
        if (el.y == null) {
          showRemainder = false;
          graphLibreLegendsD.innerHTML += `
          <div class="widget-summary-item">
            <div class="value">
              <div class="is-number first">
                - <span class="unit">MW</span>
              </div>
            </div>
            <div class="label">${el.name}</div>
          </div>
      
            `;
        } else {
          showRemainder = true;
          graphLibreLegendsD.innerHTML += `
          <div class="widget-summary-item">
            <div class="value">
              <div class="is-number first">
                ${Utils.toNumber(
                  el.y,
                  0,
                  'comma'
                )}<span class="unit"> MW </span>
              </div>
            </div>
            <div class="label">${el.name}</div>
          </div>
      
            `;
        }
      });

      graphLibreLegendV.innerHTML = '';
      parsedData.map((el) => {
        if (el.id == 545) {
          a = el.y;
        }
        if (el.id == 1293) {
          b = el.y;
        }
        remainder = Math.round(((b - a) / b) * 100);
      });
      graphLibreLegendV.innerHTML += `
      <div class="value is-big-number is-bold">
        ${
          Number.isFinite(remainder) || showRemainder
            ? remainder !== -Infinity
              ? remainder
              : '-'
            : '-'
        } <span class="unit is-small-number"> %</span>
        </div>
          `;
    });

    var aspect = 300 / 300,
      chart = d3.select('#generacion-consumo');
    d3.select(window).on('resize', function () {
      var targetWidth = chart.node()?.getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });

    if (
      window.location.pathname.includes(i18n.t('routes.embed')) ||
      window.location.pathname.includes(i18n.t('routes.myesios'))
    ) {
      if (data.indicators[0].values.length === 0) {
        document
          .querySelector('#widget2 .rect-no-data')
          .classList.remove('hidden');
        document.querySelector('#widget2 svg').classList.add('hidden');
        document.querySelector('#widget2').classList.add('no-data-graphic');
      } else {
        document
          .querySelector('#widget2 .rect-no-data')
          .classList.add('hidden');
        document.querySelector('#widget2').classList.remove('no-data-graphic');
      }
    }
  },
  3: async (data) => {
    const indicators = data.indicators;
    const arrDataLibre = indicators.map((elemnt, index) => {
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
      };
    });
    let graphLibreLegends = document.querySelector(
      '#gycCo2WidgetView .widget-custom .widget-summary'
    );
    const arrDataLibreUp = arrDataLibre.splice(2);

    graphLibreLegends.innerHTML = `
    <div id="spinner3" class="spinner-container" style="display: none;">
      <div class="spinner"></div>
    </div>
    `;
    let reverDataLibre = arrDataLibre.reverse();
    reverDataLibre.map((el, index) => {
      graphLibreLegends.innerHTML += `
  
        <div class="widget-summary-item">
          <div class="value">
              <div class="value ${
                el.id === 10033 ? 'is-big-number' : 'is-small-number is-bold'
              }">
              ${Utils.toNumber(el.value, el.id === 10033 ? 1 : 0, 'comma')}
              <span class="unit ${el.id === 10033 ? 'is-small-number' : ''}">${
        el.value ? (el.id === 10033 ? '%' : 'MW') : ''
      }</span>
            </div>
          </div>
        </div>
        `;
    });

    let graphLibreLegendsL = document.querySelector(
      '#gycCo2WidgetView .aside-summary'
    );

    graphLibreLegendsL.innerHTML = '';
    arrDataLibreUp.map((el, index) => {
      graphLibreLegendsL.innerHTML += `
        <div class="widget-summary-item widget-summary-item-${el.id}" >
          <div class="label"> ${el.alias}</div>
             <div class="value">
                <div class="is-small-number">
                ${Utils.toNumber(el.value, 0)}
                ${el.value ? `<span class="unit">MW</span>` : ''}
                </div>
             </div>
        </div>    
        `;
    });
  },
  4: async (data) => {
    const indicators = data.indicators;

    /* COLORS*/
    let colorsLD = [
      '#146797',
      '#85b11e',
      '#fdce2e',
      '#23a3d6',
      '#d60012',
      '#717171',
      '#fb91fd',
    ];

    let colorsLL = [
      '#0090d1',
      '#6fb124',
      '#e48500',
      '#ff0000',
      '#B4D490',
      '#464394',
      '#86c6e3',
      '#ffcc66',
      '#ad5c34',
      '#ba0f16',
      '#00a150',
      '#717171',
      '#23A2D8',
      '#FF8282',
      '#8C0000',
    ];

    /* IDs*/

    let LegendDownLeft = [
      10063, 10010, 84, 85, 10013, 2131, 74, 79, 10008, 10009, 10011, 10012, 96,
      10025,
    ];

    let LegendAlmCenter = [2143, 2154, 95, 73];

    let LegendDownCenter = [10014, 10015, 10016, 10017];

    let LegendDownRight = [365, 366, 367, 368, 369, 370, 371];

    let sumaBalanceL = 0;
    let sumaBalanceA = 0;
    let sumaBalanceR = 0;
    let sumaBalanceI = 0;

    let arrBalanceG;
    const itemPositions = {};
    for (const [index, id] of LegendDownLeft.entries()) {
      itemPositions[id] = index;
    }

    arrBalanceG = indicators
      .filter((el) => LegendDownLeft.includes(el.id))
      .sort((a, b) => itemPositions[a.id] - itemPositions[b.id]);

    arrBalanceG = arrBalanceG.map((elemnt, index) => {
      sumaBalanceL += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        color: colorsLL[index],
      };
    });

    let arrBalanceA;
    arrBalanceA = indicators
      .filter((el) => LegendAlmCenter.includes(el.id))
      .map((elemnt, index) => {
        sumaBalanceA += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
        return {
          id: elemnt.id,
          composited: elemnt.composited,
          alias: elemnt.alias,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        };
      });

    arrBalanceA.sort((a, b) => a.id - b.id);

    let arrBalanceC;
    arrBalanceC = indicators
      .filter((el) => LegendDownRight.includes(el.id))
      .map((elemnt, index) => {
        sumaBalanceR += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
        return {
          id: elemnt.id,
          composited: elemnt.composited,
          alias: elemnt.alias,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
          color: colorsLD[index],
        };
      });

    let arrBalanceI;
    arrBalanceI = indicators
      .filter((el) => LegendDownCenter.includes(el.id))
      .map((elemnt, index) => {
        sumaBalanceI += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
        return {
          id: elemnt.id,
          composited: elemnt.composited,
          alias: elemnt.alias,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        };
      });

    let options = {
      target: d3.select('#gycBalanceWidgetView .dibujo').node(),
      innerArrow: true,
      innerRadius: 0.65,
      hoverFade: 1,
    };

    let optionsR = {
      target: d3.select('#gycBalanceWidgetView .dibujoR').node(),
      innerArrow: true,
      innerRadius: 0.65,
      hoverFade: 1,
    };

    let pie = new Charicharts.Pie(options, arrBalanceG);
    let pieR = new Charicharts.Pie(optionsR, arrBalanceC);

    /* ----- SECCION GENERACION ----- */
    debugger;
    /** TITLE  */
    let titleGeneracion = document.querySelectorAll(
      '#generacion .summary-resume'
    );
    titleGeneracion.forEach((i) => {
      i.innerHTML = '';

      i.innerHTML += `
        <div class="value">
            <div class="is-small-number">
            ${Utils.toNumber(sumaBalanceL.toFixed(1), 1, 'comma')}
               <span class="unit">MWh</span>
            </div>
        </div>
    
         ${
           sumaBalanceL === 0
             ? ''
             : `<div class="value">
         <div class="is-small-number is-bold">
         ${Utils.toNumber(
           ((sumaBalanceL * 100) / sumaBalanceR).toFixed(1),
           1,
           'comma'
         )}
         <span class="unit">% </span>
         </div>
       </div>`
         }
    
          `;
    });

    /* GRAFICA*/

    const graphPieLegendCenter = document.querySelector(
      '#gycBalanceWidgetView  .chari-chart'
    );
    graphPieLegendCenter.insertAdjacentHTML(
      'beforeend',
      `  <div class="chartLegend" ></div>`
    );

    pie.on('Pie-piece/mouseover', function (arrBalanceG, d) {
      const centerLegendContent = document.querySelector(
        '#gycBalanceWidgetView .chartLegend'
      );

      centerLegendContent.innerHTML = `
      <h3 class="title">${arrBalanceG.data.alias}</h3>
      <div class="value be-size">
        ${Utils.toNumber(arrBalanceG.data.value.toFixed(1), 1, 'comma', true)} 
      </div> 
      <div class="unit">MWh </div>
      `;
    });

    /** ---------- SECCION Almacenamiento -------- */

    /** TITLE  */
    let titleInteralm = document.querySelectorAll(
      '#almacenamiento .summary-resume'
    );
    titleInteralm.forEach((i) => {
      i.innerHTML = '';

      i.innerHTML += `
    <div class="value">
        <div class="is-small-number">
        ${Utils.toNumber(sumaBalanceA.toFixed(1), 1, 'comma')}
           <span class="unit">MWh</span>
        </div>
    </div>
  
    ${
      sumaBalanceA === 0
        ? ''
        : `<div class="value">
    <div class="is-small-number is-bold">
    ${Utils.toNumber(
      ((sumaBalanceA * 100) / sumaBalanceR).toFixed(1),
      1,
      'comma'
    )} 
   <span class="unit">%</span>
    </div>
  </div>`
    }
      `;
    });

    /** LIST  */
    let legendInteralm = document.querySelector(
      '#almacenamiento .basic-summary'
    );

    legendInteralm.innerHTML = '';
    arrBalanceA.map((el, index) => {
      if (el.value < 0) {
        legendInteralm.innerHTML += `
      <div class=" widget-summary-item">
        <div class="label"> ${el.alias}</div>
        <div class="value">
          <div class="is-micro-number is-bold">
          ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
            <span class="unit">MWh</span>
          </div>
        </div>
      </div>
      `;
      } else {
        legendInteralm.innerHTML += `
      <div class=" widget-summary-item">
        <div class="label"> ${el.alias}</div>
        <div class="value">
          <div class="is-micro-number is-bold">
          ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
            <span class="unit">MWh</span>
          </div>
        </div>
      </div>
      `;
      }
    });

    /** ---------- SECCION INTERCONEXIONES -------- */

    /** TITLE  */
    let titleInter = document.querySelectorAll(
      '#interconexiones .summary-resume'
    );
    titleInter.forEach((i) => {
      i.innerHTML = '';

      i.innerHTML += `
      <div class="value">
          <div class="is-small-number">
          ${Utils.toNumber(sumaBalanceI.toFixed(1), 1, 'comma')}
             <span class="unit">MWh</span>
          </div>
      </div>
    
      ${
        sumaBalanceI === 0
          ? ''
          : `<div class="value">
      <div class="is-small-number is-bold">
      ${Utils.toNumber(
        ((sumaBalanceI * 100) / sumaBalanceR).toFixed(1),
        1,
        'comma'
      )} 
     <span class="unit">%</span>
      </div>
    </div>`
      }
        `;
    });

    /** LIST  */
    let legendInter = document.querySelector('#interconexiones .basic-summary');

    legendInter.innerHTML = '';
    arrBalanceI.map((el, index) => {
      if (el.value < 0) {
        legendInter.innerHTML += `
        <div class=" widget-summary-item">
          <div class="label false"> ${el.alias}</div>
          <div class="value">
            <div class="is-micro-number is-bold">
            ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
              <span class="unit">MWh</span>
            </div>
          </div>
        </div>
        `;
      } else {
        legendInter.innerHTML += `
        <div class=" widget-summary-item">
          <div class="label true"> ${el.alias}</div>
          <div class="value">
            <div class="is-micro-number is-bold">
            ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
              <span class="unit">MWh</span>
            </div>
          </div>
        </div>
        `;
      }
    });

    /* SECCION CONSUMO*/

    /** TITLE  */
    let titleConsumo = document.querySelectorAll('#consumo .summary-resume');
    titleConsumo.forEach((i) => {
      i.innerHTML = '';

      i.innerHTML += `
        <div class="value">
            <div class="is-small-number">
            ${Utils.toNumber(sumaBalanceR.toFixed(1), 1, 'comma')}
               <span class="unit"> MWh</span>
            </div>
        </div>
    
  
        
        <div class="value">
           <div class="is-small-number is-bold">
           ${
             sumaBalanceR === 0
               ? '100,0'
               : Utils.toNumber(
                   ((sumaBalanceR * 100) / sumaBalanceR).toFixed(1),
                   1,
                   'comma'
                 )
           }
           <span class="unit"> %</span>
           </div>
         </div>
  
          `;
    });

    /** GRAFICA  */
    const dataLength3 = indicators[0].values.length;
    const rectNoData3 = document.getElementById('rectNoData3');
    const rectNoData2 = document.getElementById('rectNoData2');
    const wid2 = document.getElementById('genConWidget2');
    const wid3 = document.getElementById('genConWidget3');
    if (dataLength3 != 0) {
      wid2.classList.remove('no-data-graphic-s');
      wid3.classList.remove('no-data-graphic-s');
      rectNoData2.classList.add('hidden');
      rectNoData3.classList.add('hidden');
    } else {
      wid2.classList.add('no-data-graphic-s');
      wid3.classList.add('no-data-graphic-s');
      rectNoData2.classList.remove('hidden');
      rectNoData3.classList.remove('hidden');
      document.querySelector('#graphic3 > svg')?.classList.add('hidden');
      document.querySelector('#char4 .chartLegend')?.remove();
      document.querySelector('#char4 .chartLegendR')?.remove();
      document.querySelectorAll('#char4 svg')?.forEach((svg) => {
        svg?.classList.add('hidden');
      });
    }

    const graphPieLegendCenterR = document.querySelector(
      '#gycBalanceWidgetView  .chari-chart.dibujoR'
    );
    graphPieLegendCenterR.insertAdjacentHTML(
      'beforeend',
      `  <div class="chartLegendR"></div>`
    );

    pieR.on('Pie-piece/mouseover', function (arrBalanceC, d) {
      const centerLegendContentR = document.querySelector(
        '#gycBalanceWidgetView .chartLegendR'
      );
      let numberToDraw = Utils.toNumber(
        arrBalanceC.value.toFixed(1),
        1,
        'comma'
      );
      numberToDraw = numberToDraw == '-' ? 0 : numberToDraw;
      centerLegendContentR.innerHTML = `
      <h3 class="title">${arrBalanceC.data.alias}</h3>
      <div class="value be-size">
      ${numberToDraw} 
      </div> 
        <div class="unit">MWh</div>
    
      `;
    });

    renderList(pie, '.eb-left.basic-legend', arrBalanceG, colorsLL);
    renderList(pieR, '.eb-rigth.basic-legend', arrBalanceC, colorsLD);
    document.documentElement.style.setProperty(
      '--lines-height',
      `${document.getElementById('char4').clientHeight || 400}px`
    );
  },
  6: async (data) => {
    const indicators = data.indicators;
    let graphColors = [
      '#006699',
      '#85B200',
      '#FFCF09',
      '#00A2D8',
      '#D90000',
      '#717171',
      '#FD8FFF',
      '#FF7F00',
    ];
    const indcatorLength = indicators.length;
    let sumaTotal = 0;
    const arrGeneMeFilter = indicators
      .map((elemnt, index) => {
        return {
          id: elemnt.id,
          alias: elemnt.alias,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
          color: graphColors[index],
        };
      })
      .filter((el, index) => {
        if (indcatorLength !== index + 1) {
          return el;
        }
      });

    let sumPercent = 0;
    let finalIn = arrGeneMeFilter.at(-1).id;

    arrGeneMeFilter.map((te) => {
      sumaTotal += te.value;
      if (te.id !== finalIn) {
        sumPercent += te.value;
      }
    });

    let options = {
      target: d3.select('#mypVolumenWidgetView .chari-chart').node(),
      innerArrow: true,
      innerRadius: 0.7,
      hoverFade: 1,
    };

    let pie = new Charicharts.Pie(options, arrGeneMeFilter);
    let totalVolume = document.querySelector('#total-volume');
    totalVolume.innerHTML = '';
    totalVolume.innerHTML = `${Utils.toNumber(
      indicators[8]?.values[0]?.y,
      1,
      'comma'
    )}<span class="unit">MWh</span>`;

    document.querySelector('#mypVolumenWidgetView .volume-leyends').innerHTML =
      '';
    let ulLegend = d3
      .select('#mypVolumenWidgetView .volume-leyends')
      .append('ul')
      .attr('class', 'esios-listEnergyItems__list')
      .style('margin-top', '30px');
    let keys = ulLegend
      .selectAll('.key')
      .data(arrGeneMeFilter)
      .enter()
      .append('li')
      .attr('class', 'esios-listEnergyItems__item-with-value');

    keys
      .html(
        (item, index) =>
          `<div class="esios-listEnergyItems__label ">
          <span
            class="esios-listEnergyItems__decorator"
            style="background: ${graphColors[index]};"
          ></span>
            ${item.alias}
        </div>`
      )
      .on('mouseover', function (d, i) {
        if (pie.innerArrow) {
          pie.innerArrow.moveTo(d.id);
        }
      });

    const graphPieLegendCenter = document.querySelector(
      '#mypVolumenWidgetView .chari-chart'
    );

    graphPieLegendCenter.insertAdjacentHTML(
      'beforeend',
      `  <div class="chartLegend"></div>`
    );
    pie.on('Pie-piece/mouseover', function (arrGeneMeFilter, d) {
      const centerLegendContent = document.querySelector(
        '#mypVolumenWidgetView .chartLegend'
      );
      let valueToShow = '-';
      if (arrGeneMeFilter.value == 0) {
        valueToShow = '-';
      } else {
        valueToShow = Utils.toNumber(
          (arrGeneMeFilter.value / sumPercent) * 100,
          1,
          'comma'
        );
      }
      centerLegendContent.innerHTML = `
    <h3 class="title">${arrGeneMeFilter.data.alias}</h3>
    <div class="value">
    ${valueToShow}
      <div class="unit">%</div>
    </div>
    `;
    });

    if (pie.pie) {
      pie.pie.triggerMouseover(1);
    }

    var aspect = 280 / 280,
      chart = d3.select('#mypVolumenWidgetView .chari-chart');

    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });

    const dataLength = indicators[0].values.length;
    const rectNoData1 = document.getElementById('mypNoDataRect1');
    const widget1 = document.getElementById('mypGraphic1');
    if (dataLength != 0) {
      widget1.classList.remove('no-data-graphic');
      rectNoData1.classList.add('hidden');
      document
        .querySelector('#mypGraphic1 .chartLegend')
        .classList.remove('hidden');
    } else {
      widget1.classList.add('no-data-graphic');
      widget1.classList.add('thin');
      rectNoData1.classList.remove('hidden');
      document.querySelector('#mypGraphic1 > svg').classList.add('hidden');
      document
        .querySelector('#mypGraphic1 .chartLegend')
        .classList.add('hidden');
    }
  },
  7: async (data) => {
    let graphColors = [
      '#006699',
      '#85B200',
      '#FFCF09',
      '#00A2D8',
      '#D90000',
      '#717171',
      '#FD8FFF',
      '#FF7F00',
    ];

    const indicators = data.indicators;

    const renovableFilter = [805, 808, 10377, 10380, 814, 1277, 10403];

    const tableFilter = [817, 804, 791, 856, 843, 830, 10028, 10029];

    const arrGeneMedFilter = indicators.filter((elemnt, index) => {
      if (renovableFilter.includes(elemnt.id)) {
        return elemnt;
      }
    });
    const arrUnitaryTable = indicators
      .filter((elemnt, index) => {
        if (tableFilter.includes(elemnt.id)) {
          return elemnt;
        }
      })
      .map((el) => {
        return {
          value: el.values.length > 0 ? el.values[0].y : 0,
        };
      });

    const arrGeneMed = arrGeneMedFilter.map((elemnt, index) => {
      return {
        id: elemnt.id,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        color: graphColors[index],
      };
    });
    let options = {
      target: d3.select('#mypCosteWidgetView .chari-chart').node(),
      orientation: 'vertical',
      margin: '10 25 0 220',
      gridTicks: 7,
    };

    let graphTable = document.querySelector(
      '#mypCosteWidgetView .table-summary'
    );
    graphTable.innerHTML = `
    <table id="table-summary-laptop">
    <thead>
      <tr>
        <th></th>
        <th>${i18n.t('total')}</th>
        <th>${i18n.t('free_trading_company')}</th>
        <th>${i18n.t('reference_trading_company')}</th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 0;">
        <th>${i18n.t('average_price')} <span class="unit">€/MWh</span></th>
        <td class="is-big-number is-bold">${Utils.toNumber(
          arrUnitaryTable[2].value,
          2,
          'comma'
        )}</td>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[1].value,
          2,
          'comma'
        )}</td>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[0].value,
          2,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('energy')} <span class="unit">MWh</span></th>
        <td class="is-small-number is-bold">${Utils.toNumber(
          arrUnitaryTable[5].value,
          1,
          'comma'
        )}</td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[4].value,
          1,
          'comma'
        )}</td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[3].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('market_share')} <span class="unit">%</span></th>
        <td></td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[6].value,
          1,
          'comma'
        )}</td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[7].value,
          1,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>

  <table id="table-summary-mobile-ap">
    <tbody>
      <tr>
        <th class="th-title">${i18n.t(
          'average_price'
        )} <span class="unit th-title">€/MWh</span></th>
      </tr>
      <tr>
        <th>${i18n.t('total')}</th>
        <td class="is-big-number is-bold">${Utils.toNumber(
          arrUnitaryTable[2].value,
          2,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('free_trading_company')}</th>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[1].value,
          2,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('reference_trading_company')}</th>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[0].value,
          2,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>

  <table id="table-summary-mobile-energy">
    <tbody>
      <tr>
        <th class="th-title">${i18n.t(
          'energy'
        )} <span class="unit th-title">MWh</span></th>
      </tr>
      <tr>
        <th>${i18n.t('total')}</th>
        <td class="is-small-number is-bold">${Utils.toNumber(
          arrUnitaryTable[5].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('free_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[4].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('reference_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[3].value,
          1,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>

  <table id="table-summary-mobile-market">
    <tbody>
      <tr>
        <th class="th-title">${i18n.t(
          'market_share'
        )} <span class="unit th-title">%</span></th>
      </tr>
      <tr>
        <th>${i18n.t('free_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[6].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18n.t('reference_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[7].value,
          1,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>
    `;

    let barPercentage = new Charicharts.PercentageBar(options, arrGeneMed);

    const graphPie = document.querySelector(
      '#mypCosteWidgetView  .chari-chart'
    );

    const infoWindow = document.getElementById('infoWin');
    if (infoWindow) {
      infoWindow.remove();
    }
    graphPie.insertAdjacentHTML(
      'beforeend',
      `
      <div id="infoWin" class="informativeWindow">
      <div id="arrowForInfo" class="widget-stacked-bar-tooltip-arrow"></div>
      <div class="widget-stacked-bar-tooltip">
      </div>
      </div>
    `
    );

    const graphPieLegendCenter = document.querySelector(
      '#mypCosteWidgetView .widget-stacked-bar-tooltip'
    );
    graphPieLegendCenter.innerHTML = `
        <div class="tooltip-container">
          <div class="tooltip-header" style="border-color: ${
            arrGeneMed[0].color
          };">
            <h3>${arrGeneMed[0].name}</h3>
            <div class="value is-medium-num">
              ${arrGeneMed[0].value.toLocaleString()}<span class="unit">€/MWh</span>
            </div>
          </div>
          <div class="tooltip-data">
            <div class="tooltip-row">
              <div class="label">${i18n.t('free_trading_company')}</div>
              <div class="value is-small-num">
                ${arrGeneMed[0].value.toLocaleString()}<span class="unit">€/MWh</span>
              </div>
            </div>
            <div class="tooltip-row">
              <div class="label">${i18n.t('reference_trading_company')}</div>
              <div class="value is-small-num">
                ${arrGeneMed[0].value.toLocaleString()}<span class="unit">€/MWh</span>
              </div>
            </div>
          </div>
        </div>
      `;

    barPercentage.on('Bar-piece/mouseover', function (arrGeneMed, d) {
      document.getElementById('arrowForInfo').style.top = `${
        d[1] <= 325 ? d[1] : 325
      }px`;
      document.getElementById('arrowForInfo').style.display =
        d.value === 0 ? 'none' : 'flex';
      graphPieLegendCenter.style.top = `${d[1] <= 70 ? d[1] : 70}px`;
      graphPieLegendCenter.innerHTML = `
        <div class="tooltip-container">
          <div class="tooltip-header" style="border-color: ${
            arrGeneMed.color
          };">
            <h3>${arrGeneMed.name}</h3>
            <div class="value is-medium-num">
              ${
                arrGeneMed.value === 0 ? '-' : arrGeneMed.value.toLocaleString()
              }<span class="unit">€/MWh</span>
            </div>
          </div>
          <div class="tooltip-data">
            <div class="tooltip-row">
              <div class="label">${i18n.t('free_trading_company')}</div>
              <div class="value is-small-num">
                ${
                  arrGeneMed.value === 0
                    ? '-'
                    : arrGeneMed.value.toLocaleString()
                }<span class="unit">€/MWh</span>
              </div>
            </div>
            <div class="tooltip-row">
              <div class="label">${i18n.t('reference_trading_company')}</div>
              <div class="value is-small-num">
                ${
                  arrGeneMed.value === 0
                    ? '-'
                    : arrGeneMed.value.toLocaleString()
                }<span class="unit">€/MWh</span>
              </div>
            </div>
          </div>
        </div>
      `;
    });

    const listOfIndicators = document.querySelectorAll(
      '#mypCosteWidgetView .volume-leyends ul'
    );
    if (listOfIndicators.length > 0) {
      listOfIndicators[0].remove();
    }

    /**CREACIÓN DE LEYENDA */
    let ulLegend = d3
      .select('#mypCosteWidgetView .volume-leyends')
      .append('ul')
      .attr('class', 'esios-listEnergyItems__list')
      .style('margin-top', '30px');

    const listArr = document.querySelectorAll(
      '#mypCosteWidgetView .volume-leyends .esios-listEnergyItems__list'
    );
    if (listArr.length > 1) {
      listArr[0].remove();
    }
    let keys = ulLegend
      .selectAll('.key')
      .data(arrGeneMed)
      .enter()
      .append('li')
      .attr('class', 'esios-listEnergyItems__item-with-value');

    keys
      .html(
        (item, index) => `<div class="esios-listEnergyItems__label ">
            <span class="esios-listEnergyItems__decorator"
              style="background: ${graphColors[index]};"></span>${item.name}
          </div>`
      )
      .on('mouseover', function (d, i) {
        const graphPieLegendCenter = document.querySelector(
          '#mypCosteWidgetView .widget-stacked-bar-tooltip'
        );
        document.getElementById('arrowForInfo').style.top = `${
          d.y0 + d.y1 / 2
        }%`;
        document.getElementById('arrowForInfo').style.display =
          d.value === 0 ? 'none' : 'flex';
        graphPieLegendCenter.style.top = `${d.y0 <= 0 ? 0 : d.y0 - 16}px`;

        graphPieLegendCenter.innerHTML = `
          <div class="tooltip-container ${325 - d.y1 <= 70 ? 325 - d.y1 : 70}">
          
            <div class="tooltip-header" style="border-color: ${d.color};">
              <h3>${d.name}</h3>
              <div class="value is-medium-num">
                ${
                  d.value === 0 ? '-' : d.value.toLocaleString()
                }<span class="unit">€/MWh</span>
              </div>
            </div>
            <div class="tooltip-data">
              <div class="tooltip-row">
                <div class="label">${i18n.t('free_trading_company')}</div>
                <div class="value is-small-num">
                  ${
                    d.value === 0 ? '-' : d.value.toLocaleString()
                  }<span class="unit">€/MWh</span>
                </div>
              </div>
              <div class="tooltip-row">
                <div class="label">${i18n.t('reference_trading_company')}</div>
                <div class="value is-small-num">
                  ${
                    d.value === 0 ? '-' : d.value.toLocaleString()
                  }<span class="unit">€/MWh</span>
                </div>
              </div>
            </div>
          </div>
        `;
      });

    var aspect = 400 / 300,
      chart = d3.select('#mypCosteWidgetView .chari-chart');

    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });

    if (indicators[0].values.length > 0) {
      document
        .getElementById('mypGraphic2')
        .classList.remove('no-data-graphic');
      document.getElementById('mypNoDataRect7')?.classList.add('hidden');
      document.querySelector('#mypGraphic2 svg').classList.remove('hidden');
      document.getElementById('infoWin').classList.remove('hidden');
    } else {
      document.getElementById('infoWin').classList.add('hidden');
      document.getElementById('mypGraphic2').classList.add('no-data-graphic');
      document.querySelector('#mypGraphic2 svg').classList.add('hidden');
      document.getElementById('mypNoDataRect7')?.classList.remove('hidden');
    }
  },
  8: async (data) => {
    let { date } = getUrlParams();
    date = date ? date : moment(new Date()).format('DD-MM-YYYY');

    const indicators = data.indicators;
    const graphColors = ['#02669C', '#666666', '#DDDDDD'];
    let length = 24;
    let parsedData = indicators.map((el, index) => {
      if (el.time === 218) {
        length = el.values.length;
      }
      if (index === 0) {
        return {
          id: el.id,
          alias: el.alias,
          geo_id: el.geo_id,
          short_name: el.short_name,
          type: 'line',
          // ! Se le debe de pasar la fecha
          values: processValues(length, el.values, date),
          // values: el.values,
          magnitud: el.magnitud,
          dots: true,
          interpolation: 'linear',
          color: graphColors[index],
        };
      } else if (index === 1) {
        return {
          id: el.id,
          alias: el.alias,
          geo_id: el.geo_id,
          short_name: el.short_name,
          type: 'line',
          values: processValues(length, el.values, date, true),
          magnitud: el.magnitud,
          dots: true,
          interpolation: 'linear',
          color: graphColors[index],
        };
      }
    });
    if (parsedData.length == 3) {
      parsedData.pop();
    }

    let trailConfig;
    if (indicators[0]?.time === 218) {
      trailConfig = {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por intervalos de 15 minutos
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          let { date } = getUrlParams(); // Suponiendo que estás obteniendo la fecha desde los parámetros de la URL
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          if (date == null) {
            date = now;
          }
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');

          // Si la fecha actual es diferente a la fecha del parámetro, empieza desde el comienzo del día
          if (formatedCurrentDate !== now) {
            return moment(date, 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hours(0)
              .minutes(0)
              .format();
          } else {
            // Si la fecha es la misma que la actual, devuelve la fecha y hora actual
            return moment().tz('Europe/Madrid').format();
          }
        },
      };
    } else {
      // Configuración por defecto
      trailConfig = {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          let { date } = getUrlParams();
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');
          if (formatedCurrentDate !== now) {
            return moment(date, 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hours('00')
              .minutes('00')
              .format();
          } else {
            return moment().tz('Europe/Madrid').format();
          }
        },
      };
    }

    var options = {
      target: d3.select('#mypPrecioWidgetView .chari-chart').node(),
      margin: '40 40 25 45',
      dots: true,
      trail: trailConfig,
      xaxis: {
        ticks: [d3.time.hours, 1],
        textMarginLeft: 50,
        bottom: {
          // Otra opción
          // domain: [1640991600000, 1641074400000],
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        textMarginTop: -10,
        ticks: [6],
        fit: false,
        fullGrid: true,
        left: {
          label: '€/MWh',
          tickFormat: function (d) {
            return Math.abs(d);
          },
        },
      },
    };
    //Pie del gráfico PRECIOS DE LOS DESVÍOS EN TIEMPO REAL
    var chart = new Charicharts.Chart(options, parsedData);

    const graphTime = document.querySelector('#mypPrecioWidgetView .mod-date');
    let graphLegend = document.querySelector(
      '#mypPrecioWidgetView .esios-listEnergyItems__list'
    );
    const embedTime = document.querySelector('#embed-hour');

    if (parsedData[0].values.length === 0) {
      graphLegend.style.top = '20px';
      graphTime.innerHTML = '00:00';
      graphLegend.innerHTML = '';

      graphLegend.innerHTML = `<div class="row">
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
          <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[0]};"
            ></span>
            <span class="label">${parsedData[0].short_name}</span>
            <div class="value is-number">
              <span class="caption">-</span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
         <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[1]};"
            ></span>
            <span class="label">${parsedData[1].short_name}</span>
            <div class="value is-number">
              <span class="caption">-</span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
    </div>`;
    } else {
      graphLegend.style.top = '0px';
    }

    chart.on('Trail/moved', function (parsedDataLegend, date) {
      if (graphTime) {
        graphTime.innerHTML =
          parsedData[0].values.length === 0
            ? '00:00'
            : moment(date).tz('Europe/Madrid').format('HH:mm');
      }

      if (embedTime) {
        embedTime.innerHTML =
          parsedData[0].values.length === 0
            ? '00:00'
            : moment(date).tz('Europe/Madrid').format('HH:mm');
      }

      graphLegend.innerHTML = '';

      graphLegend.innerHTML = `<div class="row">
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
          <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[0]};"
            ></span>
            <span class="label">${parsedDataLegend[0].short_name}</span>
            <div class="value is-number">
              <span class="caption">
              ${
                parsedData[0].values.length === 0
                  ? ''
                  : parsedDataLegend[0].y !== null
                  ? Utils.toNumber(
                      parsedDataLegend[0].y.toFixed(2).toLocaleString(),
                      2,
                      'comma',
                      true
                    )
                  : '-'
              }
            </span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
         <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[1]};"
            ></span>
            <span class="label">${parsedDataLegend[1].short_name}</span>
            <div class="value is-number">
              <span class="caption">${
                parsedData[0].values.length === 0
                  ? ''
                  : parsedDataLegend[1].y !== null
                  ? Utils.toNumber(
                      (parsedDataLegend[1].y >= 0
                        ? parsedDataLegend[1].y
                        : parsedDataLegend[1].y * -1
                      )
                        .toFixed(2)
                        .toLocaleString(),
                      2,
                      'comma',
                      true
                    )
                  : '-'
              }</span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
    </div>`;
    });

    const dataLength = indicators[0].values.length;
    const rectNoData3 = document.getElementById('mypNoDataRect3');
    const widget3 = document.getElementById('mypGraphic3');
    if (dataLength != 0) {
      widget3.classList.remove('no-data-graphic');
      rectNoData3.classList.add('hidden');
      document
        .querySelector('#mypPrecioWidgetView .esios-listEnergyItems__list')
        .classList.remove('no-data');
    } else {
      widget3.classList.add('no-data-graphic');
      rectNoData3.classList.remove('hidden');
      document
        .querySelector('#mypPrecioWidgetView .esios-listEnergyItems__list')
        .classList.add('no-data');
      document.querySelector('#mypGraphic3 > svg').classList.add('hidden');
    }
  },
  9: async (data) => {
    const indicators = data.indicators;
    const graphColors = ['#00A1DA', '#DF4A32'];
    let { date } = getUrlParams();
    date = date ? date : moment(new Date()).format('DD-MM-YYYY');

    const parsedData = indicators.map((el, index) => {
      return {
        id: el.id,
        alias: el.alias,
        geo_id: el.geo_id,
        short_name: el.short_name,
        type: 'line',
        values: el.values,
        magnitud: el.magnitud,
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3.select('#mypFacturacionWidgetView .chari-chart').node(),
      margin: '40 0 25 55',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');
          if (formatedCurrentDate !== now) {
            return moment(date, 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hours('00')
              .minutes('00')
              .format();
          } else {
            return moment().tz('Europe/Madrid').format();
          }
        },
      },
      xaxis: {
        ticks: [d3.time.hours, 1],
        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        fit: false,
        fullGrid: true,
        ticks: [10],
        textMarginTop: -10,
        left: {
          label: '€/MWh',
          tickFormat: function (d, i) {
            if (i) {
              return d;
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime;
    if (window.location.pathname.includes(i18n.t('routes.embed'))) {
      graphTime = document.querySelector('#char9 #embed-hour');
    } else {
      graphTime = document.querySelector('#mypFacturacionWidgetView .mod-date');
    }

    if (parsedData[0].values.length === 0) {
    } else {
      chart.on('Trail/moved', function (parsedData, date) {
        let facturacionLegends = document.querySelector(
          '#mypFacturacionWidgetView .esios-listEnergyItems__list .row'
        );
        graphTime.innerHTML =
          parsedData[0].y == null || parsedData[0].y == 'Invalid Date'
            ? '00:00'
            : moment(date).tz('Europe/Madrid').format('HH:mm');
        facturacionLegends.innerHTML = '';
        parsedData.map((el, index) => {
          facturacionLegends.innerHTML += `
          <div class="col-xs-4">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label">${el.alias}</span>
                            <div class="value is-number">
                              <span class="caption">${Utils.toNumber(
                                el.y,
                                2,
                                'comma'
                              )}</span><span class="unit">€/MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
        });
      });
    }

    const facturawidgetSVG = document.querySelector(
      '#mypFacturacionWidgetView svg'
    );
    facturawidgetSVG?.setAttribute('preserveAspectRatio', 'xMinYMin meet');
    if (window.location.pathname.includes(i18n.t('routes.embed'))) {
      facturawidgetSVG?.setAttribute('viewBox', '0 15 540 320');
      facturawidgetSVG?.setAttribute('width', '540px');
      facturawidgetSVG?.setAttribute('height', '620px');
    }

    facturawidgetSVG?.removeAttribute('width');
    facturawidgetSVG?.removeAttribute('height');

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect4');
    const widget4 = document.getElementById('mypGraphic4');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic4 > svg').classList.add('hidden');
    } else {
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
    const windows = document.getElementById('infoWin');
    const rectNoData2 = document.getElementById('mypNoDataRect2');
    const widget2 = document.getElementById('mypGraphic2');
    const rectNoData3 = document.getElementById('mypNoDataRect3');
    const widget3 = document.getElementById('mypGraphic3');
    if (dataLength != 0) {
      widget3?.classList.remove('no-data-graphic');
      rectNoData3?.classList.add('hidden');
      widget2?.classList.remove('no-data-graphic');
      rectNoData2?.classList.add('hidden');
      windows?.classList.remove('hidden');
    } else {
      windows?.classList.add('hidden');
      widget2?.classList.add('no-data-graphic');
      widget2?.classList.add('thin');
      rectNoData2?.classList.remove('hidden');
      widget3?.classList.add('no-data-graphic');
      rectNoData3?.classList.remove('hidden');
      document.querySelector('#mypGraphic3 > svg')?.classList.add('hidden');
      document.querySelector('#mypGraphic2 > svg')?.classList.add('hidden');
    }
  },
  10: async (data, selectTime) => {
    document.getElementById('spinner10').classList.remove('hidden');
    const { currentHour, currentMinutes } = selectTime;
    let date = document.getElementById('datepicker').value;

    const state = store.getState();
    const auctionsData =
      await servicesMercadosPrecios.getAuctionsDataFromWidget({
        lang: state.exampleReducer.currentLang,
        time: `${currentHour}:${currentMinutes}`,
        day: moment(date, 'DD / MM / YYYY').tz('Europe/Madrid').format('DD'),
        month: moment(date, 'DD / MM / YYYY').tz('Europe/Madrid').format('MM'),
        year: moment(date, 'DD / MM / YYYY').tz('Europe/Madrid').format('YYYY'),
      });

    const auctionsPortugalData =
      await servicesMercadosPrecios.getAuctionsPortugalDataFromWidget({
        lang: state.exampleReducer.currentLang,
        time: `${currentHour}:${currentMinutes}`,
        day: moment(date, 'DD / MM / YYYY').tz('Europe/Madrid').format('DD'),
        month: moment(date, 'DD / MM / YYYY').tz('Europe/Madrid').format('MM'),
        year: moment(date, 'DD / MM / YYYY').tz('Europe/Madrid').format('YYYY'),
      });

    const tableData = auctionsData.indicators;
    const newTable = tableData.concat(auctionsPortugalData.indicators);

    // let tableContent = (data) => {
    //   const getDataForIdAndValue = (id, value = 'values') => {
    //     const found = data.find((el) => el.id === id);
    //     if (found) {
    //       if (value === 'values') {
    //         if (found[value].length > 0) {
    //           if (found.magnitud[0].id === 23) {
    //             return found[value][0].y.toFixed(2).toLocaleString();
    //           }
    //           if (found.magnitud[0].id === 21) {
    //             return found[value][0].y.toFixed(1).toLocaleString();
    //           }
    //           return found[value][0].y;
    //         } else {
    //           return '-';
    //         }
    //       }
    //       return found[value];
    //     }
    //   };

    //   const headTable = [
    //     `${i18n.t('auction')}`,
    //     `${i18n.t('num_of_participants')}`,
    //     `${i18n.t('num_of_purchaser')}`,
    //     `${i18n.t('price')} (€/MW)`,
    //     `${i18n.t('assignment')} (MW)`,
    //   ];

    //   let miArrayHead = `
    //     <thead class="esios-table__head">
    //       <tr class="esios-table__row">
    //         ${headTable
    //           .map(
    //             (el) => `<th>
    //           <div class="esios-table__cell">
    //             <p>${el}</p>
    //             <div class="esios-table__cell--sort"></div>
    //           </div>
    //         </th>`
    //           )
    //           .join('')}
    //       </tr>
    //     </thead>
    //   `;

    //   const tableData = [
    //     {
    //       text: `${i18n.t('esfr')}`,
    //       content: [
    //         [1074, 1074, 1076, 1080, 1078],
    //         [1082, 1082, 1084, 1088, 1086],
    //       ],
    //       footer: 'last_intraday_legend',
    //     },
    //     {
    //       text: `${i18n.t('fres')}`,
    //       content: [
    //         [1073, 1073, 1075, 1079, 1077],
    //         [1081, 1081, 1083, 1087, 1085],
    //       ],
    //       footer: 'last_intraday_legend',
    //     },
    //     {
    //       text: `${i18n.t('espt')}`,
    //       content: [
    //         [1639, 1639, 1641, 1645, 1643],
    //         [1647, 1647, 1649, 1653, 1651],
    //         [1655, 1655, 1657, 1661, 1659],
    //       ],
    //     },
    //     {
    //       text: `${i18n.t('ptes')}`,
    //       content: [
    //         [1638, 1638, 1640, 1644, 1642],
    //         [1646, 1646, 1648, 1652, 1650],
    //         [1654, 1654, 1656, 1660, 1658],
    //       ],
    //     },
    //   ];

    //   let parseData = (element, index) => {
    //     if (element !== '-' && typeof element === 'string') {
    //       if (index == 4) {
    //         return Utils.toNumber(element, 0, 'comma');
    //       }
    //       return Utils.toNumber(element, 2, 'comma');
    //     } else {
    //       return element;
    //     }
    //   };

    //   let miArrayTab = {
    //     activeTab: '1',
    //     tabsContent: tableData.map((el) => {
    //       return {
    //         text: el.text,
    //         class: 'tab-myp-content',
    //         content: `<div class="esios-tabla simple">
    //           <table class="esios-table">
    //             ${miArrayHead}
    //             <tbody>
    //                 ${el.content
    //                   .map((ele) => {
    //                     return `<tr class="esios-table__row">
    //                     ${ele
    //                       .map((el, index) => {
    //                         return `<td data-title='${headTable[index]}'>${
    //                           index % 5 === 0
    //                             ? getDataForIdAndValue(el, 'short_name')
    //                             : parseData(
    //                                 getDataForIdAndValue(el, 'values', index),
    //                                 index
    //                               )
    //                         }</td>`;
    //                       })
    //                       .join('')}
    //                     </tr>`;
    //                   })
    //                   .join('')}
    //             </tbody>
    //           </table>
    //         </div>

    //         ${
    //           el.footer
    //             ? `<p class="table-description cursive">
    //         ${i18n.t(el.footer)}
    //         </p>`
    //             : ''
    //         }`,
    //       };
    //     }),
    //   };
    //   return miArrayTab;
    // };

    let miArrayTab = intercambiosInternacionalesContent.tableContent(newTable);
    document.getElementById('spinner10').classList.add('hidden');

    let tableContent1 = document.getElementById('tab-myp-content1');
    tableContent1.innerHTML = miArrayTab.tabsContent[0].content;
    let tableContent2 = document.getElementById('tab-myp-content2');
    tableContent2.innerHTML = miArrayTab.tabsContent[1].content;
    let tableContent3 = document.getElementById('tab-myp-content3');
    tableContent3.innerHTML = miArrayTab.tabsContent[2].content;
    let tableContent4 = document.getElementById('tab-myp-content4');
    tableContent4.innerHTML = miArrayTab.tabsContent[3].content;
    componentTabs.render(miArrayTab, '#tabwithcontent');
  },
  17: async (data, currentDate, format) => {
    const indicators = data.indicators;
    //  Let's get time id from indicator (Check if this value comes from services)
    const timeID = indicators[0].time;
    /* Then let's get the value in minutes equivalent for id. 
     After that setting up options of chart. options > parseStep */
    let step = Utils.getTimeValue(timeID) || 10;
    const graphColors = ['#FFCF09', '#DF4A32', '#74BA05'];
    const lastDate = [];
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          lastDate.push(moment(date.x).tz('Europe/Madrid').format());
        });
      }
      return {
        id: el.id,
        name: el.name,
        type: 'line',
        values: el.values,
        dots: false,
        interpolation: el.step,
        color: graphColors[index] || getColour(index),
      };
    });
    Utils.orderObjectsArrayByKey(parsedData, [1293, 544, 545], 'id');
    var options = {
      target: d3.select('#widget17').node(),
      margin: `40 ${isImbedPage ? '45' : '8'} 25 45`,
      trail: {
        enabled: true,
        parseStep: function (date) {
          // "step" is the interval value in minutes for the chart
          date = moment(date).toDate();
          var minutes = date.getUTCMinutes();
          // Logic changed and "now" it is not currently used
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          minutes = Math.round(minutes / step) * step;
          date.setUTCMinutes(minutes, 0, 0);
          return date;
        },
        initXValue: function () {
          let { date } = getUrlParams();
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');

          if (formatedCurrentDate !== now) {
            return lastDate.at(-1);
          } else {
            return lastDate.at(-1);
          }
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 1],

        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();

            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        fit: true,
        fullGrid: true,
        textMarginTop: -6,
        ticks: [4],
        left: {
          label: 'MW',
          tickFormat: function (y) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };

    Utils.orderObjectsArrayByKey(parsedData, [544, 545, 1293], 'id');
    var chart = new Charicharts.Chart(options, parsedData);
    Utils.orderObjectsArrayByKey(parsedData, [1293, 544, 545], 'id');
    document.getElementById('widget17Data').innerHTML == ''
      ? document
          .getElementById('widget17Data')
          .insertAdjacentHTML('afterbegin', templateChart17())
      : '';
    const demandaRealElement = document.getElementById('demanda-real-value');
    const demandaProgramadaElement = document.getElementById(
      'demanda-programada-value'
    );
    const demandaPrevistaElement = document.getElementById(
      'demanda-prevista-value'
    );
    chart.on('Trail/moved', function (parsedData, d) {
      const graphTime = document.querySelector(
        '#char17 .esios-toolbar-time__component'
      );
      const graphHours = document.querySelector('#char17 #embed-hour');
      const graphDatetime = document.querySelector('#char17 #datepicker');

      if (graphTime) {
        graphTime.innerHTML = moment(d)
          .tz('Europe/Madrid')
          .format(format || 'HH:mm');
      }
      if (graphHours) {
        graphHours.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
      }
      if (graphDatetime) {
        graphDatetime.value = moment(d)
          .tz('Europe/Madrid')
          .format('DD / MM / YYYY');
      }

      demandaRealElement.innerHTML = Utils.toNumber(
        parsedData[0].y,
        0,
        'comma'
      );
      demandaProgramadaElement.innerHTML = Utils.toNumber(
        parsedData[1].y,
        0,
        'comma'
      );
      demandaPrevistaElement.innerHTML = Utils.toNumber(
        parsedData[2].y,
        0,
        'comma'
      );
    });
    Utils.orderObjectsArrayByKey(parsedData, [1293, 545, 544], 'id');
    const dataLength = indicators[0].values.length;
    const rect17 = document.getElementById('rect17');
    const wid17 = document.getElementById('widget17');
    if (dataLength === 0) {
      wid17.classList.add('no-data-graphic');
      rect17.classList.remove('hidden');
      document.querySelector('#widget17 > svg').classList.add('hidden');
      demandaRealElement.innerHTML = '-';
      demandaProgramadaElement.innerHTML = '-';
      demandaPrevistaElement.innerHTML = '-';
    } else {
      wid17.classList.remove('no-data-graphic');
      rect17?.classList.add('hidden');
    }

    var aspect = 300 / 200,
      chart = d3.select('#widget17');
    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });
  },
  18: async (data, currentDate, format) => {
    const indicators = data.indicators;
    let lastDate = [];
    const graphColors = [
      '#FFCF09',
      '#DF4A32',
      '#00A1DA',
      '#74BA05',
      '',
      '',
      '',
    ];

    const parsedData = indicators.map((el, index) => {
      el.values.map((date) => {
        lastDate.push(moment(date.x).tz('Europe/Madrid').format());
      });
      return {
        idOrder: index,
        id: `${el.id}` + `${el.geo_id}`,
        alias: el.alias,
        urlId: el.id,
        geo_id: el.geo_id,
        short_name: el.short_name,
        type: 'line',
        values: el.values,
        magnitud: el.magnitud,
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
        active: index > 3 ? '0' : '1',
        disabled: index > 3 ? true : false,
      };
    });
    const orderObjectsArrayByKey = (array, orderArr, key) => {
      const itemPositions = {};
      for (const [index, id] of orderArr.entries()) {
        itemPositions[id] = index;
      }
      const arrOrdered = array.sort(
        (a, b) => itemPositions[a[key]] - itemPositions[b[key]]
      );
      return arrOrdered;
    };

    const orderArr = [3, 2, 1, 0, 4, 5];
    orderObjectsArrayByKey(parsedData, orderArr, 'idOrder');

    var options = {
      target: d3.select('#widget18').node(),
      margin: '40 10 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          if (currentDate !== now) {
            return lastDate.at(-1);
          } else {
            return moment().tz('Europe/Madrid').format();
          }
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 1],

        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        fit: false,
        fullGrid: true,
        textMarginTop: -6,
        ticks: [6],
        left: {
          label: '€/MWh',
          tickFormat: function (y) {
            return withComasOrDot(y, ',');
          },
        },
      },
    };
    const newParsedData = [...parsedData];
    parsedData.pop();
    parsedData.pop();
    var chart = new Charicharts.Chart(options, parsedData);

    document.getElementById('widget18Data').innerHTML == ''
      ? document
          .getElementById('widget18Data')
          .insertAdjacentHTML('afterbegin', templateChart18())
      : '';

    chart.on('Trail/moved', function (parsedData, d) {
      const graphTime = document.querySelector('#char18 .mod-date');
      const graphEmbed = document.querySelector('#char18 #embed-hour');
      const mercadoyPreciosSecondLegend = document.querySelector(
        '#widget18Data .simple-legend'
      );
      if (graphEmbed) {
        graphEmbed.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
      }
      if (graphTime) {
        graphTime.innerHTML = moment(d)
          .tz('Europe/Madrid')
          .format(format || 'HH:mm');
      }

      document.getElementById('mp-pvpc').innerText = Utils.toNumber(
        parsedData[3].y,
        2,
        'comma'
      );

      document.getElementById('mp-spot-portugal').innerText = Utils.toNumber(
        parsedData[0].y,
        2,
        'comma'
      );

      document.getElementById('mp-spot-france').innerText = Utils.toNumber(
        parsedData[1].y,
        2,
        'comma'
      );

      document.getElementById('mp-spot-spain').innerText = Utils.toNumber(
        parsedData[2].y,
        2,
        'comma'
      );

      mercadoyPreciosSecondLegend.innerHTML = '';
      newParsedData.forEach((el, index) => {
        if (index === 4 || index === 5) {
          let currentObj = el.values.find((el) => {
            if (
              moment(el.x).format('DD-MM-YYYYT:HH:mm') ===
              moment(d).format('DD-MM-YYYYT:HH:mm')
            ) {
              return el;
            }
          });
          mercadoyPreciosSecondLegend.innerHTML += `
          <li
            class="legend-item"
            id="termino-de-facturacion-de-energia-activa-del-pvpc-20td1001"
          >
            <div class="item">
              <span class="label strong">${el.alias}</span>
            </div>
            <div class=" value is-number">
              <span id="mp-pvpc" class="caption is-medium">
              ${
                currentObj?.y !== null
                  ? Utils.toNumber(currentObj?.y, 2, 'comma')
                  : '-'
              }
              </span>
              <span class="unit">${MAGNITUD_SYMBOLS[el?.magnitud[0].id]}</span>
              <a
                href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${
            el?.urlId
          }${el?.urlId === 600 ? '?geoids=' + el.geo_id : ''}"
                class="esios-toolbar__buttons toolbar-analysis"
              >
                <img
                style="vertical-align: middle"
                src="${statsImg}"
              />
              </a>
            </div>
          </li>`;
        }
      });
    });
    document.getElementById('title-pvpc').innerHTML = parsedData[3].alias;
    document.getElementById('title-por').innerHTML = parsedData[0].alias;
    document.getElementById('title-fra').innerHTML = parsedData[1].alias;
    document.getElementById('title-esp').innerHTML = parsedData[2].alias;
    const dataLength = indicators[1].values.length;
    const rect18 = document.getElementById('rect18');
    const wid18 = document.getElementById('widget18');
    if (dataLength === 0) {
      wid18.classList.add('no-data-graphic');
      rect18?.classList.remove('hidden');
      document.querySelector('#widget18 > svg').classList.add('hidden');
      const ulListInteractive = document.querySelectorAll(
        '#widget18Data ul .legend-item .caption'
      );

      ulListInteractive.forEach((el) => {
        el.innerHTML = '-';
      });
    } else {
      wid18.classList.remove('no-data-graphic');
      rect18.classList.add('hidden');
    }

    var aspect = 300 / 200,
      chart = d3.select('#widget18');
    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });
  },
  19: async (data) => {
    const indicators = data.indicators;
    const demandWidgetList = document.querySelector('#widget19');
    demandWidgetList.innerHTML = '';
    indicators.map((el) => {
      demandWidgetList.innerHTML += `
    <div class="widget-summary-item widget-summary-item-10245">
      <div class="label label--demanda is-small mt-2">${el.alias}</div>
      <div class="value ">
        <div class="is-number caption caption--demanda is-medium">
        ${el.values[0] ? Utils.toNumber(el.values[0]?.y, 1, 'comma') : '-'}
       <span class="unit">MWh</span>
        </div>
      </div>
    </div>
  `;
    });
  },
  20: async (data) => {
    document.getElementById('widget20Data').innerHTML == '';

    document.getElementById('widget20Data').innerHTML = templateChart20();

    // Obtener la fecha del datepicker y convertirla a un objeto Date
    const datepickerValue = document.getElementById('datepicker').value;
    const [day, month, year] = datepickerValue.split('/').map(Number);
    const selectedDate = new Date(year, month - 1, day);

    // Fecha límite (04/12/2024)
    const limitDate = new Date(2024, 11, 4); // Meses en JavaScript van de 0 a 11
    document.getElementById('widget20Data').innerHTML == '';

    document.getElementById('widget20Data').innerHTML = templateChart20();

    let updatedIndicators;

    let graphColor;

    if (selectedDate > limitDate) {
      // Filtrar el array para eliminar el objeto con id 1152
      graphColor = [
        '#0090d1',
        '#6fb114',
        '#e48500',
        '#ff0000',
        '#9a5cbc',
        '#a0a0a0',
        '#464394',
        '#ffcc66',
        '#993300',
        '#ba0f16',
        '#cfa2ca',
        '#666666',
      ];
      updatedIndicators = data.indicators.filter(
        (indicator) => indicator.id !== 1152
      );
    } else {
      graphColor = [
        '#0090d1',
        '#6fb114',
        '#e48500',
        '#ff0000',
        '#9a5cbc',
        '#a0a0a0',
        '#464394',
        '#007cf9',
        '#ffcc66',
        '#993300',
        '#ba0f16',
        '#cfa2ca',
        '#666666',
      ];
      updatedIndicators = data.indicators;
    }

    const indicators = updatedIndicators;

    let sumaTotal = 0;

    const renovableFilter = [10035, 10037, 1161, 1162, 10041, 10062];

    let arrGeneMed = indicators.map((elemnt, index) => {
      let value = 0;
      if (elemnt.values[0]?.y !== undefined) {
        value = elemnt.values[0].y;
      }
      sumaTotal += value;
      return {
        id: elemnt.id,
        name:
          elemnt.short_name.charAt(0).toUpperCase() +
          elemnt.short_name.slice(1),
        value,
        color: graphColor[index],
        renovable: renovableFilter.includes(elemnt.id),
      };
    });

    // Filtrar arrGeneMed para eliminar el objeto con id 1152 si se cumple la condición
    if (selectedDate > limitDate) {
      arrGeneMed = arrGeneMed.filter((item) => item.id !== 1152);
    }

    let renovablePercentage = 0;
    let noRenovablePercentage = 0;

    let finalArrGeneMed = arrGeneMed.map((arrGM) => {
      if (arrGM.renovable) {
        renovablePercentage += arrGM.value;
      } else {
        noRenovablePercentage += arrGM.value;
      }
      arrGM.value = ((arrGM.value / sumaTotal) * 100).toFixed(1);
      return arrGM;
    });

    let homeBalanceWidget = document.querySelectorAll(
      '#char20 .esios-listEnergyItems .esios-listEnergyItems__list li'
    );

    const homeBalanceRenovable = document.querySelector(
      `#char20 .esios-listEnergyItems [data-id="renovable"]`
    );
    const homeBalanceNoRenovable = document.querySelector(
      `#char20 .esios-listEnergyItems [data-id="no-renovable"]`
    );

    homeBalanceRenovable.innerHTML = `
          <div class="value is-number">
            ${Utils.toNumber(
              ((renovablePercentage / sumaTotal) * 100).toFixed(1),
              1,
              'comma'
            )}
            <span class="unit">%</span>
          </div>
        `;
    homeBalanceNoRenovable.innerHTML = `
          <div class="value is-number">
            ${Utils.toNumber(
              ((noRenovablePercentage / sumaTotal) * 100).toFixed(1),
              1,
              'comma'
            )}
            <span class="unit">%</span>
          </div>
        `;

    homeBalanceWidget.forEach((el, index) => {
      if (arrGeneMed[index] && finalArrGeneMed[index]) {
        // Verificar que los objetos existen
        let current = document.querySelector(
          `#char20 .esios-listEnergyItems [data-id="${arrGeneMed[index].id}"]`
        );

        // Eliminar el elemento con data-id 1152 si se cumple la condición
        if (selectedDate > limitDate && el.getAttribute('data-id') === '1152') {
          el.remove();
          return; // Salir de la iteración actual ya que el elemento ha sido eliminado
        }

        if (
          el.getAttribute('data-id') === finalArrGeneMed[index].id.toString()
        ) {
          current.innerHTML = `
                <div class="esios-listEnergyItems__label ">
                  <span
                    class="esios-listEnergyItems__decorator"
                    style="background: ${finalArrGeneMed[index].color};"
                  ></span>
                  ${finalArrGeneMed[index].name}
                </div>
                <div class="value is-number">
                  ${
                    finalArrGeneMed[index].value === '-0.0'
                      ? 0
                      : Utils.toNumber(finalArrGeneMed[index].value, 1, 'comma')
                  }
                  <span class="unit">%</span>
                </div>
              `;
        }
      }
    });

    // Crear una copia del array sin el elemento con id=1152 si la condición de fecha se cumple
    let filteredArrGeneMed = arrGeneMed.filter((item) => {
      return !(selectedDate > limitDate && item.id === 1152);
    });

    // Iterar sobre la copia filtrada para actualizar los elementos de la leyenda
    filteredArrGeneMed.forEach((item, index) => {
      let current = document.querySelector(
        `#char20 .esios-listEnergyItems [data-id="${item.id}"]`
      );

      if (current) {
        current.innerHTML = `
              <div class="esios-listEnergyItems__label ">
                <span
                  class="esios-listEnergyItems__decorator"
                  style="background: ${item.color};"
                ></span>
                ${item.name}
              </div>
              <div class="value is-number">
                ${
                  item.value === '-0.0'
                    ? 0
                    : Utils.toNumber(item.value, 1, 'comma')
                }
                <span class="unit">%</span>
              </div>
            `;
      }
    });

    let options = {
      target: d3.select('#chart').node(),
      innerArrow: true,
      hoverFade: 1,
    };

    var nbar = new Charicharts.PercentageBar(
      {
        target: d3.select('#bar').node(),
      },
      [
        {
          id: 1,
          value: renovablePercentage,
          color: '#99ff33',
        },
        {
          id: 2,
          value: noRenovablePercentage,
          color: '#c0c0c0',
        },
      ]
    );

    let pie = new Charicharts.Pie(options, arrGeneMed);

    let graphPieLegend = document.querySelector('#char20 .chartLegend');
    pie.on('Pie-piece/mouseover', function (d) {
      graphPieLegend.innerHTML = `
        <h3 class="title">${d.data.name}</h3>
        <div class="value">
          ${Utils.toNumber(d.data.value, 1, 'comma')}
          <div class="unit">%</div>
        </div>
        <div class="description">${
          d.data.renovable ? i18n.t('renewable') : i18n.t('no_renewable')
        }</div>
        `;
    });

    if (pie.pie) {
      pie.pie.triggerMouseover(1);
    }

    var aspect = 300 / 200,
      chart = d3.select('#chart');
    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });
    if (document.querySelector('#char20')) {
      if (data.indicators[0].values.length === 0) {
        document.querySelector('#char20 svg')?.remove();
        if (document.querySelector('#rect20')) {
          document.querySelector('#rect20').style.display = 'block';
        }
        document.querySelector('#char20 .chartLegend').style.display = 'none';
        document
          .querySelector('#char20 .esios-pie-graph')
          .classList.add('no-data-graphic');

        document.querySelector('#char20 .no-data-graphic').style.width =
          '280px';
      } else {
        document.querySelector('#char20 .chartLegend').style.display = 'block';
        if (document.querySelector('#rect20')) {
          document.querySelector('#rect20').style.display = 'none';
        }
        if (window.location.pathname.includes(i18n.t('routes.embed'))) {
          let timeVal = data.indicators[0].values[0].x;
          document.getElementById('datepicker').value = moment(timeVal)
            .tz('Europe/Madrid')
            .format('MM / YYYY');
        }
        document.getElementById('widget20Data').style.maxHeight =
          document.getElementById('widget20Data').offsetHeight + 'px';
        document
          .querySelector('#char20 .esios-pie-graph')
          .classList.remove('no-data-graphic');
      }
    }
    document.querySelector('.inner-arrow')?.setAttribute('id', 'innerarrowdd');
  },
  21: async (data, currentDate) => {
    moment.locale(i18n.t('routes.lng'));
    const indicators = data.indicators;
    const colorGraph = ['#66CC33', '#FF8000'];
    const parsedData = indicators.map((el, index) => {
      return {
        id: el.id,
        type: 'line',
        values: el.values,
        dots: false,
        interpolation: 'linear',
        color: colorGraph[index],
      };
    });
    // moment({ day: 1 }).hours(0).minutes(0).seconds(0);
    var options = {
      target: d3.select('#widget21').node(),
      margin: '40 30 24 50',
      trail: {
        enabled: true,
        parseStep: function (date) {
          date = moment(date).startOf('month').toDate();
          return date;
        },
      },
      xaxis: {
        fit: true,
        ticks: [d3.time.months, 0],
        bottom: {
          tickFormat: function (d, i) {
            if (i % (window.innerWidth <= 1090 ? 3 : 2)) {
              return '';
            } else {
              return moment(d)
                .tz('Europe/Madrid')
                .format('MMM YYYY')
                .toUpperCase();
            }
          },
        },
      },
      yaxis: {
        fit: true,
        fullGrid: true,
        textMarginTop: -6,
        ticks: [4],
        left: {
          label: 'GWh',
          tickFormat: function (d) {
            return (d / 1000).toLocaleString();
          },
        },
      },
    };
    var chart = new Charicharts.Chart(options, parsedData);
    document.getElementById('widget21Data').innerHTML == ''
      ? document
          .getElementById('widget21Data')
          .insertAdjacentHTML('afterbegin', templateChart21())
      : '';
    chart.on('Trail/moved', function (parsedData, d) {
      let dProc = moment(d)
        .startOf('month')
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY');
      const bCVal = indicators[0].values.filter((el) => {
        let daySelected = moment(el.x).tz('Europe/Madrid').format('DD-MM-YYYY');
        if (dProc === daySelected) {
          return el.y;
        }
      })[0];
      const demandVal = indicators[1].values.filter((el) => {
        let daySelected = moment(el.x).tz('Europe/Madrid').format('DD-MM-YYYY');
        if (dProc === daySelected) {
          return el.y;
        }
      })[0];
      let graphTime = '';
      let day = moment(d).tz('Europe/Madrid').format('MM / YYYY');
      if (window.location.pathname.includes(i18n.t('routes.embed'))) {
        graphTime = document.querySelector('#char21 #datepicker');
        graphTime.value = day;
      } else {
        graphTime = document.querySelector('#char21 .mod-date');
        graphTime.innerHTML = day;
      }
      const evolutionConsumptionElement = document.getElementById(
        'demand-evolution-consumption'
      );
      const evolutionBCElement = document.getElementById('demand-evolution-bc');
      evolutionConsumptionElement.innerHTML = Utils.toNumber(
        bCVal.y / 1000,
        1,
        'comma'
      );
      evolutionBCElement.innerHTML = Utils.toNumber(
        demandVal.y / 1000,
        1,
        'comma'
      );
    });
    var aspect = 350 / 200,
      chart = d3.select('#widget21');
    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });
  },
  22: async (data, currentDate = false) => {
    const indicators = data.indicators;
    const dataLength = indicators[0].values.length;
    const timeID = indicators[0].time;
    const indicatorsId = document.querySelector('#indicadores');
    const graphColors = ['rgb(221, 221, 221)', 'rgb(0, 162, 216)', '#FFC182'];
    const lastDate = [];
    indicators[0].values.length === 0
      ? (indicatorsId.hidden = true)
      : (indicatorsId.hidden = false);
    const parsedData = indicators
      .sort((a, b) => a.id - b.id)
      .map((el, index) => {
        if (index === 0) {
          el.values.map((date) => {
            if (date.y !== null) {
              lastDate.push(moment(date.x).tz('Europe/Madrid').format());
            }
          });
        }
        if (index === 2) {
          return {
            id: el.id,
            alias: el.alias,
            short_name: el.short_name,
            type: 'constant',
            color: graphColors[index],
            strokeWidth: 2,
            cteAxis: 'y',
            value: el.values.length > 0 ? el.values[0].y : undefined,
          };
        }
        if (index === 1) {
          return {
            id: el.id,
            alias: el.alias,
            short_name: el.short_name,
            type: 'line',
            values: el.values.map((val) => {
              return {
                ...val,
                y: val.y === null ? null : val.y / 2,
              };
            }),
            dots: false,
            interpolation: 'linear',
            color: graphColors[index],
            fill: graphColors[index],
            opacity: index === 0 ? 0.5 : 1,
            areaOpacity: index === 0 ? 0.5 : 1,
          };
        }
        return {
          id: el.id,
          alias: el.alias,
          short_name: el.short_name,
          type: 'line',
          values: el.values,
          dots: false,
          interpolation: 'linear',
          color: graphColors[index],
          fill: graphColors[index],
          opacity: index === 0 ? 0.8 : 1,
          areaOpacity: index === 0 ? 0.8 : 1,
        };
      });

    const testParsedData = [...parsedData];

    const newDataParsed = [
      {
        type: 'area',
        stacking: false,
        data: [parsedData[0], parsedData[1]],
      },
      parsedData[2],
      {
        ...parsedData[0],
        id: parsedData[0].id + 'border',
      },
      {
        ...parsedData[1],
        id: parsedData[1].id + 'border',
      },
    ];

    var options = {
      target: d3.select('#gdDemandaWidgetView .chari-chart').node(),
      margin: '40 40 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          date = moment(date).toDate();
          var minutes = date.getUTCMinutes();
          minutes = Math.floor(minutes / 10) * 10;
          date.setUTCMinutes(minutes, 0, 0);
          return date;
        },
        initXValue: function () {
          return lastDate.at(-1);
        },
      },
      xaxis: {
        scale: 'time',
        ticks: [d3.time.hours, 1],
        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        fit: true,
        fullGrid: true,
        ticks: [7],
        textMarginTop: -10,
        left: {
          label: 'MW',
          tickFormat: function (d) {
            if (!d) {
              return;
            }
            return Math.ceil(d * 2).toFixed(0);
          },
        },
        right: {
          label: 'tCO2',
          enabled: true,
          tickFormat: function (d) {
            if (!d) {
              return;
            }

            return Math.ceil((d / 2) * 2).toFixed(0);
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, newDataParsed);

    let graphInterrumpibilidadLegendsC = document.querySelector(
      '#gdDemandaWidgetView .widget-legend.in-line.visual-legend ul'
    );
    graphInterrumpibilidadLegendsC.innerHTML = '';

    const graphColorsEyes = ['#DDDDDD', '#00A2D8', '#FFC182'];
    testParsedData.map((el, index) => {
      graphInterrumpibilidadLegendsC.innerHTML += `
    <li class="legend-item">
          <div class="item">
          <span
              class="2 legend-icon-eye icon-eye"
              data-id="${el.id}"
              style="background-color: ${graphColorsEyes[index]};"></span>
            <span class="label"> ${el.alias}</span>
          </div>
      </li>`;
    });

    chart.on('Trail/moved', function (parsedData, date) {
      parsedData = [testParsedData[0], testParsedData[1]];
      const graphTime = document.querySelector(
        '#gdDemandaWidgetView .mod-date'
      );
      const graphEmbedTime = document.querySelector('#char22 #embed-hour');
      if (graphTime) {
        graphTime.innerHTML = testParsedData[0].values[0].x
          ? moment(date).tz('Europe/Madrid').format('HH:mm')
          : '00:00';
      }
      if (graphEmbedTime) {
        graphEmbedTime.innerHTML = testParsedData[0].values[0].x
          ? moment(date).tz('Europe/Madrid').format('HH:mm')
          : '00:00';
      }
      testParsedData.map((el) => {
        el.values?.map((val) => {
          if (moment(val.x).format('HH:mm') === moment(date).format('HH:mm')) {
            if (el.id === 847) {
              document.querySelector(
                '.esios-gd__is-number--first .value'
              ).innerHTML =
                val.y === null ? '-' : Utils.toNumber(val.y * 2, 1, 'comma');
              document.querySelector(
                '.esios-gd__is-number--first .esios-gd__label'
              ).innerHTML = el.alias;
            }
            if (el.id === 835) {
              document.querySelector(
                '.esios-gd__is-number--second .value'
              ).innerHTML =
                val.y === null ? '0' : Utils.toNumber(val.y, 1, 'comma');
              document.querySelector(
                '.esios-gd__is-number--second .esios-gd__label'
              ).innerHTML = el.alias;
            }
          }
        });
      });
    });

    const rectNoData = document.getElementById('dmNoDataRect');
    const rectNoData2 = document.getElementById('dmNoDataRect2');
    const wid3 = document.getElementById('breakdownGraphic');
    const intDemand = document.querySelector(
      '#gdDemandaWidgetView #chari-chart'
    );
    const intDemandSvg = document.querySelector(
      '#gdDemandaWidgetView #chari-chart svg'
    );
    const noData = document.querySelector('#gdDemandaWidgetView #widget22Data');
    if (dataLength != 0) {
      intDemand?.classList.remove('no-data-graphic-demand');
      wid3?.classList.remove('no-data-graphic');
      document
        .querySelector('.no-data-graphic-demand')
        ?.classList.add('hidden');
      rectNoData?.classList.add('hidden');
      rectNoData2?.classList.add('hidden');
      noData?.classList.remove('mt-4');
    } else {
      intDemandSvg?.remove();
      noData?.classList.add('mt-4');
      intDemand?.classList.add('no-data-graphic-demand');
      wid3?.classList.add('no-data-graphic');
      document
        .querySelector('.no-data-graphic-demand')
        .classList.remove('hidden');
      rectNoData.classList.remove('hidden');
      rectNoData2.classList.remove('hidden');
      document.querySelector('#chari-chart svg')?.classList.add('hidden');
    }

    // const demandaGmain = document.querySelector(
    //   '#gdDemandaWidgetView svg .g-main'
    // );
    // demandaGmain.setAttribute('transform', 'translate(45, 50)');
    // const facturawidgetSVG = document.querySelector('#gdDemandaWidgetView svg');
    // facturawidgetSVG.setAttribute('preserveAspectRatio', 'xMinYMin meet');
    // facturawidgetSVG.setAttribute('viewBox', '0 0 520 580');
    // facturawidgetSVG.setAttribute('width', '520px');
    // facturawidgetSVG.setAttribute('height', '580px');
    // facturawidgetSVG.removeAttribute('width');
    // facturawidgetSVG.removeAttribute('height');
  },
  23: async (data) => {
    let selectedBalance = 'ccaa';
    const state = store.getState();
    let currentMinutes = '00';
    let currentHour = '00';
    let date = document.getElementById('datepicker').value;

    if (date) {
      let currentMinutes10in10 = `${Math.trunc(
        moment().tz('Europe/Madrid').format('mm') / 10
      )}0`;

      currentHour = moment().tz('Europe/Madrid').format('HH');
      currentMinutes = currentMinutes10in10;

      if (
        moment().format('DD-MM-YYYY') !=
        moment(date, 'DD / MM/ YYYY').format('DD-MM-YYYY')
      ) {
        currentHour = '00';
        currentMinutes = '00';
      }
    }

    const selectDesglose = {
      id: 'select_desglose_widget',
      currentLabel: 'ccaa',
      currentValue: 'ccaa',
      label: '',
      options: [
        { label: 'ccaa', value: 'ccaa' },
        { label: 'sectors', value: 'sector' },
      ],
      columns: 1,
      onlySelect: true,
      changeTime: (value) => changeSelectValue(value),
    };

    let selectTimeDesglose = {
      id: 'selectTimeDesglose',
      currentHour,
      currentMinutes,
      optionsHours: Utils.getArrayOfHours(),
      optionsMinutes: Utils.getArrayOfMinutes(5),
      changeTime: changeSelectValue,
    };
    document.querySelector('#char23 .legend-potencial-pie ul')?.remove();

    const svgNoData = document.getElementById('breakdownGraphic');
    const rectNoData = document.getElementById('dmNoDataRect2');
    if (
      data.indicators[0]?.values.length != 0 &&
      data.indicators[0]?.values.length != undefined
    ) {
      rectNoData?.classList.add('hidden');
      svgNoData?.classList.remove('no-data-graphic');
    } else {
      rectNoData.classList.remove('hidden');
      svgNoData.classList.add('no-data-graphic');
    }

    const changeSelectValue = (value) => {
      const { id, newValue } = value;
      let hoursSelect = document.querySelector(
        '#selectTimeDesglose .esios-toolbar-time__mod-date'
      )?.textContent;
      if (hoursSelect == undefined) {
        hoursSelect = currentHour + ':' + currentMinutes;
      }
      hoursSelect = hoursSelect.trim();
      let hours = hoursSelect.split(':')[0];
      let minutes = hoursSelect.split(':')[1];

      let renderDesgloceAgain = () => {
        if (window.location.pathname.includes(i18n.t('routes.embed'))) {
          document.querySelector(`#char23`).style.opacity = '.5';
          document.getElementById(`spinner23`)?.classList.remove('hidden');
          document.querySelector('#char23 .legend-potencial-pie ul')?.remove();
          document.querySelector('#char23 .piePotencia svg')?.remove();
          document.querySelector('#char23 .chartLegend')?.remove();
        } else {
          Utils.spinnerStatus('spinner23', true);
          document
            .querySelector('#widget23 .legend-potencial-pie ul')
            ?.remove();
          document.querySelector('#widget23 .piePotencia svg')?.remove();
          document.querySelector('#widget23 .chartLegend')?.remove();
        }

        let dateFormat = moment(
          document.querySelector('#datepicker').value,
          'DD / MM / YYYY'
        ).format('DD-MM-YYYY');
        gestionDemandaService
          .getDesgloseDataFromWidget({
            lang: state.exampleReducer.currentLang,
            hours,
            minutes,
            date: dateFormat,
          })
          .then((data) => {
            if (window.location.pathname.includes(i18n.t('routes.embed'))) {
              document.getElementById(`spinner23`)?.classList.add('hidden');
              document.querySelector(`#char23`).style.opacity = '1';
            } else {
              Utils.spinnerStatus('spinner23', false);
            }
            if (data.indicators[0]?.values.length === 0) {
              selectTimeDesglose.currentHour = '00';
              selectTimeDesglose.currentMinutes = '00';
            }
            gestionDemandaRenderContent.renderPie(data, selectedBalance);
          });
      };

      if (id === selectTimeDesglose.id) {
        selectTimeDesglose.optionsHours.map((el) => {
          if (el.value === hours) {
            selectTimeDesglose.currentHour = hours;
            selectTimeDesglose.currentMinutes = minutes;
            renderDesgloceAgain();
          }
        });

        return;
      }

      if (selectDesglose.id === id) {
        selectDesglose.options.map((el) => {
          if (el.value === newValue) {
            selectDesglose.currentLabel = el.label;
            selectDesglose.currentValue = el.value;
            selectedBalance = newValue;
            componentSelects.render(selectDesglose, '.esios-gd__selector');
            renderDesgloceAgain();
          }
        });
      }
    };
    componentSelects.render(selectDesglose, '.esios-gd__selector');
    changeSelectValue({ newValue: 'ccaa', id: 'select_desglose_widget' });

    document
      .querySelector('#select_desglose_widget')
      .addEventListener('click', () => {
        document
          .querySelector('#selectTimeDesglose.active')
          ?.classList.remove('active');
      });

    document
      .querySelector('#selectTimeDesglose')
      ?.addEventListener('click', () => {
        document
          .querySelector(
            '#select_desglose_widget > .select-time-container.active'
          )
          ?.classList.remove('active');
      });
  },
  24: async () => {
    let categories = balanceObjects.categories,
      programs = balanceObjects.programs,
      selectedProgram,
      selectedAgg,
      finalItems = [];

    const state = store.getState();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let dateDatepicker = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    ).format('DD-MM-YYYY');

    let selectedDate = moment(dateDatepicker, 'DD-MM-YYYY').format(
      'YYYY-MM-DD'
    );
    const setParameter = (key, value) => {
      urlParams.set(key, value);
      history.pushState({}, '', window.location.pathname + '?' + urlParams);
    };

    selectedProgram = getUrlParams()?.program || 'P48';
    selectedAgg = getUrlParams()?.agg || 'hour';
    setParameter('program', selectedProgram);
    setParameter('agg', selectedAgg);

    const selectedPrograms = programs.filter((item) => {
      if (item.name === selectedProgram) {
        return item;
      }
    })[0];

    let lists = document.querySelectorAll(
      '#char24 .esios-listEnergyItems__list'
    );
    let energyItems = await servicesBalance.dataTabs(
      state.exampleReducer.currentLang,
      categories.map((item) => item.indicators[0]),
      selectedAgg,
      selectedDate
    );

    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      energyItems,
      idOrderArr,
      'id'
    );
    let listNames = orderedBarData.map((el) => {
      return el;
    });

    let arrayOfEnergies = categories.map((el) => el.name);
    energyItems.forEach((element, i) => {
      let energies = element.short_name;
      let energiesList = arrayOfEnergies[i];

      if (energies.toUpperCase() === energiesList.toUpperCase()) {
        finalItems.push(energyItems[i]);
      } else {
        let noMatch = energyItems.find(
          (item) => item.short_name.toUpperCase() === energiesList.toUpperCase()
        );

        if (energiesList.charAt(0).toUpperCase() === 'A') {
          finalItems.push(
            energyItems.find(
              (el) => el.short_name.charAt(0).toUpperCase() === 'A'
            )
          );
        } else if (noMatch == undefined || null) {
          finalItems.push(energyItems[i]);
        } else {
          finalItems.push(noMatch);
        }
      }
    });
    let htmlContent = listNames
      .map((item, i) => {
        return `
        <li
          class="esios-listEnergyItems__item"
          value="${item.short_name}"
        >
          <span
            class=${
              categories[i].legendtype == 'circle'
                ? 'esios-listEnergyItems__decorator'
                : 'esios-listEnergyItems__line'
            }
            style="background:${categories[i].color};"
          ></span>
          <p>
            ${
              item.id === 10025
                ? `${i18n.t('programs_adjustment')}`
                : item.short_name
            }
          </p>
        </li>
      `;
      })
      .join('');
    lists.forEach((el) => {
      el.innerHTML = htmlContent;
    });
    document.getElementById('changeRange1').click();
    let listTwo = document.getElementById('list_selections');
    let list = document.getElementById('list_programs');

    if (listTwo.className === 'esios-select__selectableHidden') {
      listTwo.classList.remove('esios-select__selectableHidden');
      listTwo.classList.add('esios-select__selectable');
      list.classList.add('esios-select__selectableHidden');
    } else {
      listTwo.classList.remove('esios-select__selectable');
      listTwo.classList.add('esios-select__selectableHidden');
    }

    let graphData = await servicesBalance.dataTabs(
      state.exampleReducer.currentLang,
      selectedPrograms.indicators,
      selectedAgg,
      selectedDate
    );

    await renderGraph(
      graphData,
      selectedPrograms,
      categories,
      selectedAgg,
      selectedProgram,
      dateDatepicker
    );
  },
  25: async (data) => {
    let indicators = data.indicators.map((el) => {
      if (el.values[0]) {
        el.values[0].y0 = el.values[0]?.y;
        el.values[0].y =
          el.values[0]?.y <= 0 ? el.values[0]?.y * -1 : el.values[0]?.y;
      }
      return el;
    });
    const state = store.getState();
    let time = '00:00';
    const otherIndicatorsList = document.querySelector('#widget25');

    otherIndicatorsList.innerHTML = '';
    indicators.map((el, index) => {
      otherIndicatorsList.innerHTML += el.short_name
        ? `
    <div class="legend-item widget-summary-item widget-summary-item-${el.id}">
    <div class="item">
      <div class="label le">
        ${el.short_name} ${
            el.values[0]
              ? `(${moment(el.values[0]?.x)
                  .tz('Europe/Madrid')
                  .format('HH:mm')})`
              : ''
          }  
      </div>
    </div>
    <div class="value ${
      el.values[0] && el.values[0]?.y0 >= 0 ? 'true' : 'false'
    }">
    <span class="icon"> </span>
      <span class="is-number caption is-medium">
      ${
        el.values[0]
          ? el.values[0]?.y < 0
            ? Utils.toNumber(el.values[0].y * -1, 1, 'comma')
            : Utils.toNumber(el.values[0].y, 1, 'comma')
          : ''
      }
      <span class="unit">
      ${el.values[0] ? magnitudToSymbol(el.magnitud[0]?.id) : '-'}
      </span>
      </span>
      <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${
            el.id
          }" class="esios-toolbar__buttons toolbar-analysis">
            <img style="vertical-align: middle" src="${statsImg}">
            </a>
    </div>
  </div>`
        : `
    <div class="legend-item widget-summary-item widget-summary-item-${el.id}">
    <div class="item">
      <div class="label">
      ${el.short_name} ${
            el.values[0]
              ? `(${moment(el.values[0]?.x)
                  .tz('Europe/Madrid')
                  .format('HH:mm')})`
              : ''
          }  
      </div>
    </div>
    <div class="value ${
      el.values[0] && el.values[0]?.y0 >= 0 ? 'true' : 'false'
    }">    
    <span class="icon"> </span>
    <span class="is-number caption is-medium">
    ${
      el.values[0]
        ? el.values[0]?.y < 0
          ? Utils.toNumber(el.values[0].y * -1, 1, 'comma')
          : Utils.toNumber(el.values[0].y, 1, 'comma')
        : ''
    }
  <span class="unit">
  ${el.values[0] ? magnitudToSymbol(el.magnitud[0]?.id) : '-'}
      </span>
      </span>
      <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${
            el.id
          }" class="esios-toolbar__buttons toolbar-analysis">
          <img style="vertical-align: middle" src="${statsImg}">
          </a>
  </div>
</div>`;
    });
  },
  26: async (data) => {
    let indicators = data.indicators;
    indicators = data.indicators.map((el, index) => {
      // Calcula y aplica un valor de desplazamiento en el caso de venir un mínimo con valor negativo.
      let offset =
        el.display_options?.min < 0 ? el.display_options?.min * -1 : 0;
      let max = el.display_options?.max + offset;
      let min = el.display_options?.min + offset;

      let value = el.values[0]?.y + offset;
      let average = (max - min) / 2;

      el.resultMax = (max - value) / average;
      el.resultMin = (value - min) / average;
      el.resultMin < el.resultMax
        ? (el.result = el.resultMin)
        : (el.result = el.resultMax);
      return el;
    });

    indicators = indicators.sort((a, b) => {
      if (a.result < b.result) {
        return -1;
      }
      if (a.result > b.result) {
        return 1;
      }
      return 0;
    });

    const sortOrder = indicators.map((el, index) => {
      return el.id;
    });

    const systemStateList = document.querySelector('#widget26');
    systemStateList.innerHTML = '';
    indicators.sort(function (a, b) {
      return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
    });

    let systemDateTime = document.querySelector('#system-status-time');
    if (systemDateTime) {
      systemDateTime.innerHTML = moment()
        .tz('Europe/Madrid')
        .format('DD / MM / YYYY | HH:mm');
    }

    indicators.map((el, index) => {
      let left =
        ((el.values[0]?.y - el.display_options?.min) * 100) /
          (el.display_options?.max - el.display_options?.min) >=
        100
          ? 100
          : ((el.values[0]?.y - el.display_options?.min) * 100) /
            (el.display_options?.max - el.display_options?.min);
      left = left < 0 ? 0 : left;

      systemStateList.innerHTML += `
      <div class="indicator-row" style="${
        isWinter && el?.alias === 'Demanda invierno' ? 'display: none' : ''
      }">
          <h2 class="indicator-name">${el?.short_name}</h2>
          <div class="is-left">
          <div class="esios-toolbar-time__component mod-date" id="system-state-pvpc">
          ${
            el.values[0]
              ? moment(el.values[0].x).tz('Europe/Madrid').format('HH:mm')
              : '00:00'
          }
            </div>
        </div>
          <div class="indicator-info ${
            index === 0
              ? `icon-metafora-${el.id}-colored`
              : `icon-metafora-${el.id}`
          }"> 
          <div class="value">
            <span class="caption is-large">${
              el.alias == 'Saldo total interconexiones programa p48' &&
              el.values[0]?.y < 0
                ? Utils.toNumber((-1 * el.values[0]?.y).toFixed(1), 1, 'comma')
                : el.id === 1001
                ? Utils.toNumber(el.values[0]?.y, 2, 'comma')
                : el.id === 1293
                ? Utils.toNumber(el.values[0]?.y, 0, 'comma')
                : Utils.toNumber(el.values[0]?.y, 1, 'comma')
            }</span>
            <span class="unit">${magnitudToSymbol(el.magnitud[0]?.id)}</span>
          </div>
    
          <div class="maxmin-indicator">
            <div class="middle-tick"></div>
            <div class="is-micro maxmin-label">
              <span class="is-smaller text">${
                el.id === 10026 ? 'Exp.' : 'Mín.'
              }</span>
              <span class="is-smaller text">${
                el.id === 10026 ? 'Imp.' : 'Máx.'
              }</span>
            </div>
            <div class="maxmin-indicator-arrow" style="left:${left}%;"></div>
          </div>
        </div>
      </div>`;
    });
  },
  36: async (data, currentDate, format) => {
    sRender(MapaIntercambiosInternacionales, 'map-main-container', data);

    let noChar = document.getElementById('nochar36');
    if (data.indicators[0].values.length === 0) {
      noChar
        ? (document.getElementById('nochar36').style.display = 'flex')
        : null;
    } else {
      noChar
        ? (document.getElementById('nochar36').style.display = 'none')
        : null;
    }
  },
  37: async (data, currentDate, format) => {
    let { date } = getUrlParams();
    let opts = {
      type: 'multi',
      lineInterpolation: 'linear',
      elem: '#chart-bar-line',
      height: 450,
      date: date,
      yticks: 10,
      left: {
        label: 'GWh',
      },
    };
    let preData = {
      ...data,
      indicators: data.indicators.map((el) => {
        return {
          ...el,
          values: fillSimpleVals(24),
        };
      }),
    };
    preData.indicators.map((el, index) => {
      let finded = data.indicators.filter((or) => or.id === el.id)[0]?.values;

      finded.forEach((val) => {
        let hour = parseInt(moment(val.x).tz('Europe/Madrid').format('HH'));

        el.values[hour] = {
          ...el.values[hour],
          value: val.y,
          y: val.y,
          color: getColour(index),
          name: el.name,
        };
      });
    });
    preData.indicators.map((el, index) => {
      return {
        ...el,
        color: getColour(index),
        name: el.short_name,
        shortName: el.short_name,
        demanda: false,
        legendtype: 'circle',
      };
    });

    // Pendiente de agregar linea de saldo total
    preData.indicators.push({
      id: 10027,
      name: 'Saldo total por frontera',
      shortName: 'Saldo total por frontera',
      color: '#333333',
      legendtype: 'line',
      demanda: true,
      values: fillSimpleVals(24),
      print_values: fillPrintVals(24, date),
    });
    preData.indicators.map((elem, index) => {
      if (index !== 4) {
        preData.indicators[4].values = preData.indicators[4].values.map(
          (el, ind) => {
            return {
              ...el,
              y: el.y + elem.values[ind].y,
              value: el.y + elem.values[ind].y,
            };
          }
        );
        preData.indicators[4].print_values =
          preData.indicators[4].print_values.map((el, index) => {
            return {
              ...el,
              y: preData.indicators[4].values[index].y,
              value: preData.indicators[4].values[index].value,
            };
          });
      }
    });
    renderBalanceGraph(preData.indicators, 'hora', opts);
    let noChar = document.getElementById('nochar37');
    if (data.indicators[0].values.length === 0) {
      noChar
        ? (document.getElementById('nochar37').style.display = 'flex')
        : null;
    } else {
      noChar
        ? (document.getElementById('nochar37').style.display = 'none')
        : null;
      document.querySelector('.legends').innerHTML = `
      <div class="legend">
        <span
          class="badge"
          style="background: ${getColour(0)}"
        ></span>
        ${i18n.t('balances')} ${i18n.t('france')}
      </div>

      <div class="legend">
        <span
          class="badge"
          style="background: ${getColour(1)}"
        ></span>
        ${i18n.t('balances')} ${i18n.t('portugal')}
      </div>
      <div class="legend">
        <span
          class="badge"
          style="background: ${getColour(2)}"
        ></span>
        ${i18n.t('balances')} ${i18n.t('morocco')}
      </div>
      <div class="legend">
        <span
          class="badge"
          style="background: ${getColour(3)}"
        ></span>
        ${i18n.t('balances')} ${i18n.t('andorra')}
      </div>
      <div class="legend">
        <span class="line"></span>
        ${i18n.t('total-balance')}
      </div>`;
    }
  },
  38: async (data) => {
    // render(
    //   MapaIntercambiosInternacionales(data),
    //   document.getElementById('map-main-container')
    // );
    const updateDateTime = (id, date) => {
      document.getElementById(id).innerHTML = date;
    };
    let dateParse =
      moment(data.indicators[0].values[0]?.x)
        .tz('Europe/Madrid')
        .format('DD / MM / YYYY') || '-- / -- / --';

    if (document.getElementById('horario-frontera-map')) {
      updateDateTime('horario-frontera-map', dateParse);
    }

    sRender(MapaIntercambiosInternacionales, 'map-main-container', data);
    let arrIsEmpty = data.indicators.every((el) => el.values.length == 0);
    if (arrIsEmpty) {
      document.querySelector(
        '.esios-intercambios__no-data-graphic'
      ).style.display = 'flex';
      document.querySelector(
        '.esios-intercambios__no-data-graphic'
      ).style.position = 'absolute';
      document.querySelector(
        '.esios-intercambios__no-data-graphic'
      ).style.minHeight = '400px';
      document.querySelector(
        '.esios-intercambios__no-data-graphic'
      ).style.width = '100%';
      document.querySelector(
        '.esios-intercambios__no-data-graphic'
      ).style.height = '100%';
    } else {
      document.querySelector(
        '.esios-intercambios__no-data-graphic'
      ).style.display = 'none';
    }
  },
  39: async (data, currentDate, format) => {
    let { date } = getUrlParams();
    let opts = {
      type: 'multi',
      special: true,
      multibar: true,
      margin: { top: 20, right: -10, bottom: 40, left: 40 },
      lineInterpolation: 'linear',
      elem: '#chart-bar-line',
      height: 450,
      date: date,
      yticks: 10,
      left: {
        label: 'MWh',
      },
      bottom: {
        label: 'hours',
        prefix: '0',
      },
      magnitud: 'MWh',
    };
    let preData = {
      ...data,
      indicators: data.indicators.map((el) => {
        return {
          ...el,
          values: fillSimpleVals(24),
        };
      }),
    };
    preData.indicators.map((el, index) => {
      let finded = data.indicators.filter((or) => or.id === el.id)[0]?.values;

      finded.forEach((val) => {
        let hour = parseInt(moment(val.x).tz('Europe/Madrid').format('HH'));

        el.values[hour] = {
          ...el.values[hour],
          value: val.y,
          y: val.y,
          color: getColour(index),
          name: el.name,
        };
      });
    });
    preData.indicators.map((el, index) => {
      return {
        ...el,
        color: getColour(index),
        name: el.short_name,
        shortName: el.short_name,
        demanda: false,
        legendtype: 'circle',
      };
    });

    // Pendiente de agregar linea de saldo total
    preData.indicators.push({
      id: 10027,
      name: 'Saldo total por frontera',
      shortName: 'Saldo total por frontera',
      color: '#333333',
      legendtype: 'line',
      demanda: true,
      values: fillSimpleVals(24),
      print_values: fillPrintVals(24, date),
    });
    preData.indicators.map((elem, index) => {
      if (index !== 4) {
        preData.indicators[4].values = preData.indicators[4].values.map(
          (el, ind) => {
            return {
              ...el,
              y: el.y + elem.values[ind].y,
              value: el.y + elem.values[ind].y,
            };
          }
        );
        preData.indicators[4].print_values =
          preData.indicators[4].print_values.map((el, index) => {
            return {
              ...el,
              y: preData.indicators[4].values[index].y,
              value: preData.indicators[4].values[index].value,
            };
          });
      }
    });
    // renderBalanceGraph(preData.indicators, 'hora', opts);
    let noChar = document.getElementById('nochar39');
    if (data.indicators[0].values.length === 0) {
      noChar
        ? (document.getElementById('nochar39').style.display = 'flex')
        : null;
    } else {
      noChar
        ? (document.getElementById('nochar39').style.display = 'none')
        : null;
      document.querySelector('.legends').innerHTML = `
      <div
      class="legend esios-intercambios__label esios-intercambios__legend"
      style="display: flex; justify-content: space-between;"
    >
      <div>
        <span
          class="circle"
          style="background: ${getColour(0)}"
        ></span>
        ${i18n.t('balances')} ${i18n.t('france')}
      </div>

      <div class="valcontainer legendbar-10015">
      <div class="value"></div>
      <small class="magnitud"></small></div>
      </div>

      <div
        class="legend esios-intercambios__label esios-intercambios__legend"
        style="display: flex; justify-content: space-between;"
      >
        <div>
          <span
            class="circle"
            style="background: ${getColour(1)}"
          ></span>
          ${i18n.t('balances')} ${i18n.t('portugal')}
        </div>

        <div class="valcontainer legendbar-10014">
        <div class="value"></div>
        <small class="magnitud"></small></div>
      </div>

      <div
        class="legend esios-intercambios__label esios-intercambios__legend"
        style="display: flex; justify-content: space-between;"
      >
        <div>
          <span
            class="circle"
            style="background: ${getColour(2)}"
          ></span>
          ${i18n.t('balances')} ${i18n.t('morocco')}
        </div>

        <div class="valcontainer legendbar-10016">
        <div class="value"></div>
        <small class="magnitud"></small></div>
      </div>
      <div
        class="legend esios-intercambios__label esios-intercambios__legend"
        style="display: flex; justify-content: space-between;"
      >
        <div>
          <span
            class="circle"
            style="background: ${getColour(3)}"
          ></span>
          ${i18n.t('balances')} ${i18n.t('andorra')}
        </div>

        <div class="valcontainer legendbar-10017">
        <div class="value"></div>
        <small class="magnitud"></small></div>
      </div>

      

      <div
      class="legend esios-intercambios__label esios-intercambios__legend"
      style="display: flex; justify-content: space-between;"
    >
      <div>
        <span
          class="line"
          style="background: #000000"
        ></span>
        ${i18n.t('total-balance')}
      </div>

      <div class="valcontainer legendbar-10027">
        <div class="value"></div>
        <small class="magnitud"></small>
      </div>
      
    </div>

      
    `;
    }
    renderBalanceGraph(preData.indicators, 'hora', opts);
  },
  40: async ({ data, currentDate, format, slug }) => {
    if (slug == 'spot-europa-map') {
      let geos = [];

      data.indicators.forEach(({ geo_id }) => {
        geos = [...geos, { geo_id }];
      });
      let dataOfGeo = {
        indicator: {
          nivel: [{ geo_nivel: 0 }],
          geos,
          id: 600,
          magnitud: [{ name: 'Precio', id: 23 }],
          composited: false,
        },
      };

      let date = currentDate.split('T')[0];
      let [dd, mm, aa] = date.split('-');

      GeoChart(
        {
          datetime:
            moment(`${aa}-${mm}-${dd}`).format('YYYY-MM-DD') + ` 00:00:00`,
          indicator: dataOfGeo.indicator,
          start_date:
            moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY') + `T00:00:00`,
          end_date:
            moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY') + `T23:55:00`,
          tabSelected: { label: 'geographical' },
        },
        dataOfGeo,
        false
      );

      const dataLength = data.indicators[0].values.length;
      if (dataLength === 0) {
        if (window.location.pathname.includes('spot-europa')) {
          if (document.getElementById('geo-legend')) {
            document.getElementById('geo-legend').classList.add('hidden');
          }
          if (document.querySelector('#map40 #rect2')) {
            document.querySelector('#map40 #rect2').style.display = 'block';
          }
          if (document.getElementById('geo-map-container-600')) {
            document.getElementById('geo-map-container-600').style.display =
              'none';
          }
          if (document.getElementById('map40')) {
            document
              .getElementById('map40')
              .classList.add('esios-intercambios__no-data-graphic');
          }
        }
      } else {
        if (window.location.pathname.includes('spot-europa')) {
          if (document.getElementById('geo-legend')) {
            document.getElementById('geo-legend').classList.remove('hidden');
          }
          if (document.querySelector('#map40 #rect2')) {
            document.querySelector('#map40 #rect2').style.display = 'none';
          }
          if (document.getElementById('geo-map-container-600')) {
            document.getElementById('geo-map-container-600').style.display =
              'block';
          }

          if (document.getElementById('map40')) {
            document
              .getElementById('map40')
              .classList.remove('esios-intercambios__no-data-graphic');
          }
        }
      }
    } else {
      const indicators = data.indicators;

      const lastDate = [];
      const parsedData = indicators.map((el, index) => {
        el.values.map((date) => {
          lastDate.push(moment(date.x).tz('Europe/Madrid').format());
        });

        return {
          id: `${el.id}` + `${el.geo_id}`,
          type: 'line',
          values: el.values,
          dots: false,
          interpolation: el.step,
          color: getColour(index),
        };
      });

      var options = {
        target: d3.select('#widget40 .chari-chart').node(),
        margin: `0 ${isImbedPage ? '45' : '8'} 25 45`,
        trail: {
          enabled: true,
          parseStep: function (date) {
            return moment(date).startOf('hour').toDate();
          },
          initXValue: function () {
            let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
            if (currentDate !== now) {
              return lastDate.at(-1);
            } else {
              return moment().tz('Europe/Madrid').format();
            }
          },
        },
        xaxis: {
          scale: 'time',
          fit: true,
          ticks: [d3.time.hours, 1],

          bottom: {
            label: i18n.t('hours'),
            tickFormat: function (d, i) {
              var date = moment(d).tz('Europe/Madrid');
              var hour = date.hours();

              if (i % 2) {
                return '';
              } else {
                return hour < 10 ? '0' + hour : hour;
              }
            },
          },
        },
        yaxis: {
          fit: true,
          fullGrid: true,
          textMarginTop: -6,
          left: {
            label: '€/MWh',
            tickFormat: function (y) {
              return Utils.toNumber(y, 2, 'comma');
            },
          },
        },
      };
      var chart = new Charicharts.Chart(options, parsedData);

      let containerData;

      if (document.getElementById('char40Data')) {
        document.getElementById('char40Data').innerHTML = '';
        containerData = document.getElementById('char40Data');
      } else {
        containerData = document.createElement('div');
        containerData.setAttribute('id', 'char40Data');
        containerData.classList.add('esios-intercambios__container-data');
      }

      indicators.forEach((indicator, index) => {
        const row = document.createElement('div');
        row.classList.add('esios-intercambios__row');
        const divText = document.createElement('div');
        divText.classList.add('esios-intercambios__legend');
        const eye = document.createElement('span');
        eye.style.backgroundColor = getColour(index);
        eye.style.backgroundColor = getColour(index);
        eye.classList.add('icon-eye');
        eye.dataset.id = `${indicator.id}` + `${indicator.geo_id}`;
        const spanText = document.createElement('span');
        const text = document.createTextNode(indicator.alias);
        spanText.appendChild(text);
        spanText.classList.add('esios-intercambios__label');
        divText.appendChild(eye);
        divText.appendChild(spanText);
        const divValue = document.createElement('div');
        divValue.classList.add('esios-intercambios__value');
        const value = document.createElement('span');
        value.classList.add('esios-intercambios__legend-value');
        const unit = document.createElement('span');
        const textUnit = document.createTextNode('€/MWh');
        unit.appendChild(textUnit);
        unit.classList.add('esios-intercambios__legend-unit');
        divValue.appendChild(value);
        divValue.appendChild(unit);
        row.appendChild(divText);
        row.appendChild(divValue);
        containerData.appendChild(row);
      });

      document.getElementById('char40Container').appendChild(containerData);

      chart.on('Trail/moved', function (parsedData, d) {
        document
          .querySelectorAll('.esios-intercambios__legend-value')
          .forEach((indicator, index) => {
            indicator.innerHTML = Utils.toNumber(
              parsedData[index].y,
              2,
              'comma'
            );
          });

        const graphTime = document.querySelector('#widget40 .mod-date');
        const graphEmbed = document.querySelector('#widget40 #embed-hour');
        if (graphEmbed) {
          graphEmbed.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
        }
        if (graphTime) {
          graphTime.innerHTML = moment(d)
            .tz('Europe/Madrid')
            .format(format || 'HH:mm');
        }
      });

      Utils.showAndHideGraphLinesEyes('#char40', '#char40Data .icon-eye');

      // const dataLength = indicators[0].values.length;
      if (indicators[0].values.length === 0 || indicators.length === 0) {
        if (window.location.pathname.includes('spot-europa')) {
          if (document.querySelector('#char40 #rect1')) {
            document.querySelector('#char40 #rect1').style.display = 'block';
          }
          if (document.querySelector('#map40 #rect2')) {
            document.querySelector('#map40 #rect2').style.display = 'block';
          }
          if (document.getElementById('geo-map-container-600')) {
            document.getElementById('geo-map-container-600').style.display =
              'none';
          }
          if (document.getElementById('char40Container')) {
            document
              .getElementById('char40Container')
              .classList.add('esios-intercambios__no-data-graphic');
          }
          if (document.getElementById('map40')) {
            document
              .getElementById('map40')
              .classList.add('esios-intercambios__no-data-graphic');
          }
        }
      } else {
        if (window.location.pathname.includes('spot-europa')) {
          document.querySelector('#char40 #rect1').style.display = 'none';
          if (document.querySelector('#map40 #rect2')) {
            document.querySelector('#map40 #rect2').style.display = 'none';
          }
          if (document.getElementById('geo-map-container-600')) {
            document.getElementById('geo-map-container-600').style.display =
              'block';
          }

          if (document.getElementById('map40')) {
            document
              .getElementById('map40')
              .classList.remove('esios-intercambios__no-data-graphic');
          }
          if (document.getElementById('char40Container')) {
            document
              .getElementById('char40Container')
              .classList.remove('esios-intercambios__no-data-graphic');
          }
        }
      }
    }

    var aspect = 300 / 200,
      chart = d3.select('#widget40');
    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });

    return chart;
  },
  46: async (data, currentDate, format) => {
    const indicators = data.indicators;
    const lastDate = [];
    const parsedData = indicators.map((el, index) => {
      el.values.map((date) => {
        lastDate.push(moment(date.x).tz('Europe/Madrid').format());
      });
      return {
        id: el.id,
        type: 'bar',
        values: el.values,
        dots: false,
        // interpolation: el.step,
        color: getColour(index),
      };
    });

    const testData = [
      {
        type: 'bar',
        // stacking: true,
        grouped: true,
        data: parsedData,
      },
    ];
    var options = {
      target: d3.select('#widget46 #saldos__programados__w2').node(),
      margin: `40 ${isImbedPage ? '45' : '8'} 25 45`,
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('hour').toDate();
        },
        // initXValue: function () {
        //   let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
        //   if (currentDate !== now) {
        //     return lastDate.at(-1);
        //   } else {
        //     return moment().tz('Europe/Madrid').format();
        //   }
        // },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 1],

        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (d, i) {
            return moment(d).tz('Europe/Madrid').format('HH');
          },
        },
      },
      yaxis: {
        fit: true,
        fullGrid: true,
        textMarginTop: -6,
        // ticks: [4],
        left: {
          label: 'MW',
          tickFormat: function (y) {
            return y;
          },
        },
      },
    };
    var chartElem = new Charicharts.Chart(options, testData);

    const dataLength = indicators[0].values.length;
    if (dataLength === 0) {
    } else {
    }

    return chartElem;
  },
  41: async (data, date) => {
    document.getElementById('spinner41').style.display = 'flex';
    document.getElementById('spinner41table').style.display = 'flex';

    let tableID = 0;
    const selectMobileSelector = {
      id: 'mobile__selector_id',
      currentLabel: '0-1 H',
      currentValue: '0',
      label: '',
      options: getArrayOfHoursForBalance('hour'),
      columns: 1,
      onlySelect: true,
      changeTime: (value) => changeTimeFromSelector(value),
    };
    const changeTimeFromSelector = (value) => {
      const { id, newValue } = value;

      if (selectMobileSelector.id === id) {
        selectMobileSelector.options.map((el) => {
          if (el.value === newValue) {
            selectMobileSelector.currentLabel = el.label;
            selectMobileSelector.currentValue = el.value;
            let tableWidth =
              window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth;
            if (tableWidth <= 540) {
              renderColumnSelected(el.value, 'hour');
            }

            componentSelects.render(selectMobileSelector, '#mobile__selector');
          }
        });

        return;
      }
    };

    let dataFrancia = [],
      dataPortugal = [],
      dataMarruecos = [],
      dataAndorra = [];
    let opts = saldoFronterasContent.optsForMultipleGraph(date);
    let { dFrancia, dPortugal, dMarruecos, dAndorra } = processPreData(data);
    dataFrancia = dFrancia;
    dataPortugal = dPortugal;
    dataMarruecos = dMarruecos;
    dataAndorra = dAndorra;

    let finalArray = proccessData(
      dataFrancia,
      dataPortugal,
      dataMarruecos,
      dataAndorra
    );
    // hide data
    let isArrFill = data.indicators.every((el) => el.values.length == 0);

    document.getElementById('spinner41').style.display = 'none';
    document.getElementById('spinner41table').style.display = 'none';
    showTable(tableID);
    componentSelects.render(selectMobileSelector, '#mobile__selector');

    let tableWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (tableWidth <= 540) {
      renderColumnSelected(0, 'hour');
    }

    if (isArrFill) {
      document.querySelector('#char41.esios-chart').style.display = 'flex';
      document.querySelectorAll('.tabcontentclass').forEach((el) => {
        el?.classList.add('hidden');
      });
    } else {
      // document.getElementById('char41').style.display = 'none';
      document.querySelector('#char41.esios-chart').style.display = 'none';
      opts.forEach((opt, index) => {
        document.querySelector(`${opt.elem}-legends`).innerHTML = `
        ${opt.legends
          .map(
            (el) =>
              `<div class="legend">
            <span
              class="${el.type}"
              style="background: ${el.color}">
            </span>
            ${i18n.t(`${el.name}`)}
          </div>`
          )
          .join('')}
        `;
        if (index == 0) {
          let orderArr = [
            1106,
            10104,
            'intraday',
            536,
            565,
            488,
            492,
            // , 10026
          ];

          finalArray[index] = Utils.orderObjectsArrayByKey(
            finalArray[index],
            orderArr,
            'id'
          );
        } else if (index == 1) {
          let orderArr = [
            10113,
            'intraday',
            540,
            567,
            489,
            493,
            // 10026,
          ];

          finalArray[index] = Utils.orderObjectsArrayByKey(
            finalArray[index],
            orderArr,
            'id'
          );
        } else if (index == 2) {
          let orderArr = [
            10122,
            'intraday',
            490,
            494,
            // 10026
          ];

          finalArray[index] = Utils.orderObjectsArrayByKey(
            finalArray[index],
            orderArr,
            'id'
          );
        } else if (index == 3) {
          let orderArr = [
            10131,
            'intraday',
            491,
            495,
            // 10026
          ];

          finalArray[index] = Utils.orderObjectsArrayByKey(
            finalArray[index],
            orderArr,
            'id'
          );
        }
        renderBalanceGraph(finalArray[index], 'hora', opt);
        let renderTabFunc;
        finalArray[index] = finalArray[index].map((el) => {
          if (el?.area1) {
            return {
              ...el,
              values: el.values.map((val) => {
                return {
                  ...val,
                  y: val.y * -1,
                  value: val.value * -1,
                };
              }),
            };
          } else {
            return el;
          }
        });

        finalArray[index] = finalArray[index].map((el, index) => {
          return {
            ...el,
            opts: opt.legends[index],
          };
        });
        renderTabFunc = updateTable(finalArray[index]);
        componentTab.render(renderTabFunc, `#tabwithcontent${index}`);
      });
      document.querySelectorAll('.tabcontentclass').forEach((el) => {
        el?.classList.remove('hidden');
      });
    }

    document.getElementById('spinner41').style.display = 'none';
    document.getElementById('spinner41table').style.display = 'none';
    showTable(tableID);
  },
  42: async (data, date) => {
    let isMobile = Utils.isMobileDevice();
    // Convertir el string en formato 'dd-mm-yyyy' a un objeto Date

    let parts = date.split('-');
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1;
    let year = parseInt(parts[2], 10);

    let dateObj = new Date(year, month, day);

    // Obtener el mes y año actuales
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth(); // Mes actual (0 es enero)
    let currentYear = currentDate.getFullYear(); // Año actual

    // Verificar si el mes y año no coinciden con los actuales
    if (
      dateObj.getMonth() !== currentMonth ||
      dateObj.getFullYear() !== currentYear
    ) {
      // Sumar un mes
      dateObj.setMonth(dateObj.getMonth() + 1);
    }

    // Formatear la nueva fecha en 'dd-mm-yyyy'
    let newDay = dateObj.getDate().toString().padStart(2, '0');
    let newMonth = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 para ajustar el índice del mes
    let newYear = dateObj.getFullYear();

    date = `${newDay}-${newMonth}-${newYear}`;
    const indicators = data.indicators;
    const graphColors = ['#00A1DA', '#DF4A32'];

    const parsedData = indicators.map((el, index) => {
      return {
        id: el.id,
        alias: el.alias,
        geo_id: el.geo_id,
        short_name: el.short_name,
        values: el.values,
        magnitud: el.magnitud,
        dots: false,
        color: graphColors[index],
      };
    });

    let opts = {
      type: 'multi',
      special: true,
      fit: true,
      multibar: true,
      margin: { top: 20, right: 0, bottom: 40, left: 50 },
      lineInterpolation: 'linear',
      elem: '#mypGraphic5',
      height: isMobile ? 380 : 500,
      date: date,
      yticks: 10,
      left: {
        label: '%',
      },
      bottom: {
        label: 'months',
        prefix: '0',
      },
      magnitud: '%',
    };
    let valh = 14;

    let preData = {
      ...data,
      indicators: data.indicators.map((el) => {
        return {
          ...el,
          values: fillSimpleVals(valh),
        };
      }),
    };

    let dataOrdered = Utils.orderObjectsArrayByKey(
      preData.indicators,
      [10460, 10461, 10462],
      'id'
    );

    const dateObject = moment(date, 'DD-MM-YYYY').toDate(); //Última
    const iniDateObject = moment(dateObject).subtract(14, 'months').toDate(); // Primera

    dataOrdered.map((el, index) => {
      let finded = data.indicators.filter((or) => or.id === el.id)[0]?.values;
      let size1;
      if (el.print_values.length === 1) {
        size1 = true;
      }

      let currentIndexValues = 0;
      if (finded.length > 0) {
        let primerFindedDate = moment(finded[0].x).toDate();
        if (iniDateObject < primerFindedDate) {
          let tempIniDateObject = iniDateObject;
          while (tempIniDateObject < primerFindedDate) {
            tempIniDateObject = moment(tempIniDateObject)
              .add(1, 'months')
              .toDate();
            currentIndexValues++;
          }
        }
      }

      let tempIniDateObject = moment(dateObject)
        .subtract(14, 'months')
        .startOf('month')
        .toDate();

      finded.forEach((val) => {
        // Siempre el 1 de cada mes
        let findedDate = moment(val.x).startOf('month').toDate();

        while (findedDate < tempIniDateObject) {
          return;
        }

        el.values[currentIndexValues] = {
          ...el.values[currentIndexValues],
          value: val.y,
          y: val.y,
          color: getColour(index),
          name: el.name != null ? el.name : el.alias,
        };

        currentIndexValues++;
      });
    });

    // Pendiente de agregar linea de saldo total
    preData.indicators.push({
      id: 10462,
      name: '% Energía renovable no integrable por RRTT Total',
      shortName: '% Energía RRTT Total',
      color: '#9C9C9C',
      legendtype: 'line',
      demanda: true,
      values: fillSimpleVals(valh),
      print_values: fillPrintVals(valh, date),
    });
    preData.indicators.map((elem, index) => {
      if (index === 2) {
        preData.indicators[3].values = preData.indicators[2]?.values.map(
          (el, ind) => {
            return {
              ...el,
              y: el.y + preData.indicators[3]?.values[ind].y,
              value: el.y + preData.indicators[3]?.values[ind].y,
            };
          }
        );
        preData.indicators[3].print_values =
          preData.indicators[2]?.print_values.map((el, index) => {
            if (preData.indicators[3]?.values[index] != null) {
              return {
                ...el,
                y: preData.indicators[3]?.values[index].y,
                value: preData.indicators[3]?.values[index].value,
              };
            }
          });
        // Eliminar el elemento en la posición 2 de preData.indicators
        preData.indicators.splice(2, 1);
      }
    });

    const dataLength = preData.indicators[0].print_values.length;
    const rectNoData5 = document.getElementById('mypNoDataRect5');
    const widget5 = document.getElementById('mypGraphic5');
    if (dataLength != 0) {
      widget5.classList.remove('no-data-graphic');
      rectNoData5.classList.add('hidden');
    } else {
      widget5.classList.add('no-data-graphic');
      rectNoData5.classList.remove('hidden');
      document.querySelector('#mypGraphic5 > svg').classList.add('hidden');
    }
    renderBalanceErniGraph(preData.indicators, 'month', opts);
  },
  544: async (data, date) => {
    await generateChartData(544, data, date);
  },
  541: async (data, date) => {
    await generateChartData(541, data, date);
  },
  542: async (data, date) => {
    await generateChartData(542, data, date);
  },
  543: async (data, date) => {
    await generateChartData(543, data, date);
  },
  1775: async (data, date) => {
    await generateChartData(1775, data, date);
  },
  10358: async (data, date) => {
    await generateChartData(10358, data, date);
  },
  1776: async (data, date) => {
    await generateChartData(1776, data, date);
  },
  10359: async (data, date) => {
    await generateChartData(10359, data, date);
  },
  1774: async (data, date) => {
    await generateChartData(1774, data, date);
  },
  1742: async (data, date) => {
    await generateChartData(1742, data, date);
  },
  460: async (data, date) => {
    await generateChartData(460, data, date);
  },
  461: async (data, date) => {
    await generateChartData(461, data, date);
  },
  603: async (data, date) => {
    await generateChartData(603, data, date);
  },
  620: async (data, date) => {
    await generateChartData(620, data, date);
  },
  10034: async (data, date) => {
    await generateChartData(10034, data, date);
  },
  621: async (data, date) => {
    await generateChartData(621, data, date);
  },
  604: async (data, date) => {
    await generateChartData(604, data, date);
  },
  619: async (data, date) => {
    await generateChartData(619, data, date);
  },
  622: async (data, date) => {
    await generateChartData(622, data, date);
  },
  10249: async (data, date) => {
    await generateChartData(10249, data, date);
  },
};

const generateChartData = async (id, data, date) => {
  const indicators = [];
  indicators.push(data);
  const graphColors = ['#00A1DA', '#DF4A32'];
  const lastDate = [];
  let time =
    indicators[0].indicators[0] != null
      ? indicators[0].indicators[0].time
      : null;
  const timeID = indicators[0].time != null ? indicators[0].time : time;
  let step = Utils.getTimeValue(timeID) || 10;
  const parsedData = indicators.map((el, index) => {
    let values =
      el.indicators.values != null
        ? el.indicators.values
        : el.indicators[0].values;
    if (values == null || values.length == 0) {
      values = el.indicators[0] != null ? el.indicators[0].values : values;
    }
    if (index == 0) {
      values.map((date) => {
        if (date.y != null) {
          lastDate.push(moment(date.x).tz('Europe/Madrid').format());
        }
      });
    }
    return {
      id: el.indicators.id,
      type: 'line',
      name: el.indicators.short_name,
      values: values,
      dots: false,
      interpolation: 'linear',
      color: graphColors[index],
    };
  });

  const targetSelector = {
    544: '#mypPrevisionSemanalDemandaWidgetView .chari-chart',
    541: '#mypPrevisionEolicaWidgetView .chari-chart',
    542: '#mypPrevisionFotovoltaicaWidgetView .chari-chart',
    543: '#myPrevisionSolarTerWidgetView .chari-chart',
    1775: '#myPrevisionDiariaD1Wiew .chari-chart',
    10358: '#myPrevisionDiariaD1EolicaWiew .chari-chart',
    1776: '#myPrevisionIntraDiariaH3DemandaWiew .chari-chart',
    10359: '#myPrevisionIntraDiariaH3DemandaFotovolWiew .chari-chart',
    1774: '#myPrevisionDemandaAnualWiew .chari-chart',
    1742: '#myDemandaPrevistaSNPWiew .chari-chart',
    460: '#myDemandaDiariaElectricaPeninsularWiew .chari-chart',
    461: '#myDemandaMensualElectricaPeninsularWiew .chari-chart',
    603: '#myDemandaSemanalElectricaPeninsularWiew .chari-chart',
    620: '#myPrevisionMaximaAnualPeninsularWiew .chari-chart',
    10034: '#myGeneracionPrevistaSolarWiew .chari-chart',
    621: '#myPrevisionMaximaAnualPeninsularWiew .chari-chart',
    604: '#myPrevisionMaximaMensualPeninsularWiew .chari-chart',
    619: '#myPrevisionMinimaMensualPeninsularWiew .chari-chart',
    622: '#myMargenGeneracionWiew .chari-chart',
    10249: '#myPrevisionDiariaD1Wiew .chari-chart',
  };

  const legendsSelector = {
    544: '#mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__list .row',
    541: '#mypPrevisionEolicaWidgetView .esios-listEnergyItems__list .row',
    542: '#mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__list .row',
    543: '#myPrevisionSolarTerWidgetView .esios-listEnergyItems__list .row',
    1775: '#myPrevisionDiariaD1Wiew .esios-listEnergyItems__list .row',
    10358: '#myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__list .row',
    1776: '#myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__list .row',
    10359:
      '#myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__list .row',
    1774: '#myPrevisionDemandaAnualWiew .esios-listEnergyItems__list .row',
    1742: '#myDemandaPrevistaSNPWiew .esios-listEnergyItems__list .row',
    460: '#myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__list .row',
    461: '#myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__list .row',
    603: '#myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__list .row',
    620: '#myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list .row',
    10034: '#myGeneracionPrevistaSolarWiew .esios-listEnergyItems__list .row',
    621: '#myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list .row',
    604: '#myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__list .row',
    619: '#myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__list .row',
    622: '#myMargenGeneracionWiew .esios-listEnergyItems__list .row',
    10249: '#myPrevisionDiariaD1Wiew .esios-listEnergyItems__list .row',
  };

  const legendLabels = {
    544: 'weekly_demand_forecast',
    541: 'wind_forecast',
    542: 'photovoltaic-solar-forecast',
    543: 'solar_thermal_forecast',
    1775: 'daily-forecast-d1-demand',
    10358: 'daily-forecast-d1-wind-plus-photovoltaic',
    10359: 'intraday-forecast-h3-more-photovoltaic-demand',
    1776: 'intraday-forecast-h3-demand',
    1774: 'annual-demand-forecast',
    1742: 'snp-forecast-demand',
    460: 'daily-forecast-peninsular-electricity-demand',
    461: 'monthly-forecast-peninsular-electricity-demand',
    603: 'weekly-forecast-peninsular-electricity-demand',
    620: 'maximum-annual-forecast-peninsular-electricity-demand',
    10034: 'solar-planned-generation',
    621: 'minimum-annual-forecast-peninsular-electricity-demand',
    604: 'maximum-monthly-forecast-peninsular-electricity-demand',
    10249: 'residual-demand-forecast',
    619: 'minimum-monthly-forecast-peninsular-electricity-demand',
    622: 'available-generation-margin-maximum-forecast',
  };

  const spinnerId = {
    544: 'spinner26',
    541: 'spinner27',
    542: 'spinner28',
    543: 'spinner29',
    1775: 'spinner30',
    10358: 'spinner31',
    10359: 'spinner33',
    1776: 'spinner32',
    1774: 'spinner12',
    1742: 'spinner14',
    460: 'spinner15',
    461: 'spinner16',
    603: 'spinner17',
    620: 'spinner18',
    10034: 'spinner19',
    621: 'spinner18',
    604: 'spinner22',
    619: 'spinner23',
    622: 'spinner24',
    10249: 'spinner30',
  };

  const widgetId = {
    544: 'mypGraphic26',
    541: 'mypGraphic27',
    542: 'mypGraphic28',
    543: 'mypGraphic29',
    1775: 'mypGraphic30',
    10358: 'mypGraphic31',
    1776: 'mypGraphic32',
    10359: 'mypGraphic33',
    1774: 'mypGraphic12',
    1742: 'mypGraphic14',
    460: 'mypGraphic15',
    461: 'mypGraphic16',
    603: 'mypGraphic17',
    620: 'mypGraphic18',
    10034: 'mypGraphic19',
    621: 'mypGraphic18',
    604: 'mypGraphic22',
    619: 'mypGraphic23',
    622: 'mypGraphic24',
    10249: 'mypGraphic30',
  };

  const rectNoDataId = {
    544: 'mypNoDataRect26',
    541: 'mypNoDataRect27',
    542: 'mypNoDataRect28',
    543: 'mypNoDataRect29',
    1775: 'mypNoDataRect30',
    10358: 'mypNoDataRect31',
    1776: 'mypNoDataRect32',
    10359: 'mypNoDataRect33',
    1774: 'mypNoDataRect12',
    1742: 'mypNoDataRect14',
    460: 'mypNoDataRect15',
    461: 'mypNoDataRect16',
    603: 'mypNoDataRect17',
    620: 'mypNoDataRect18',
    10034: 'mypNoDataRect19',
    621: 'mypNoDataRect18',
    604: 'mypNoDataRect22',
    619: 'mypNoDataRect23',
    622: 'mypNoDataRect24',
    10249: 'mypNoDataRect30',
  };

  const charId = {
    544: '#char544',
    541: '#char541',
    542: '#char542',
    543: '#char543',
    1775: '#char1775',
    10358: '#char10358',
    1776: '#char1776',
    10359: '#char10359',
    1774: '#char1774',
    1742: '#char1742',
    460: '#char460',
    461: '#char461',
    603: '#char603',
    620: '#char620',
    10034: '#char10034',
    621: '#char621',
    604: '#char604',
    619: '#char619',
    622: '#char622',
    10249: '#char10249',
  };
  let options;

  if (id === 1774 || id === 622) {
    // Opciones para visualización anual
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('year').toDate();
        },
        initXValue: function () {
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.years, 1],
        bottom: {
          label: i18n.t('years'),
          tickFormat: function (date) {
            return date.getFullYear().toString();
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else if (id === 461) {
    // Opciones para visualización mensual
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('month').toDate();
        },
        initXValue: function () {
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.months, 1],
        bottom: {
          label: i18n.t('months'),
          tickFormat: function (date) {
            const month = date.toLocaleString('default', { month: 'short' });
            return `${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else if (id === 603 || id === 619) {
    // Opciones para visualización semanal
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('day').toDate();
        },
        initXValue: function () {
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.days, 1],
        bottom: {
          label: i18n.t('days'),
          tickFormat: function (date) {
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else if (id === 541) {
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3],
        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (date) {
            return moment(date).format('HH:mm');
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else if (id === 621 || id === 620 || id === 604) {
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('week').toDate();
        },
        initXValue: function () {
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.months, 1],
        bottom: {
          label: i18n.t('months'),
          tickFormat: function (date) {
            const month = date.toLocaleString('default', { month: 'short' });
            return `${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else if (id === 619 || id === 604) {
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          date = moment(date).startOf('week').toDate(); // Asegurarse de que se agrupe por día
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.day, 4], // Cambiar a cada 4 días

        bottom: {
          label: i18next.t('days'),
          tickFormat: function (date) {
            date.setDate(date.getDate() + 1);
            const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
            const day = date.getDate(); // Obtener el día
            return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else if (
    id === 543 ||
    id === 542 ||
    id === 460 ||
    id === 1775 ||
    id === 10358 ||
    id === 1776 ||
    id === 10359 ||
    id === 10034 ||
    id === 10249
  ) {
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3],
        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (date) {
            return moment(date).format('HH:mm');
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  } else {
    options = {
      target:
        d3.select(targetSelector[id]).node() ||
        d3.select('#widget' + id).node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 5, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3],
        bottom: {
          label: i18n.t('hours'),
          tickFormat: function (date) {
            return moment(date).format('HH:mm');
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            return Utils.toNumber(y, 0, 'comma');
          },
        },
      },
    };
  }

  var chart = new Charicharts.Chart(options, parsedData);

  let graphTime = document.querySelector(`${charId[id]} .mod-date-embed`);
  let facturacionLegends = document.querySelector(
    legendsSelector[id] || `#widget${id}Data`
  );
  const dataLength = indicators[0].indicators.values.length;
  if (indicators[0].indicators.values.length === 0) {
    facturacionLegends.innerHTML = '';
    parsedData.map((el, index) => {
      facturacionLegends.innerHTML += `
          <div class="col-xs-12">
            <li class="esios-listEnergyItems__item-with-value" id="legend-686">
              <div class="esios-listEnergyItems__label">
                <span class="esios-listEnergyItems__decorator" style="background-color: ${
                  graphColors[index]
                };"></span>
                <span class="label">${i18n.t(legendLabels[id])}</span>
                <div class="value is-number">
                  <span class="caption">-</span><span class="unit">€/MWh</span>
                </div>
              </div>
            </li>
          </div>
        `;
    });
  } else {
    let grapTime = document.querySelector(`${charId[id]} .mod-date`);
    let embedhour = document.querySelector(`${charId[id]} #embed-hour`);
    let embedDatetime = document.querySelector(`${charId[id]} #datepicker`);
    chart.on('Trail/moved', function (parsedData, d) {
      if (grapTime) {
        grapTime.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
      }

      if (embedhour) {
        embedhour.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
      }
      if (embedDatetime) {
        embedDatetime.value = moment(d)
          .tz('Europe/Madrid')
          .format('DD / MM / YYYY');
      }
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
            <div class="col-xs-12">
              <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                <div class="esios-listEnergyItems__label">
                  <span class="esios-listEnergyItems__decorator" style="background-color: ${
                    graphColors[index]
                  };"></span>
                  <span class="label">${i18n.t(legendLabels[id])}</span>
                  <div class="value is-number">
                    <span class="caption">${
                      parsedData[0].y === null
                        ? '-'
                        : Utils.toNumber(el.y, 2, 'comma')
                    }</span><span class="unit"> €/MWh</span>
                  </div>
                </div>
              </li>
            </div>
          `;
      });
    });
  }
  //const dataLength = indicators[0].indicators.values.length;
  const rectNoData = document.getElementById(rectNoDataId[id]);
  const widget = document.getElementById(widgetId[id]);
  if (dataLength === 0) {
    widget.classList.add('no-data-graphic');
    rectNoData.classList.remove('hidden');
    document.querySelector(`#${widgetId[id]} > svg`).classList.add('hidden');
    document.querySelector(legendsSelector[id]).classList.add('no-data');
  } else {
    document.querySelector(legendsSelector[id]).classList.remove('no-data');
    widget.classList.remove('no-data-graphic');
    rectNoData.classList.add('hidden');
  }
  document.getElementById(spinnerId[id]).style.display = 'none';
};

const renderList = (pie, selector, data, colors) => {
  document.querySelector(selector + ' ul') &&
    document.querySelector(selector + ' ul').remove();

  let ulLegend = d3.select(selector).append('ul').attr('class', 'legend');

  let keys = ulLegend
    .selectAll('.key')
    .data(data)
    .enter()
    .append('li')
    .attr('class', 'legend-item');

  keys
    .html(
      (item, index) =>
        `
        <div class="item">
          <span
            class="legend-circle"
            style="background-color: ${colors[index]};"
          ></span>
          ${item.alias}
        </div>
    `
    )
    .on('mouseover', function (d, i) {
      if (pie.innerArrow) {
        pie.innerArrow.moveTo(d.id);
      }
    });
};

const withComasOrDot = (val, separator) => {
  let newValue =
    val !== null
      ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
      : '-';
  return newValue.trim();
};

const magnitudToSymbol = (valId) => {
  if (valId === 152) return '%';
  if (valId === 13) return 'MWh';
  if (valId === 20) return 'MW';
  if (valId === 23) return '€/MWh';

  return "This value doesn't exist!!!";
};

const isWinter = () => {
  var today = moment();
  var march = moment({ day: 21, month: 2 }).valueOf();
  var october = moment({ day: 21, month: 9, year: today.year() - 1 }).valueOf();
  var isWinter = today.valueOf() < march && today.valueOf() >= october;
};

export { charts };
