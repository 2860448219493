'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-consumo.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';

let templategenerConsumo = (documents, buttons, handlers, listMarket, lang) => {
  document.addEventListener('click', (evt) => {
    const list = document.querySelectorAll('.btn-legend-popup');
    let targetElement = evt.target; // clicked element

    do {
      if (targetElement == list[0] || targetElement == list[1]) {
        return;
      }
      // Go up the DOM.
      targetElement = targetElement.parentNode;
    } while (targetElement);

    // Do something useful here.
    let legend = document.querySelectorAll('.box-legend-popup');
    legend.forEach((item) => item.classList.add('invisible'));
  });

  const handleClick = (e) => {
    const classList = [...e.target.nextElementSibling.classList]; // se dirige con nextElementSibling al vecino mas cercano hacia abajo
    if (classList.find((item) => item === 'invisible')) {
      let legend = document.querySelectorAll('.box-legend-popup');
      legend.forEach((item) => item.classList.add('invisible'));
      e.target.nextElementSibling.classList.remove('invisible');
    } else {
      e.target.nextElementSibling.classList.add('visible');
    }
  };

  return componentLayout.render(
    html`
      <div class="esios-consumo">
        <div class="wrap">
          <!--                 seccion POTENCIA  -->
          <div class="grid-md-5 grid-sm-6 div-potencia">
            <div id="char1" class="widget widget-gyc-potencia">
              <div class="grid-xs-24 grid-sm-24">
                <h1>${i18n.t('power')}</h1>
                <h2>${i18n.t('gen_conventional')}</h2>

                <div class="widget-toolbar">
                  <div class="esios-toolbar">
                    <div class="esios-toolbar1"></div>
                    <div class=" pickers-wrapper">
                      <div id="gen_conventional-time"></div>
                    </div>
                  </div>
                </div>
                <div id="widget1">
                  <div
                    id="spinner1"
                    style="margin-top:50%;"
                    class="spinner-container"
                  >
                    <div class="spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--                 seccion GENERACION Y CONSUMO  -->
          <div class="grid-md-14 is-mobile-first-item div-consumo">
            <div class="widget" id="char2">
              <div>
                <div class="grid-xs-24 grid-sm-24">
                  <h1>${i18n.t('generation_and_consumption')}</h1>
                  <div class="widget-toolbar">
                    <div class="esios-toolbar col-12">
                      <div class="esios-toolbar2"></div>
                      ${templateToolbarTime('generation-consumption')}
                    </div>
                  </div>
                </div>
              </div>
              <div id="widget2" class="generacion-consum widget-chart">
                <div
                  id="rect2"
                  class="rect-no-data"
                  style="display:none; margin-top:26%"
                >
                  ${i18n.t('no-data-available')}
                </div>
                <div id="spinner2" class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </div>

              <div id="widget2Data" class="grid-xs-24">
                <div class="widget-legend visual-legend in-line">
                  <ul></ul>
                </div>

                <div class="widget-summary gyc-summary">
                  <div class="gyc-summary highlighted-summary">
                    <div class="in-line"></div>

                    <div class="widget-summary-item ">
                      <div class="value is-big variacion"></div>
                      <div class="label">${i18n.t('variation')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--                 seccion GENERACION LIBRE   -->
          <div class="grid-md-5 grid-sm-6 div-generacion">
            <aside>
              <div class="widget widget-3" id="gycCo2WidgetView">
                <div class="aside">
                  <div class="grid-xs-24 grid-sm-24">
                    <h1>${html([i18n.t('co2_free_generation')])}</h1>
                  </div>

                  <div class="widget-toolbar" style="width: 100%;">
                    <div class="esios-toolbar" style="width: 100%;">
                      <div class="esios-toolbar3"></div>
                      <div id="free-gen-timeSelector"></div>
                    </div>
                  </div>

                  <div class="grid-xs-24 grid-sm-24">
                    <div class="widget-custom">
                      <div id="spinner3" class="spinner-container">
                        <div class="spinner"></div>
                      </div>
                      <div class="widget-summary"></div>

                      <hr />

                      <div class="widget-summary aside-summary"></div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>

      <!--                 seccion BALANCE ELECTRICO   -->
    `,
    {
      image: backImage,
      showBanner: true,
    },

    html`
      <!--                 seccion BALANCE ELECTRICO   -->

      <div id="layout-extended" class="layout-extended-balance">
        <div class="esios-related-content">
          <div class="wrap">
            <div class="gyc-balance grid-sm-24 grid-xs-24">
              <div class="widget widget-4" id="gycBalanceWidgetView">
                <div class="row">
                  <div class="grid-xs-24 grid-sm-24">
                    <h1>${i18n.t('electric_balance')}</h1>
                  </div>

                  <div class="grid-xs-24 grid-sm-24">
                    <div class="esios-toolbar col-24">
                      <div class="esios-toolbar4"></div>

                      <div id="electric-balance-time"></div>
                    </div>
                  </div>

                  <div class="grid-xs-24 grid-sm-24">
                    <div class="widget-custom">
                      <div id="spinner4" class="spinner-container">
                        <div class="spinner"></div>
                      </div>
                      <div class="mod-tabs">
                        <div id="programs" class="widget-gyc-balance">
                          <div class="full-width row is-stretch">
                            <div class="grid-xs-24 grid-sm-8 is-stretch">
                              <div class="mod-tab-subpanel" id="generacion">
                                <div class="title">
                                  <p class="sum">${i18n.t('generation')}</p>
                                </div>
                                <div class="summary-resume"></div>

                                <div id="genConWidget2" class="content">
                                  <div
                                    id="graphic2"
                                    class="widget-chart chari-chart dibujo"
                                  ></div>
                                  <div
                                    id="rectNoData2"
                                    class="rect-no-data hidden"
                                    style="width:65%"
                                  >
                                    ${i18n.t('no-data-available')}
                                  </div>
                                </div>
                                <div class="mod-legend-popup">
                                  <div
                                    class="btn-legend-popup"
                                    @click=${handleClick}
                                  >
                                    ${i18n.t('legend')}
                                  </div>

                                  <div class="box-legend-popup left invisible ">
                                    <div
                                      class="eb-left widget-legend basic-legend"
                                    ></div>
                                  </div>
                                </div>
                                <div class="summary-resume last"></div>
                              </div>
                              <p class="sum-mob"></p>
                            </div>

                            <div class="grid-xs-24 grid-sm-8 is-stretch">
                              <div class="mod-tab-subpanel" id="almacenamiento">
                                <div class="title">
                                  <p class="sum">${i18n.t('storage')}</p>
                                </div>
                                <div class="summary-resume"></div>
                                <div class="content content-middle">
                                  <div class=" basic-summary"></div>
                                </div>
                                <div class="summary-resume last"></div>
                              </div>
                              <p class="equal-mob"></p>
                            </div>

                            <div class="grid-xs-24 grid-sm-8 is-stretch">
                              <div
                                class="mod-tab-subpanel"
                                id="interconexiones"
                              >
                                <div class="title">
                                  <p class="equal">
                                    ${i18n.t('interconections')}
                                  </p>
                                </div>
                                <div class="summary-resume"></div>
                                <div class="content content-middle">
                                  <div class=" basic-summary"></div>
                                </div>
                                <div class="summary-resume last"></div>
                              </div>
                              <p class="equal-mob"></p>
                            </div>

                            <div class="grid-xs-24 grid-sm-8 is-stretch">
                              <div
                                class="mod-tab-subpanel last-item"
                                id="consumo"
                              >
                                <div class="title">
                                  <p>${i18n.t('consumption')}</p>
                                </div>
                                <div class="summary-resume"></div>

                                <div
                                  id="genConWidget3"
                                  class="content content-consumo"
                                >
                                  <div
                                    id="graphic3"
                                    class="widget-chart chari-chart dibujoR"
                                  ></div>
                                  <div
                                    id="rectNoData3"
                                    class="rect-no-data hidden"
                                    style="width:65%"
                                  >
                                    ${i18n.t('no-data-available')}
                                  </div>
                                </div>
                                <div class="mod-legend-popup">
                                  <div
                                    class="btn-legend-popup right"
                                    @click=${handleClick}
                                  >
                                    ${i18n.t('legend')}
                                  </div>
                                  <div
                                    class="box-legend-popup right  invisible"
                                  >
                                    <div
                                      class="eb-rigth widget-legend basic-legend"
                                    ></div>
                                  </div>
                                </div>
                                <div class="summary-resume last"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="grid-xs-24">
                        <div class="mod-action-buttons">
                          <a
                            href="/${i18n.t('routes.lng')}/balance"
                            class="btn-info"
                          >
                            ${i18n.t('more_info')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      title: i18n.t('more'),
      subcontent: html`
        <ul class="esios-also__list row"></ul>

        <!----->

        <div id="document-list-myp"></div>

        <!----->
        <div class="esios-documents">
          <div class="grid-xs-24 grid-xs-24--gc documents-container">
            <div class="mod-documents mod-buttons--gc">
              ${templateBoxDocument(documents, buttons)}
            </div>
            <div class="mod-documents mod-buttons--gc">
              ${templateBoxMarket(listMarket)}
              <div class="mod-buttons mod-buttons--gc">
              </br>
              ${
                lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''
              }
              </div>
            </div>
          </div>
        </div>
      `,
    }

    /** */
  );
};

export { templategenerConsumo };
