'use strict';

/**
 * @description GENERACION Y CONSUMO Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { componentLayoutExtended } from '../../shared/components/layoutExtended/layoutExtended.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { dateSetTime } from '../../utils/dateFormat.js';
import { charts } from '../../utils/options-charts.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils';
import { servicesgenerConsumo } from './generConsumo.service.js';
import { templategenerConsumo } from './generConsumo.template.js';

let componentgenerConsumo = (function () {
  let context = {};
  let handlers = {};
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };
  let UTC1 = false;
  let obToolbar = [];
  let date = moment().tz('Europe/Madrid');
  let newDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let renderSuccess = false;
  const currentHour = moment(date, 'DD-MM-YYYY').hours();
  const currentMinutes = moment(date, 'DD-MM-YYYY').minutes();
  const selectTimeForGenConventional = {
    id: 'select_conventional',
    currentHour: currentHour,
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (newValue) => changeTimeOnIndicator(newValue),
  };
  const selectTimeForFreeGeneration = {
    id: 'select_other_free_gen',
    currentHour: currentHour,
    currentMinutes:
      currentMinutes < 10
        ? '00'
        : currentMinutes.toString().substring(0, 1) + '0',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: Utils.getArrayOfMinutes(5),
    changeTime: (newValue) => changeTimeOnIndicator(newValue),
  };

  const selectTimeForElectricBalance = {
    id: 'select_electrict_balance',
    currentHour: currentHour,
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (newValue) => changeTimeOnIndicator(newValue),
  };

  const changeTimeOnIndicator = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];

    if (selectTimeForElectricBalance.id === id) {
      selectTimeForElectricBalance.currentHour = hours;
      selectTimeForElectricBalance.currentMinutes = minutes;
      componentTimeSelector.render(
        selectTimeForElectricBalance,
        '#electric-balance-time'
      );
      renderElectricBalanceAgain();
    }
    if (selectTimeForGenConventional.id === id) {
      selectTimeForGenConventional.currentHour = hours;
      selectTimeForGenConventional.currentMinutes = minutes;
      componentTimeSelector.render(
        selectTimeForGenConventional,
        '#gen_conventional-time'
      );
      renderConventionalAgain(false);
    }
    if (selectTimeForFreeGeneration.id === id) {
      selectTimeForFreeGeneration.currentHour = hours;
      if (hours > 2) {
        UTC1 = true;
      } else if (hours < 2) {
        UTC1 = false;
      }
      selectTimeForFreeGeneration.currentMinutes = minutes;
      componentTimeSelector.render(
        selectTimeForFreeGeneration,
        '#free-gen-timeSelector'
      );
      renderFreeGenAgain();
    }
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('generation_and_consumption')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    let documents = [
      {
        title: 'Informe incidente Tenerife 15 julio 2020',
        link: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}/informe-incidente-tenerife-15-julio-2020`,
      },
      {
        title: 'Informe Incidente Tenerife 29 septiembre 2019',
        link: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}/informe-incidente-tenerife-29-septiembre-2019`,
      },
      {
        title: 'P.O. 3.1. Programación de la generación',
        link: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}/p-o-3-1-programacion-de-la-generacion`,
      },
      {
        title:
          'Real Decreto 413/2014, de 6 de junio, por el que se regula la actividad de producción de energia eléctrica a partir de fuentes de energía renovables, cogeneracion y residuos',
        link: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}/real-decreto-413-2014-produccion-energia-electrica-fuentes-renovables-cogeneracion-residuos`,
      },
    ];
    let buttons = [
      {
        label: 'see_more_doc',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.generation_and_consumption')}`,
      },
      {
        label: 'downloads_files',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}`,
      },
    ];
    const currentRoute = window.location.pathname;
    let lang = currentRoute.split('/')[1];

    let listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];

    obToolbar = [
      {
        id: 1,
        slug: i18n.t('routes.power'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 1,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/10001?compare_indicators=472,473,474,475,476,477,478,479&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 2,
        slug: i18n.t('routes.generation-consumption-variation'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 2,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/1293?compare_indicators=545,544&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 3,
        slug: i18n.t('routes.co2-free-generation'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 3,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/10006?compare_indicators=1295,1294,551,546,549,552,1296&start_date=${newDate}T00:00&geoids=`,
      },
      {
        id: 4,
        slug: i18n.t('routes.electric-balance'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 4,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/10024?compare_indicators=369,370,92,91,79,95,88,90,96,83,82,80,81,87,97,71,2131,72,77,75,78,76,74,86,93,94,84,85,89,73,2143,2154&start_date=${newDate}T00:00&geoids=`,
      },
    ];

    try {
      // Render the template to the document
      let generalDate;
      let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');

      let loaded = {
        1: false,
        2: false,
        3: false,
        4: false,
      };

      const loadedCheck = (id) => {
        loaded[id] = true;
        let allLoaded = Object.values(loaded).some(
          (isLoaded) => isLoaded == false
        );

        if (!allLoaded) {
          let inputCalendar = document.querySelector('#datepicker');
          let divCalendar = document.querySelector('.is-day-selectable');
          inputCalendar.removeAttribute('disabled');
          divCalendar.classList.remove('disabled');
        }
      };

      await templategenerConsumo(
        documents,
        buttons,
        handlers,
        listMarket,
        lang
      );

      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.setAttribute('disabled', 'true');
      divCalendar.classList.add('disabled');

      document
        .querySelector('.esios-layout__breadcrumbs')
        .classList.add('esios-layout__breadcrumbs--gc');
      generalDate = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      if (urlParams.get('date')) {
        generalDate = urlParams.get('date');
      } else {
        setParameter('date', generalDate);
      }
      document.getElementById('datepicker').value = moment(
        generalDate,
        'DD-MM-YYYY'
      ).format('DD / MM / YYYY');

      if (generalDate !== now) {
        selectTimeForGenConventional.currentHour = '00';
        selectTimeForFreeGeneration.currentHour = '00';
        selectTimeForFreeGeneration.currentMinutes = '00';
        selectTimeForElectricBalance.currentHour = '00';
      } else {
        date = moment(date).toDate();
        var minutes = date.getUTCMinutes();
        minutes = Math.floor(minutes / 5) * 5 - 15;
        date.setUTCMinutes(minutes, 0, 0);
        selectTimeForFreeGeneration.currentHour = moment(date)
          .tz('Europe/Madrid')
          .format('HH');
        selectTimeForFreeGeneration.currentMinutes = moment(date)
          .tz('Europe/Madrid')
          .format('mm');
        selectTimeForElectricBalance.currentHour = moment(date)
          .tz('Europe/Madrid')
          .format('HH');
      }

      servicesgenerConsumo
        .getDataFromWidget({
          id: 1,
          date: generalDate,
          hours: selectTimeForGenConventional.currentHour,
        })
        .then((data) => {
          Utils.spinnerStatus('spinner1', false);
          document.querySelector(`#char1`).style.opacity = '1';

          if (data.indicators[0]?.values.length === 0) {
            selectTimeForGenConventional.currentHour = '00';
            selectTimeForGenConventional.currentMinutes = '00';
          }
          componentTimeSelector.render(
            selectTimeForGenConventional,
            '#gen_conventional-time'
          );
          charts[1](data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget1 svg')?.remove();
            charts[1](data);
          });

          setInfoModal(
            0,
            data,
            templateShareModal('power', window.location.search, false, {
              width: '100%',
              height: '900px',
            })
          );
        })
        .finally(() => {
          loadedCheck(1);
        });

      servicesgenerConsumo
        .getDataFromWidget({
          id: 2,
          date: generalDate,
        })
        .then((data) => {
          Utils.spinnerStatus('spinner2', false);
          document.querySelector(`#char2`).style.opacity = '1';

          charts[2](data, generalDate);
          Utils.showAndHideGraphLinesEyes(
            '#char2',
            '#char2 #widget2Data ul .legend-icon-eye'
          );
          setInfoModal(
            1,
            data,
            templateShareModal(
              'generation-consumption-variation',
              window.location.search,
              false,
              {
                width: '100%',
                height: '769px',
              }
            )
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget2 svg')?.remove();

            charts[2](data, generalDate);
            let eyes = document.querySelectorAll(
              '#char2 #widget2Data ul .legend-icon-eye'
            );
            eyes.forEach((eye) => {
              eye.classList.remove('disabled');
            });
            Utils.showAndHideGraphLinesEyes(
              '#char2',
              '#char2 #widget2Data ul .legend-icon-eye'
            );
          });
        })
        .finally(() => {
          loadedCheck(2);
        });

      servicesgenerConsumo
        .getDataFromWidget({
          id: 3,
          date: generalDate,
          hours: selectTimeForFreeGeneration.currentHour,
          minutes: selectTimeForFreeGeneration.currentMinutes,
        })
        .then((data) => {
          // Check Data:
          Utils.spinnerStatus('spinner3', false);
          document.querySelector(`#gycCo2WidgetView`).style.opacity = '1';
          if (data.indicators[0].values.length === 0) {
            selectTimeForFreeGeneration.currentHour = '00';
            selectTimeForFreeGeneration.currentMinutes = '00';
          }
          componentTimeSelector.render(
            selectTimeForFreeGeneration,
            '#free-gen-timeSelector'
          );
          renderDataLibre(data);
          setInfoModal(
            2,
            data,
            templateShareModal(
              'co2-free-generation',
              window.location.search,
              false,
              {
                width: '100%',
                height: '600px',
              }
            )
          );
        })
        .finally(() => {
          loadedCheck(3);
        });

      servicesgenerConsumo
        .getDataFromWidget({
          id: 4,
          date: generalDate,
          hours: selectTimeForElectricBalance.currentHour,
        })
        .then((data) => {
          Utils.spinnerStatus('spinner4', false);
          document.querySelector(`#gycBalanceWidgetView`).style.opacity = '1';

          if (data.indicators[0]?.values.length === 0) {
            selectTimeForElectricBalance.currentHour = '00';
          }
          componentTimeSelector.render(
            selectTimeForElectricBalance,
            '#electric-balance-time'
          );
          renderDataBalance(data);
          setInfoModal(
            3,
            data,
            templateShareModal(
              'electric-balance',
              window.location.search,
              false,
              {
                height: 518,
                width: '100%',
              }
            )
          );
        })
        .finally(() => {
          loadedCheck(4);
        });

      servicesgenerConsumo
        .getDataFromWidget({
          id: 5,
          date: generalDate,
        })
        .then((data) => {
          renderDataMore(data);
        });

      // Define a template

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
        onlyRefresh: () => renderComponentListAgain(),
      };

      componentDocumentList.render(newDLArray, '#document-list-myp');

      componentTimeSelector.render(
        selectTimeForGenConventional,
        '#gen_conventional-time'
      );
      componentTimeSelector.render(
        selectTimeForElectricBalance,
        '#electric-balance-time'
      );
      componentTimeSelector.render(
        selectTimeForFreeGeneration,
        '#free-gen-timeSelector'
      );

      $(document).on('change', '#datepicker', async () => {
        renderAllAgain(false);
      });

      let renderAllAgain = async (currentDay = true) => {
        let inputCalendar = document.querySelector('#datepicker');
        let divCalendar = document.querySelector('.is-day-selectable');
        inputCalendar.setAttribute('disabled', 'true');
        divCalendar.classList.add('disabled');

        if (currentDay) {
          date = moment().tz('Europe/Madrid').format('DD/MM/YYYY');
        } else {
          date = $('#datepicker').val();
        }

        setParameter('date', moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY'));
        Utils.spinnerStatus('spinner1', true);
        document.querySelector(`#char1`).style.opacity = '.5';
        Utils.spinnerStatus('spinner2', true);
        document.querySelector(`#char2`).style.opacity = '.5';
        Utils.spinnerStatus('spinner3', true);
        document.querySelector(`#gycCo2WidgetView`).style.opacity = '.5';
        Utils.spinnerStatus('spinner4', true);
        document.querySelector(`#gycBalanceWidgetView`).style.opacity = '.5';

        await renderFreeC0();
        await renderGenConsumpAgain();
        await renderConventionalAgain();
        await renderElectricB();

        inputCalendar.removeAttribute('disabled');
        divCalendar.classList.remove('disabled');
      };

      Utils.clickHoy('datepicker', renderAllAgain, true);

      let toogles = document.querySelectorAll('.mod-tab-subpanel');
      toogles.forEach((i) => {
        i.addEventListener('click', function (e) {
          let panelClass = $(e.target).attr('class');
          panelClass.includes('active')
            ? e.target.classList.remove('active')
            : e.target.classList.add('active');
        });
      });
      const RapidAccessData = [
        {
          id: '.widget-gyc-potencia',
          title: 'power',
        },
        {
          id: '.div-consumo',
          title: 'generation_and_consumption',
        },
        {
          id: '.div-generacion',
          title: 'co2_free_generation',
        },
        {
          id: '.gyc-balance',
          title: 'electric_balance',
        },
        {
          id: '.esios-layout__subcontent',
          title: 'more',
        },
      ];
      componentRapidAccess.render(RapidAccessData);
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document
      .querySelector('.esios-layout__extended')
      .classList.add('esios-layout__extended--gc');
    document
      .querySelector('.esios-layout__wrap')
      .classList.add('esios-layout__wrap--gc');
    document
      .querySelector('.esios-related-content')
      .classList.add('esios-related-content--gc');
    document
      .querySelector('.esios-layout__subcontent ')
      .classList.add('esios-layout__subcontent--gc');

    document
      .querySelector('.esios-layout__subcontent__wrapper')
      .classList.add('esios-layout__subcontent__wrapper--gc');
    document.querySelector('#gycBalanceWidgetView').classList.add('widget--gc');
    document
      .querySelector('.esios-also__list')
      .classList.add('esios-also__list--gc');
    document
      .querySelector('.esios-boxdoc__title')
      .classList.add('esios-boxdoc__title--gc');
    document
      .querySelector('.esios-boxdoc__list')
      .classList.add('esios-boxdoc__list--gc');
    document
      .querySelector('.esios-documents')
      .classList.add('esios-documents--gc');

    let list = document.querySelectorAll('.mod-documents');
    for (let i = 0; i < list.length; ++i) {
      list[i].classList.add('mod-documents--gc');
    }

    document.querySelector('.mod-buttons').classList.add('mod-buttons--gc');
    document
      .querySelector('.is-section-title')
      .classList.add('is-section-title--gc');
    document.querySelector('.mod-maps').classList.add('mod-maps--gc');

    let sizeList = document.querySelectorAll('.grid-xs-24.grid-sm-8');
    for (let i = 0; i < sizeList.length; ++i) {
      sizeList[i].classList.add('grid-sm-8--gc');
    }

    let titleList = document.querySelectorAll(
      '.esios-related-content .mod-tab-subpanel .title'
    );
    for (let i = 0; i < titleList.length; ++i) {
      titleList[i].classList.add('title--gc');
    }
  };

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      obToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    let dateForUrl =
      getUrlParams()?.date || moment().tz('Europe/Madrid').format('DD-MM-YYYY');
    if (id === 2) {
      obToolbar[id].url = () =>
        `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.analysis'
        )}/10006?compare_indicators=1295,1294,551,546,549,552,1296&start_date=${dateForUrl}T00:00&geoids=`;
    }
    if (id === 3) {
      data.indicators = data.indicators.filter(
        (el) => (el.geo_id === 8741) & (el.id !== 1136)
      );
      obToolbar[id].url = () =>
        `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.analysis'
        )}/10024?compare_indicators=369,370,92,91,79,95,88,90,96,83,82,80,81,87,97,71,2131,72,77,75,78,76,74,86,93,94,84,85,89,73,2143,2154&start_date=${dateForUrl}T00:00&geoids=`;
    }
    obToolbar[id].widgetData = data;
    if (id == 1) {
      obToolbar[id].url = () => data.urlAnalysis;
    }
    obToolbar[id].datetime = urlParams.get('date')
      ? moment(urlParams.get('date'), 'DD-MM-YYYY')
          .tz('Europe/Madrid')
          .format('YYYY-MM-DD_HH_mm')
      : moment().tz('Europe/Madrid').format('YYYY-MM-DD_HH_mm');
    obToolbar[id].shareModal = html;
    componentToolbar.render(obToolbar[id], '.esios-toolbar' + (id + 1));
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    componentLayoutExtended.render();
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  // ? FUNCIONES PARA VOLVER A RENDERIZAR EL CONTENIDO QUE CAMBIO SU ESTADO GLOBAL
  let renderElectricBalanceAgain = async () => {
    let hours = selectTimeForElectricBalance.currentHour;
    let minutes = selectTimeForElectricBalance.currentMinutes;
    Utils.spinnerStatus('spinner4', true);
    document.getElementById('spinner4').style.cssText = `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    opacity: .7;
    z-index: 1;`;
    const date = document.getElementById('datepicker').value;
    try {
      let DataBalance = await servicesgenerConsumo.getDataFromWidget({
        id: 4,
        hours,
        minutes,
        date,
      });

      document.querySelector('#gycBalanceWidgetView .dibujo svg').remove();
      document.querySelector('#gycBalanceWidgetView .dibujoR svg').remove();
      renderDataBalance(DataBalance);
      Utils.spinnerStatus('spinner4', false);
      document.querySelector(`#gycBalanceWidgetView`).style.opacity = '1';

      setInfoModal(
        3,
        DataBalance,
        templateShareModal('electric-balance', window.location.search, false, {
          height: 518,
          width: '100%',
        }),
        'share'
      );
      renderSuccess = true;
      document.querySelector(
        '#gycBalanceWidgetView .esios-toolbar-time__mod-date'
      ).innerHTML = hours + ':00';
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderElectricB = async () => {
    let hours = '00';
    let minutes = '00';

    try {
      let DataBalance = await servicesgenerConsumo.getDataFromWidget({
        id: 4,
        hours,
        minutes,
        date,
      });
      document.querySelector('#gycBalanceWidgetView .dibujo svg').remove();
      document
        .querySelector('#gycBalanceWidgetView .dibujo .chartLegend')
        .remove();
      document.querySelector('#gycBalanceWidgetView .dibujoR svg').remove();
      document
        .querySelector('#gycBalanceWidgetView .dibujoR .chartLegendR')
        .remove();

      renderDataBalance(DataBalance);
      Utils.spinnerStatus('spinner4', false);
      document.querySelector(`#gycBalanceWidgetView`).style.opacity = '1';

      setInfoModal(
        3,
        DataBalance,
        templateShareModal('electric-balance', window.location.search, false, {
          height: 518,
          width: '100%',
        }),
        'share'
      );
      document.querySelector(
        '#gycBalanceWidgetView .esios-toolbar-time__mod-date'
      ).innerHTML = '00:00';
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderFreeGenAgain = async () => {
    let hours = selectTimeForFreeGeneration.currentHour;
    let minutes = selectTimeForFreeGeneration.currentMinutes;
    try {
      Utils.spinnerStatus('spinner3', true);
      document.getElementById('spinner3').style.cssText = `
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);
      background: white;
      opacity: .7;`;
      const date = document.getElementById('datepicker').value;

      let DataLibre = await servicesgenerConsumo.getDataFromWidget({
        id: 3,
        hours,
        minutes,
        date,
        UTC1,
      });

      renderDataLibre(DataLibre);
      Utils.spinnerStatus('spinner3', false);
      document.querySelector(`#gycCo2WidgetView`).style.opacity = '1';

      setInfoModal(
        2,
        DataLibre,
        templateShareModal(
          'co2-free-generation',
          window.location.search,
          false,
          {
            width: '100%',
            height: '600px',
          }
        ),
        'share'
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderFreeC0 = async () => {
    let hours = '00';
    let minutes = '00';

    try {
      document
        .querySelectorAll('#gycCo2WidgetView .widget-summary')
        .forEach((el) => {
          el.style.display = 'none';
        });
      let DataLibre = await servicesgenerConsumo.getDataFromWidget({
        id: 3,
        hours,
        minutes,
        date,
      });

      renderDataLibre(DataLibre);
      Utils.spinnerStatus('spinner3', false);
      document.querySelector(`#gycCo2WidgetView`).style.opacity = '1';

      setInfoModal(
        2,
        DataLibre,
        templateShareModal(
          'co2-free-generation',
          window.location.search,
          false,
          {
            width: '100%',
            height: '600px',
          }
        ),
        'share'
      );
      document
        .querySelectorAll('#gycCo2WidgetView .widget-summary')
        .forEach((el) => {
          el.style.display = 'block';
        });
      selectTimeForFreeGeneration.currentHour = '00';
      selectTimeForFreeGeneration.currentMinutes = '00';
      componentTimeSelector.render(
        selectTimeForFreeGeneration,
        '#free-gen-timeSelector'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderGenConsumpAgain = async () => {
    try {
      Utils.noDataStatus('rect2', false);
      document.querySelector('#widget2').classList.remove('no-data-graphic');
      document.querySelector('#widget2 svg').remove();
      let DataConsumo = await servicesgenerConsumo.getDataFromWidget({
        id: 2,
        date,
      });
      renderGeneracionConsumo(DataConsumo, date);
      Utils.showAndHideGraphLinesEyes(
        '#char2',
        '#char2 #widget2Data ul .legend-icon-eye'
      );
      window.addEventListener('resize', () => {
        document.querySelector('#widget2 svg')?.remove();

        renderGeneracionConsumo(DataConsumo, date);
        let eyes = document.querySelectorAll(
          '#char2 #widget2Data ul .legend-icon-eye'
        );
        eyes.forEach((eye) => {
          eye.classList.remove('disabled');
        });
        Utils.showAndHideGraphLinesEyes(
          '#char2',
          '#char2 #widget2Data ul .legend-icon-eye'
        );
      });
      Utils.spinnerStatus('spinner2', false);
      document.querySelector(`#char2`).style.opacity = '1';

      setInfoModal(
        1,
        DataConsumo,
        templateShareModal(
          'generation-consumption-variation',
          window.location.search,
          false,
          {
            width: '100%',
            height: '769px',
          }
        ),
        'share'
      );
      if (DataConsumo.indicators[0].values.length === 0) {
        document.querySelector('#char2 .mod-date').innerHTML = '00:00';
      } else {
        document.querySelector('#char2 .mod-date').innerHTML = '03:00';
      }

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderConventionalAgain = async (selects = true) => {
    if (selects) {
      let generalDate;
      let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');

      generalDate = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      if (urlParams.get('date')) {
        generalDate = urlParams.get('date');
      } else {
        setParameter('date', generalDate);
      }
      document.getElementById('datepicker').value = moment(
        generalDate,
        'DD-MM-YYYY'
      ).format('DD / MM / YYYY');
      if (generalDate !== now) {
        selectTimeForGenConventional.currentHour = '00';
      }
    }

    let hours = selectTimeForGenConventional.currentHour;
    let minutes = selectTimeForGenConventional.currentMinutes;
    document.querySelector(`#char1`).style.opacity = '0.5';
    document.getElementById('spinner1').style.cssText = `
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    background: white;
    z-index:1;
    opacity: .7;`;
    document.querySelector('#rectPiePotencia').classList.add('hidden');
    let date = document.getElementById('datepicker').value;
    try {
      let Datageneracion = await servicesgenerConsumo.getDataFromWidget({
        id: 1,
        hours,
        minutes,
        date,
      });
      document.querySelector('#char1 .gen-bar svg')?.remove();
      document.querySelector('#char1 .piePotencia svg')?.remove();
      document.querySelector('#char1 .legend-potencial-pie ul')?.remove();
      renderDatageneracion(Datageneracion);

      window.addEventListener('resize', () => {
        document.querySelector('#char1 .gen-bar svg')?.remove();
        document.querySelector('#char1 .piePotencia svg')?.remove();
        document.querySelector('#char1 .legend-potencial-pie ul')?.remove();
        renderDatageneracion(Datageneracion);
      });
      Utils.spinnerStatus('spinner1', false);
      document.querySelector(`#char1`).style.opacity = '1';

      setInfoModal(
        0,
        Datageneracion,
        templateShareModal('power', window.location.search, false, {
          width: '100%',
          height: '900px',
        }),
        'share'
      );

      document.querySelector('#char1 .esios-toolbar-time__mod-date').innerHTML =
        hours + ':00';
      renderSuccess = true;
      $.datepicker._gotoToday = function (id) {
        $(id).datepicker('setDate', new Date()).datepicker('hide').blur();
      };
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPowerConventional = async () => {
    let hours = '00';
    let minutes = '00';
    document.querySelector('#char1 .piePotencia svg').style.cssText =
      'visibility: hidden;';
    document.querySelector('#char1 .piePotencia .chartLegend').style.cssText =
      'visibility: hidden;';

    document.querySelector(`#char1`).style.opacity = '.5';

    document.getElementById('spinner1').style.cssText = `
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    background: white;
    opacity: .7;`;
    try {
      let Datageneracion = await servicesgenerConsumo.getDataFromWidget({
        id: 1,
        hours,
        minutes,
        date: moment(date, 'DD / MM / YYYY').format('DD-MM-YYYY'),
      });
      document.querySelector('#char1 .piePotencia .chartLegend').style.cssText =
        'visibility: visible;';
      document.querySelector('#char1 .gen-bar svg').remove();
      document.querySelector('#char1 .piePotencia svg').remove();
      document.querySelector('#char1 .piePotencia .legend').remove();
      renderDatageneracion(Datageneracion);

      window.addEventListener('resize', () => {
        document.querySelector('#char1 svg')?.remove();
        renderDatageneracion(Datageneracion);
      });

      setInfoModal(
        0,
        Datageneracion,
        templateShareModal('power', window.location.search, false, {
          width: '100%',
          height: '900px',
        }),
        'share'
      );
      Utils.spinnerStatus('spinner1', false);
      document.querySelector(`#char1`).style.opacity = '1';

      selectTimeForGenConventional.currentHour = '00';
      selectTimeForGenConventional.currentMinutes = '00';
      componentTimeSelector.render(
        selectTimeForGenConventional,
        '#gen_conventional-time'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;

    let documentListData = await servicesgenerConsumo.getArchives(filterForm);

    let newDLArray = {
      isLoading: documentListData.isLoading,
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
      onlyRefresh: () => renderComponentListAgain(),
    };
    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  let renderComponentListAgain = async (form) => {
    let newDLArray = {
      renderAgain: (form) => renderComponentDocumentList(form),
      onlyRefresh: () => renderComponentListAgain(),
    };

    componentDocumentList.render(newDLArray, '#document-list-myp');
  };
  const renderDatageneracionPublic = (data) => renderDatageneracion(data);
  const renderDataLibrePublic = (data) => renderDataLibre(data);
  const renderDataBalancePublic = (data) => renderDataBalance(data);

  return {
    render: init,
    renderDatageneracionPublic,
    renderDataBalancePublic,
    renderDataLibrePublic,
  };
})();

export { componentgenerConsumo };

const renderGeneracionConsumo = async (data, currentDate) => {
  charts[2](data, currentDate);
  // const indicators = data.indicators;
  // const graphColors = ['#FFCF09', '#DF4A32', '#74BA05'];
  // const timeID = indicators[0].time;
  // let step = Utils.getTimeValue(timeID) || 10;
  // const lastDate = [];
  // const parsedData = indicators.map((el, index) => {
  //   if (index === 0) {
  //     el.values.map((date) => {
  //       if (date.y !== null) {
  //         lastDate.push(moment(date.x).tz('Europe/Madrid').format());
  //       }
  //     });
  //   }
  //   return {
  //     id: el.id,
  //     type: 'line',
  //     name: el.short_name,
  //     values: el.values,
  //     dots: false,
  //     interpolation: 'linear',
  //     color: graphColors[index],
  //   };
  // });

  // var options = {
  //   target: d3.select('#widget2').node(),
  //   margin: '40 15 25 45',
  //   trail: {
  //     enabled: true,
  //     parseStep: function (date) {
  //       date = moment(date).toDate();
  //       var minutes = date.getUTCMinutes();
  //       minutes = Math.floor(minutes / step) * step;
  //       date.setUTCMinutes(minutes, 0, 0);
  //       return date;
  //     },
  //     initXValue: function () {
  //       return lastDate.at(-1);
  //     },
  //   },
  //   xaxis: {
  //     scale: 'time',
  //     fit: true,
  //     ticks: [d3.time.hours, 1],

  //     bottom: {
  //       label: i18n.t('hours'),
  //       tickFormat: function (d, i) {
  //         var date = moment(d).tz('Europe/Madrid');
  //         var hour = date.hours();
  //         if (i % 2) {
  //           return '';
  //         } else {
  //           return hour < 10 ? '0' + hour : hour;
  //         }
  //       },
  //     },
  //   },
  //   yaxis: {
  //     fit: true,
  //     textMarginTop: -6,
  //     ticks: [5],
  //     left: {
  //       label: 'MW',
  //       tickFormat: function (y, i) {
  //         return Utils.toNumber(y, 0, 'comma');
  //       },
  //     },
  //   },
  // };

  // const arrGeneConGC = indicators.map((elemnt, index) => {
  //   return {
  //     id: elemnt.id,
  //     composited: elemnt.composited,
  //     alias: elemnt.alias,
  //     name: elemnt.short_name,
  //     value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
  //     color: graphColors[index],
  //   };
  // });

  // let graphLibreLegendsC = document.querySelector(
  //   '#char2 .widget-legend.in-line.visual-legend ul'
  // );

  // graphLibreLegendsC.innerHTML = '';
  // arrGeneConGC.map((el, index) => {
  //   graphLibreLegendsC.innerHTML += `
  //    <li class="legend-item">
  //                       <div class="item">
  //                         <span
  //                           class="this legend-icon-eye icon-eye"
  //                           data-id=${el.id}
  //                           style="background-color: ${graphColors[index]};"
  //                         ></span
  //                         ><span class="label"> ${el.name}</span>
  //                       </div>
  //                     </li>
  //      `;
  // });

  //var chart = new Charicharts.Chart(options, parsedData);
  //Utils.setNoDataStatus(data, '2');

  // let graphLibreLegendsD = document.querySelector(
  //   '#char2 .gyc-summary.highlighted-summary .in-line'
  // );
  // let graphLibreLegendV = document.querySelector(
  //   '#char2 .value.is-big.variacion'
  // );
  // let showRemainder;
  // let a = 0;
  // let b = 0;
  // let remainder = 0;
  // graphLibreLegendV.innerHTML = '';
  // parsedData.map((el) => {
  //   if (el.id == 545) {
  //     a = el.y;
  //   }
  //   if (el.id == 1293) {
  //     b = el.y;
  //   }
  //   remainder = Math.round(((b - a) / b) * 100);
  // });
  // graphLibreLegendV.innerHTML += `
  //   <div class="value is-big-number is-bold">
  //     ${
  //       Number.isFinite(remainder) || showRemainder ? remainder : '-'
  //     } <span class="unit is-small-number"> %</span>
  //     </div>
  //       `;
  // graphLibreLegendsD.innerHTML = '';
  // parsedData.map((el) => {
  //   if (el.y == null) {
  //     showRemainder = false;
  //     graphLibreLegendsD.innerHTML += ``;
  //   } else {
  //     showRemainder === true;
  //     graphLibreLegendsD.innerHTML += `
  //     <div class="widget-summary-item">
  //       <div class="value">
  //         <div class="is-number first">
  //           ${Utils.toNumber(el.y, 0, 'comma')}<span class="unit"> MW </span>
  //         </div>
  //       </div>
  //       <div class="label">${el.name}</div>
  //     </div>

  //       `;
  //   }
  // });
  // let grapTime = document.querySelector('#char2 .mod-date');
  // chart.on('Trail/moved', function (parsedData, d) {
  //   grapTime.innerHTML = moment(d).tz('Europe/Madrid').format('HH:mm');
  //   graphLibreLegendsD.innerHTML = '';
  //   parsedData.map((el) => {
  //     if (el.y == null) {
  //       showRemainder = false;
  //       graphLibreLegendsD.innerHTML += `
  //       <div class="widget-summary-item">
  //         <div class="value">
  //           <div class="is-number first">
  //             - <span class="unit">MW</span>
  //           </div>
  //         </div>
  //         <div class="label">${el.name}</div>
  //       </div>

  //         `;
  //     } else {
  //       showRemainder = true;
  //       graphLibreLegendsD.innerHTML += `
  //       <div class="widget-summary-item">
  //         <div class="value">
  //           <div class="is-number first">
  //             ${Utils.toNumber(el.y, 0, 'comma')}<span class="unit"> MW </span>
  //           </div>
  //         </div>
  //         <div class="label">${el.name}</div>
  //       </div>

  //         `;
  //     }
  //   });

  //   graphLibreLegendV.innerHTML = '';
  //   parsedData.map((el) => {
  //     if (el.id == 545) {
  //       a = el.y;
  //     }
  //     if (el.id == 1293) {
  //       b = el.y;
  //     }
  //     remainder = Math.round(((b - a) / b) * 100);
  //   });

  //   graphLibreLegendV.innerHTML += `
  //   <div class="value is-big-number is-bold">
  //     ${
  //       Number.isFinite(remainder) || showRemainder
  //         ? remainder !== -Infinity
  //           ? remainder
  //           : '-'
  //         : '-'
  //     } <span class="unit is-small-number"> %</span>
  //     </div>
  //       `;
  // });

  // var aspect = 300 / 300,
  //   chart = d3.select('#generacion-consumo');
  // d3.select(window).on('resize', function () {
  //   var targetWidth = chart.node().getBoundingClientRect().width;
  //   chart.attr('width', targetWidth);
  //   chart.attr('height', targetWidth / aspect);
  // });
};

const renderDatageneracion = async (data) => {
  const indicators = data.indicators;

  const colorBar = ['#85B200', '#CF4634'];

  const disponible = indicators.find((item) => item.id == 10001);
  const valorDisponible =
    disponible.values.length === 0 ? 0 : disponible.values[0]?.y;

  const filterBar = [10001, 462, 10003];
  const filterBarA = [10001, 462];
  piePotencia(indicators, valorDisponible);

  const arrGeneConBar = indicators
    .map((elemnt, index) => {
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        color: colorBar[index],
      };
    })
    .filter((el) => filterBar.includes(el.id));

  const barAux = indicators
    .map((b, index) => {
      let difference = 0;
      if (b.values.length > 0) {
        if (b.id == 10001) {
          b.values.y = b.values[0].y;
        } else if (b.id == 462) {
          arrGeneConBar.map((a) => {
            if (a.id == 10001) {
              difference = a.value - difference;
            }
            if (a.id == 10003) {
              difference = a.value - difference;
            }
          });
          b.values.y = difference;
        }
      }

      return {
        id: b.id,
        name: b.short_name,
        value: b.values.length > 0 ? b.values.y : 0,
        color: colorBar[index],
      };
    })
    .filter((b) => filterBarA.includes(b.id));

  let optionsBarA = {
    target: d3.select('#char1 .widget-summary-bar.gen-bar').node(),
    innerArrow: true,
    innerRadius: 0.7,
    hoverFade: 1,
  };
  let barPercentage = new Charicharts.PercentageBar(optionsBarA, barAux);

  let graphUpLegends = document.querySelector(
    '#char1 .widget-summary.gyc-summary .dispIndi'
  );

  // change upperData

  graphUpLegends.innerHTML = '';
  arrGeneConBar.map((el, index) => {
    if (el.id == 10001) {
      graphUpLegends.innerHTML += `
      <div class="widget-summary-item">
      <span class="legend-circle" style="background: ${
        colorBar[index]
      };" ></span>
    <div class="label"> ${el.name}</div>
    <div class="value has-padding">
      <div class="is-number">
      ${el.value === 0 ? '-' : Utils.toNumber(el.value.toFixed(1), 1)} 
        <span class="unit">MW</span>
      </div>
    </div>
    </div>
      `;
    }

    if (el.id == 462) {
      let difference = 0;

      arrGeneConBar.map((b) => {
        if (b.id == 10001) {
          difference = b.value - difference;
        }
        if (b.id == 10003) {
          difference = b.value - difference;
        }
      });

      el.value = difference;

      graphUpLegends.innerHTML += `
      <div class="widget-summary-item">
        <span
          class="legend-circle" style="background:  ${colorBar[index]};"
         ></span>
          <div class="label">${el.name}</div>
            <div class="value has-padding">
              <div class="is-number">
              
              ${el.value === 0 ? 0 : Utils.toNumber(el.value.toFixed(1), 1)} 
                  <span class="unit">MW</span>
              </div>
            </div>
      </div>

      `;
    }
  });
  let graphUpLegendsD = document.querySelector(
    '#char1 .widget-summary-item.instalada span'
  );

  graphUpLegendsD.innerHTML = '';
  arrGeneConBar.map((el) => {
    if (el.id == 10003) {
      graphUpLegendsD.innerHTML += `
      
      <span class="legend-circle" style="background-color: #ffffff;"></span>
      <div class="label">${el.name}</div>
                    <div class="value has-padding">
                      <div class="is-number">
                      ${Utils.toNumber(el.value.toFixed(1), 1)} 
                        <span class="unit">MW</span>
                      </div>
                    </div>
                  </div>

      `;
    }
  });

  if (indicators[0].values.length === 0) {
    document.querySelector('#rectPiePotencia').classList.remove('hidden');
    document.querySelectorAll('.piePotencia svg').forEach((el) => {
      el.style.display = 'none';
    });
    document.querySelectorAll('.piePotencia .chartLegend').forEach((el) => {
      el.style.display = 'none';
    });
  } else {
    document.querySelector('#rectPiePotencia').classList.add('hidden');
    document
      .querySelector('.piePotencia .chartLegend')
      .classList.remove('hidden');
  }
};

const renderDataBalance = async (data) => {
  const indicators = data.indicators;

  /* COLORS*/
  let colorsLD = [
    '#146797',
    '#85b11e',
    '#fdce2e',
    '#23a3d6',
    '#d60012',
    '#717171',
    '#fb91fd',
  ];

  let colorsLL = [
    '#0090d1',
    '#6FB114',
    '#e48500',
    '#ff0000',
    '#8C5DA2',
    '#54b8be',
    '#464394',
    '#ffcc66',
    '#ad5c34',
    '#BA0F16',
    '#CFA2CA',
    '#A0A0A0',
    '#EB6539',
    '#8C0000',
  ];

  /* IDs*/

  let LegendDownLeft = [
    10063, 10010, 84, 85, 10013, 2131, 74, 79, 10008, 10009, 10011, 10012, 96,
    10025,
  ];

  let LegendDownCenter = [10014, 10015, 10016, 10017];

  let LegendAlmCenter = [2143, 2154, 95, 73];

  let LegendDownRight = [365, 366, 367, 368, 369, 370, 371];

  let sumaBalanceL = 0;
  let sumaBalanceR = 0;
  let sumaBalanceI = 0;
  let sumaBalanceA = 0;

  let arrBalanceG;
  const itemPositions = {};
  for (const [index, id] of LegendDownLeft.entries()) {
    itemPositions[id] = index;
  }
  arrBalanceG = indicators
    .filter((el) => LegendDownLeft.includes(el.id))
    .sort((a, b) => itemPositions[a.id] - itemPositions[b.id]);

  arrBalanceG = arrBalanceG.map((elemnt, index) => {
    sumaBalanceL += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
    return {
      id: elemnt.id,
      composited: elemnt.composited,
      alias: elemnt.alias,
      name: elemnt.short_name,
      value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
      color: colorsLL[index],
    };
  });

  let arrBalanceC;
  arrBalanceC = indicators
    .filter((el) => LegendDownRight.includes(el.id))
    .map((elemnt, index) => {
      sumaBalanceR += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        color: colorsLD[index],
      };
    });

  let arrBalanceI;
  arrBalanceI = indicators
    .filter((el) => LegendDownCenter.includes(el.id))
    .map((elemnt, index) => {
      sumaBalanceI += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
      };
    });

  let arrBalanceA;
  arrBalanceA = indicators
    .filter((el) => LegendAlmCenter.includes(el.id))
    .map((elemnt, index) => {
      sumaBalanceA += elemnt.values.length > 0 ? elemnt.values[0].y : 0;
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
      };
    });

  arrBalanceA.sort((a, b) => a.id - b.id);

  let options = {
    target: d3.select('#gycBalanceWidgetView .dibujo').node(),
    innerArrow: true,
    innerRadius: 0.65,
    hoverFade: 1,
  };

  let optionsR = {
    target: d3.select('#gycBalanceWidgetView .dibujoR').node(),
    innerArrow: true,
    innerRadius: 0.65,
    hoverFade: 1,
  };

  let pie = new Charicharts.Pie(options, arrBalanceG);
  let pieR = new Charicharts.Pie(optionsR, arrBalanceC);

  /* ----- SECCION GENERACION ----- */
  document.querySelectorAll('#gycBalanceWidgetView line').forEach((line) => {
    line.setAttribute('x2', '127');
  });
  /** TITLE  */
  let titleGeneracion = document.querySelectorAll(
    '#generacion .summary-resume'
  );
  titleGeneracion.forEach((i) => {
    i.innerHTML = '';

    i.innerHTML += `
      <div class="value">
          <div class="is-small-number">
          ${Utils.toNumber(sumaBalanceL.toFixed(1), 1, 'comma')}
             <span class="unit">MWh</span>
          </div>
      </div>
  
       ${
         sumaBalanceL === 0
           ? ''
           : `<div class="value">
       <div class="is-small-number is-bold">
       ${Utils.toNumber(
         ((sumaBalanceL * 100) / sumaBalanceR).toFixed(1),
         1,
         'comma'
       )}
       <span class="unit">% </span>
       </div>
     </div>`
       }
  
        `;
  });

  /* GRAFICA*/
  const dataLength2 = indicators[0].values.length;
  const rectNoData2 = document.getElementById('rectNoData2');
  const wid2 = document.getElementById('genConWidget2');
  if (dataLength2 != 0) {
    wid2.classList.remove('no-data-graphic-s');
    rectNoData2.classList.add('hidden');
  } else {
    wid2.classList.add('no-data-graphic-s');
    rectNoData2.classList.remove('hidden');
  }

  const graphPieLegendCenter = document.querySelector(
    '#gycBalanceWidgetView  .chari-chart'
  );
  graphPieLegendCenter.insertAdjacentHTML(
    'beforeend',
    `  <div class="chartLegend"></div>`
  );

  pie.on('Pie-piece/mouseover', function (arrBalanceG, d) {
    const centerLegendContent = document.querySelector(
      '#gycBalanceWidgetView .chartLegend'
    );

    centerLegendContent.innerHTML = `
    <h3 class="title">${arrBalanceG.data.alias}</h3>
    <div class="value be-size">
    ${Utils.toNumber(arrBalanceG.data.value.toFixed(1), 1, 'comma', true)} 
    </div> 
    <div class="unit">MWh </div>
    `;
  });

  /** ---------- SECCION Almacenamiento -------- */

  /** TITLE  */
  let titleInteralm = document.querySelectorAll(
    '#almacenamiento .summary-resume'
  );
  titleInteralm.forEach((i) => {
    i.innerHTML = '';

    i.innerHTML += `
    <div class="value">
        <div class="is-small-number">
        ${Utils.toNumber(sumaBalanceA.toFixed(1), 1, 'comma')}
           <span class="unit">MWh</span>
        </div>
    </div>
  
    ${
      sumaBalanceA === 0
        ? ''
        : `<div class="value">
    <div class="is-small-number is-bold">
    ${Utils.toNumber(
      ((sumaBalanceA * 100) / sumaBalanceR).toFixed(1),
      1,
      'comma'
    )} 
   <span class="unit">%</span>
    </div>
  </div>`
    }
      `;
  });

  /** LIST  */
  let legendInteralm = document.querySelector('#almacenamiento .basic-summary');

  legendInteralm.innerHTML = '';
  arrBalanceA.map((el, index) => {
    if (el.value < 0) {
      legendInteralm.innerHTML += `
      <div class=" widget-summary-item">
        <div class="label"> ${el.alias}</div>
        <div class="value">
          <div class="is-micro-number is-bold">
          ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
            <span class="unit">MWh</span>
          </div>
        </div>
      </div>
      `;
    } else {
      legendInteralm.innerHTML += `
      <div class=" widget-summary-item">
        <div class="label"> ${el.alias}</div>
        <div class="value">
          <div class="is-micro-number is-bold">
          ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
            <span class="unit">MWh</span>
          </div>
        </div>
      </div>
      `;
    }
  });

  /** ---------- SECCION INTERCONEXIONES -------- */

  /** TITLE  */
  let titleInter = document.querySelectorAll(
    '#interconexiones .summary-resume'
  );
  titleInter.forEach((i) => {
    i.innerHTML = '';

    i.innerHTML += `
    <div class="value">
        <div class="is-small-number">
        ${Utils.toNumber(sumaBalanceI.toFixed(1), 1, 'comma')}
           <span class="unit">MWh</span>
        </div>
    </div>
  
    ${
      sumaBalanceI === 0
        ? ''
        : `<div class="value">
    <div class="is-small-number is-bold">
    ${Utils.toNumber(
      ((sumaBalanceI * 100) / sumaBalanceR).toFixed(1),
      1,
      'comma'
    )} 
   <span class="unit">%</span>
    </div>
  </div>`
    }
      `;
  });

  /** LIST  */
  let legendInter = document.querySelector('#interconexiones .basic-summary');

  legendInter.innerHTML = '';
  arrBalanceI.map((el, index) => {
    if (el.value < 0) {
      legendInter.innerHTML += `
      <div class=" widget-summary-item">
        <div class="label false"> ${el.alias}</div>
        <div class="value">
          <div class="is-micro-number is-bold">
          ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
            <span class="unit">MWh</span>
          </div>
        </div>
      </div>
      `;
    } else {
      legendInter.innerHTML += `
      <div class=" widget-summary-item">
        <div class="label true"> ${el.alias}</div>
        <div class="value">
          <div class="is-micro-number is-bold">
          ${Utils.toNumber(el.value.toFixed(1), 1, 'comma')} 
            <span class="unit">MWh</span>
          </div>
        </div>
      </div>
      `;
    }
  });

  /* SECCION CONSUMO*/

  /** TITLE  */
  let titleConsumo = document.querySelectorAll('#consumo .summary-resume');
  titleConsumo.forEach((i) => {
    i.innerHTML = '';

    i.innerHTML += `
      <div class="value">
          <div class="is-small-number">
          ${Utils.toNumber(sumaBalanceR.toFixed(1), 1, 'comma')}
             <span class="unit"> MWh</span>
          </div>
      </div>
  

      
      <div class="value">
         <div class="is-small-number is-bold">
         ${
           sumaBalanceR === 0
             ? '100,0'
             : Utils.toNumber(
                 ((sumaBalanceR * 100) / sumaBalanceR).toFixed(1),
                 1,
                 'comma'
               )
         }
         <span class="unit"> %</span>
         </div>
       </div>

        `;
  });

  /** GRAFICA  */
  const dataLength3 = indicators[0].values.length;
  const rectNoData3 = document.getElementById('rectNoData3');
  const wid3 = document.getElementById('genConWidget3');
  if (dataLength3 != 0) {
    wid3.classList.remove('no-data-graphic-s');
    rectNoData3.classList.add('hidden');
    // Select the SVG element inside the div with id 'graphic3'
    const svgElement = document.querySelector('#graphic3 svg');
    const svgElement2 = document.querySelector('#graphic2 svg');

    // Set the viewBox attribute to the desired value
    if (svgElement) {
      svgElement.setAttribute('viewBox', '0 0 250 290');
      svgElement2.setAttribute('viewBox', '0 0 250 290');
    } else {
      console.error('SVG element not found!');
    }
  } else {
    wid3.classList.add('no-data-graphic-s');
    rectNoData3.classList.remove('hidden');
    document.querySelector('#graphic3 > svg').classList.add('hidden');
    document.querySelector('#graphic2 > svg').classList.add('hidden');
    // Select the div element with id 'rectNoData3'
    const noDataDiv = document.querySelector('#rectNoData3');
    const noDataDiv2 = document.querySelector('#rectNoData2');

    // Set the width style property to 85%
    if (noDataDiv) {
      noDataDiv.style.width = '85%';
      noDataDiv2.style.width = '85%';
    } else {
      console.error('No data div not found!');
    }
  }

  const graphPieLegendCenterR = document.querySelector(
    '#gycBalanceWidgetView  .chari-chart.dibujoR'
  );
  graphPieLegendCenterR.insertAdjacentHTML(
    'beforeend',
    `  <div class="chartLegendR"></div>`
  );

  pieR.on('Pie-piece/mouseover', function (arrBalanceC, d) {
    const centerLegendContentR = document.querySelector(
      '#gycBalanceWidgetView .chartLegendR'
    );

    centerLegendContentR.innerHTML = `
    <h3 class="title">${arrBalanceC.data.alias}</h3>
    <div class="value be-size">
    ${Utils.toNumber(arrBalanceC.value.toFixed(1), 1, 'comma')} 
    </div> 
      <div class="unit">MWh</div>
  
    `;
  });

  renderList(pie, '.eb-left.basic-legend', arrBalanceG, colorsLL);

  renderList(pieR, '.eb-rigth.basic-legend', arrBalanceC, colorsLD);
};

const renderList = (pie, selector, data, colors) => {
  document.querySelector(selector + ' ul') &&
    document.querySelector(selector + ' ul').remove();

  let ulLegend = d3.select(selector).append('ul').attr('class', 'legend');

  let keys = ulLegend
    .selectAll('.key')
    .data(data)
    .enter()
    .append('li')
    .attr('class', 'legend-item');

  keys
    .html(
      (item, index) =>
        `
        <div class="item">
          <span
            class="legend-circle"
            style="background-color: ${colors[index]};"
          ></span>
          ${item.alias}
        </div>
    `
    )
    .on('mouseover', function (d, i) {
      if (pie.innerArrow) {
        pie.innerArrow.moveTo(d.id);
      } // Evento que relaciona el hover y anima el grafico.
    });
};

const renderDataLibre = async (data) => {
  const indicators = data.indicators;
  const arrDataLibre = indicators.map((elemnt, index) => {
    return {
      id: elemnt.id,
      composited: elemnt.composited,
      alias: elemnt.alias,
      name: elemnt.short_name,
      value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
    };
  });
  let graphLibreLegends = document.querySelector(
    '#gycCo2WidgetView .widget-custom .widget-summary'
  );
  const arrDataLibreUp = arrDataLibre.splice(2);

  graphLibreLegends.innerHTML = '';
  let reverDataLibre = arrDataLibre.reverse();
  reverDataLibre.map((el, index) => {
    graphLibreLegends.innerHTML += `

      <div class="widget-summary-item">
        <div class="value">
            <div class="value ${
              el.id === 10033 ? 'is-big-number' : 'is-small-number is-bold'
            }">
            ${Utils.toNumber(el.value, el.id === 10033 ? 1 : 0, 'comma')}
            <span class="unit ${el.id === 10033 ? 'is-small-number' : ''}">${
      el.value ? (el.id === 10033 ? '%' : 'MW') : ''
    }</span>
          </div>
        </div>
      </div>
      `;
  });

  let graphLibreLegendsL = document.querySelector(
    '#gycCo2WidgetView .aside-summary'
  );

  graphLibreLegendsL.innerHTML = '';
  arrDataLibreUp.map((el, index) => {
    graphLibreLegendsL.innerHTML += `
      <div class="widget-summary-item widget-summary-item-${el.id}" >
        <div class="label"> ${el.alias}</div>
           <div class="value">
              <div class="is-small-number">
              ${Utils.toNumber(el.value, 0)}
              ${el.value ? `<span class="unit">MW</span>` : ''}
              </div>
           </div>
      </div>    
      `;
  });
};

const renderDataMore = async (data) => {
  const indicators = data.indicators;

  const arrDataMore = indicators.map((elemnt, index) => {
    return {
      id: elemnt.id,
      alias: elemnt.alias,
      name: elemnt.short_name,
    };
  });

  let graphMore = document.querySelector('.esios-also__list.row');

  graphMore.innerHTML = '';
  arrDataMore.map((item) => {
    graphMore.innerHTML += `
    <li class="esios-also__item col-6">
    <span class="esios-also__icon-Statistics"></span>
    <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${
      item.id
    }">${item.alias}</a>
  </li>
      `;
  });
};

const piePotencia = (indicators, disponible) => {
  const filterPie = [472, 473, 474, 475, 476, 477, 478, 479];
  const colorPie = [
    '#0090d1',
    '#007CF9',
    '#464394',
    '#ad5c34',
    '#9C7562',
    '#ffcc66',
    '#ba0f16',
    '#e66c71',
  ];
  /** LISTA DE DATA QUE SE RENDERIZARÁ EN EL PIE DE PONTENCIA Y EN LA LEYENDA */
  const pieDataRender = indicators
    .filter((el) => filterPie.includes(el.id))
    .map((elemnt, index) => {
      return {
        id: elemnt.id,
        composited: elemnt.composited,
        alias: elemnt.alias,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : '-',
        color: colorPie[index],
      };
    });
  /** CONFIGURACIÓN BASE PARA CREAR EL PIE */
  let configPie = {
    target: d3.select('.piePotencia').node(),
    innerArrow: true,
    innerRadius: 0.6,
    hoverFade: 1,
  };

  /** INSTANCIA Y CREACIÓN DEL PIE */
  let pie = new Charicharts.Pie(configPie, pieDataRender);

  /* CREACIÓN DEL TEXTO CENTRAL EN EL PIE  */
  const piePotenciaHtml = document.querySelector('.piePotencia');
  piePotenciaHtml.insertAdjacentHTML(
    'beforeend',
    `  
    <div class="chartLegend">
      <h3 class="titlePie"></h3>
      <div class="valuePie">
      </div>
    </div>`
  );

  /** OBTENEMOS LOS ELEMENTOS DEL TITULO Y EL VALOR PARA ACTUALZIARLOS CADA VEZ QUE REALIZEMOS UN HOVER POR EL PIE */
  let pieTitleHtml = document.querySelector(
    '.piePotencia>.chartLegend>.titlePie'
  );
  let pieValueHtml = document.querySelector(
    '.piePotencia>.chartLegend>.valuePie'
  );
  hoverPie(pieTitleHtml, pieValueHtml, pie);

  // ASPECT RATIO (?)
  var aspect = 200 / 200,
    chart = d3.select('.piePotencia');

  d3.select(window).on('resize', function () {
    var targetWidth = chart.node().getBoundingClientRect().width;
    chart.attr('width', targetWidth);
    chart.attr('height', targetWidth / aspect);
  });

  /**CREACIÓN DE LEYENDA */
  let ulLegend = d3
    .select('.legend-potencial-pie ')
    .append('ul')
    .attr('class', 'legend')
    .style('margin-top', '30px');

  let keys = ulLegend
    .selectAll('.key')
    .data(pieDataRender)
    .enter()
    .append('li')
    .attr('class', 'legend-item');

  keys
    .html(
      (item, index) =>
        `
    <div class="text-content">
      <span class="legend-circle" style="background: ${
        colorPie[index]
      };"></span>
      ${item.name}
    </div>
    <div class="value is-number ${item.value}">
      ${
        item.value === '-'
          ? '-'
          : Utils.toNumber((item.value * 100) / disponible, 1, 'comma')
      }
      <span class="unit">%</span>
    </div>
    `
    )
    .on('mouseover', function (d, i) {
      if (pie.innerArrow) {
        pie.innerArrow.moveTo(d.id);
      } // Evento que relaciona el hover y anima el grafico.
    });
};

const hoverPie = (title, value, pie) => {
  /** FUNCION PORPIA DEL PIE PARA REALIZAR EL HOVER Y ACTUALIZAR EL TEXTO CENTRAL */
  pie.on('Pie-piece/mouseover', function (info) {
    title.innerText = info.data.name;
    value.innerHTML = `
    ${info.value === 0 ? '-' : Utils.toNumber(info.value.toFixed(1), 1)}
    <div class="unit">MW</div>
  `;
  });
};
