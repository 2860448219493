import moment from 'moment-timezone';
import { parseDate } from '../../../utils/dateFormat';

import { getState } from '../../../utils/state';
import { getUrlParams } from '../../../utils/url';

export const CHART_TIME = {
  MIN10: 'MIN10',
  MIN15: 'MIN15',
  HOUR: 'HOUR',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

const CHART_TIME_VALUES = {
  MIN10: 10 * 60 * 1000,
  MIN15: 15 * 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const getDates = (from, to, step) => {
  const rFrom = new Date(from);
  const rTo = new Date(to);
  const dates = [];
  const rFromTime = rFrom.getTime();
  const rToTime = rTo.getTime();
  const possibleSteps = Object.keys(CHART_TIME_VALUES);
  if (rToTime < rFromTime || !possibleSteps.includes(step)) {
    return [];
  }

  if (step === CHART_TIME_VALUES.MONTH || step === CHART_TIME_VALUES.YEAR) {
    let currentTime = rFromTime;
    while (currentTime < rToTime) {
      const date = new Date(currentTime);
      if (CHART_TIME_VALUES.YEAR === step) {
        const year = date.getFullYear();
        currentTime = date.setFullYear(year + 1);
      } else {
        const month = date.getFullMonth();
        currentTime = date.setMonth(month + 1);
      }
      dates.push(date);
    }
  } else {
    for (let i = rFromTime; i < rToTime; i += CHART_TIME_VALUES[step]) {
      const date = new Date(i);
      dates.push(date);
    }
  }

  return dates;
};

export const zipXY = (x, y) => {
  const result = [];
  for (let i = 0; i < x.length, i < y.length; i++) {
    result.push({ x: x[i], y: y[i] });
  }
  return result;
};

const mapclours = new Map([
  [544, '#74BA05'],
  [545, '#DF4A32'],
  [1293, '#FFCF09'],
  [1941, '#FF8F40'],
]);

const nameColorMapping = {
  1478: '#993300',
  1477: '#464394',
  1483: '#FFCC66',
  1489: '#CFA2CA',
  1480: '#BA0F0B',
  1296: '#9A5CBC',
  1488: '#9A5CBC',
  1485: '#6FB114',
  1486: '#E48500',
  1754: '#C91C17',
  1475: '#0090D1',
  1487: '#FF0000',
  1482: '#BA0F16',
  1479: '#8DA69F',
  1481: '#AF8E00',
  1484: '#00FFFF',
  1476: '#007CF9',
  1491: '#A0A0A0',
  10302: '#99FF33',
  1490: '#C0C0C0',
  1172: '#367DB6',
  555: '#0B68BB',
  554: '#EB6539',
  1751: '#FA3796',
  2043: '#E5DD87',
  10304: '#95C41A',
  1293: '#FFDD00',
  1178: '#0035a8',
  1179: '#006600',
  1181: '#221e68',
  1180: '#BA0F16',
  1176: '#809AD3',
  1175: '#09B280',
  1177: '#80BEC3',
  1176: '#DC87AA',
  623: '#F05F00',
  /*'Almacenamiento con baterías': '#006699',
  'Consumo de baterías': '#9BC1DB',
  'Almacenamiento': '#F05F00',
  'Consumo almacenamiento': '#7EBD00',
  'Instalación de demanda': '#0BC87D',
  'Hibridación de tecnologías mixtas': '#8C40A8',
  'Hibridación de tecnologías renovables': '#28A064',
  'Autoconsumo': '#AD7DC7'*/
};

const colours = [
  '#006699',
  '#85B200',
  '#FFCF09',
  '#00A2D8',
  '#D90000',
  '#717171',
  '#FD8FFF',
  '#FF7F00',
  '#34B473',
  '#F18F78',
  '#444444',
  '#557A02',
  '#962A11',
  '#6D199E',
  '#AE7DC9',
  '#A2546D',
  '#FFA64D',
  '#DDDDDD',
];

export const getColour = (index = 0) => {
  return colours[index % colours.length];
};

export const doValuesLogic = (setData, isBreakDown, analysis = '') => {
  let tabSelected;
  if (!analysis) {
    tabSelected = getState().analysis.tabSelected.label;
  }

  let addToDate = (value, numToAdd, type) => {
    let dateEnd, dateEndZ;
    dateEnd = moment(new Date(value.x)).add(numToAdd, type);
    dateEndZ = moment(new Date(value.id)).add(numToAdd, type);
    dateEnd = new Date(dateEnd);
    dateEndZ = new Date(dateEndZ);
    return { ...value, x: dateEnd, id: dateEndZ, z: dateEndZ };
  };

  tabSelected = analysis.tabSelected.label;
  let groupBy = analysis.groupby;
  let getLastPoint = (arr) => {
    let value = arr.values.at(-1);
    let groupbyIndicator = arr.tiempo[0]?.id;
    if (groupBy == 'year' || groupbyIndicator == 1) {
      return addToDate(value, 1, 'year');
    } else if (groupBy == 'month' || groupbyIndicator == 2) {
      return addToDate(value, 1, 'month');
    } else if (groupBy == 'day' || groupbyIndicator == 3) {
      return addToDate(value, 1, 'day');
    } else if (groupBy == 'hour' || groupbyIndicator == 4) {
      return addToDate(value, 1, 'hour');
    } else if (groupbyIndicator == 218) {
      return addToDate(value, 15, 'minute');
    } else if (groupbyIndicator == 225) {
      return addToDate(value, 10, 'minute');
    } else if (groupbyIndicator == 219) {
      return addToDate(value, 5, 'minute');
    } else {
      return arr.values.at(-1);
    }
  };
  if (setData.length === 0) {
    return [{ values: [] }];
  }
  setData = setData.map((data, i) => {
    if (mapclours.get(data.excelId) != null) {
      // If color is found in mapclours based on excelId
      return {
        id: `${data.id}`.replaceAll(' ', '_'),
        areaOpacity: 1,
        type: isBreakDown ? 'area' : 'line',
        dots: true,
        color: mapclours.get(data.excelId),
        ...data,
      };
    } else {
      // Busca el color correspondiente al id
      const matchingColor = nameColorMapping[data.id];

      if (matchingColor) {
        // Si se encuentra un id coincidente en nameColorMapping
        return {
          id: `${data.id}`.replaceAll(' ', '_'),
          areaOpacity: 1,
          type: isBreakDown ? 'area' : 'line',
          dots: true,
          color: matchingColor, // Usamos el color encontrado
          ...data,
        };
      } else {
        return {
          id: `${data.id}`.replaceAll(' ', '_'),
          areaOpacity: 1,
          type: isBreakDown ? 'area' : 'line',
          dots: true,
          color: getColour(i),
          ...data,
        };
      }
    }
  });

  if (analysis.indicator.id == 1293 && setData.length == 4) {
    let tempNewData = [...setData];
    setData[0] = tempNewData[1];
    setData[1] = tempNewData[2];
    setData[2] = tempNewData[3];
    setData[3] = tempNewData[0];
  } else if (analysis.indicator.id == 1293 && setData.length == 3) {
    let tempNewData = [...setData];
    setData[0] = tempNewData[1];
    setData[1] = tempNewData[2];
    setData[2] = tempNewData[0];
  }

  if (isBreakDown) {
    let mainIndicator = setData.shift();
    if (mainIndicator.values.length != 0) {
      mainIndicator.values = [
        ...mainIndicator.values,
        getUrlParams().groupby == 'minutes15' && getLastPoint(mainIndicator),
      ];
    }
    mainIndicator.type = 'line';
    const maxLengthIndicator = setData.reduce((p, c) =>
      c.values.length < p.values.length ? p : c
    ).values;
    const maxLength = maxLengthIndicator.length;
    setData = setData.filter((i) => i.values.length !== 0);
    setData = setData.map((i) => {
      let result = i;
      if (i.values.length !== maxLength) {
        const currentValues = i.values;
        result = { ...i, values: [] };
        for (let value of maxLengthIndicator) {
          const realXY = currentValues.find(
            (i) => i.x.getTime() === value.x.getTime()
          ) || {
            x: value.x,
            y: 0,
          };
          result.values.push(realXY);
        }
      }
      return result;
    });
    setData = [
      {
        type: 'area',
        stacking: true,
        data: setData.map((data, i) => ({
          areaOpacity: 1,
          stacking: true,
          type: 'area',
          mainIndicator: mainIndicator.id,
          color: getColour(i),
          ...data,
          values: [...data.values, getLastPoint(data)],
        })),
      },
    ];
    setData.push(mainIndicator);
  } else {
    setData = setData.map((data, i) => {
      // Guardamos length inicial de values
      let initialLength = data.values.length;
      let values = [...data.values];
      if (data.values.length > 0 && data.step_type == 'step') {
        values =
          getUrlParams().groupby == 'minutes15'
            ? [...data.values]
            : [...data.values, { ...getLastPoint(data) }];
      }
      if (
        getUrlParams().groupby != 'month' &&
        getUrlParams().groupby != 'year'
      ) {
        // Comprobamos si se agregaron nuevos valores después del if
        if (values.length > initialLength) {
          // Verificar los nuevos valores añadidos
          for (let i = initialLength; i < values.length; i++) {
            if (values[i].y !== null) {
              // Asignar null a los nuevos valores de y
              values[i].y = null;
            }
          }
        }
      }

      return {
        id: `${data.id}`.replaceAll(' ', '_'),
        areaOpacity: 1,
        type: isBreakDown ? 'area' : 'line',
        dots: true,
        color: getColour(i),
        ...data,
        values: [...values],
      };
    });
  }

  if (tabSelected === 'composition') {
    setData = setData.map((el) => {
      if (el.value < 0) {
        return {
          ...el,
          sign: '-',
          value: el.value * -1,
        };
      } else {
        return {
          ...el,
          sign: '',
        };
      }
    });
  }
  setData = setData.map((el) => {
    return {
      ...el,
      id: `${el.id}`.replaceAll(' ', '_'),
    };
  });

  return setData;
};

export const getUnit = (id) => {
  const units = {
    13: 'MWh',
    20: 'MW',
    21: 'MW',
    22: 'MV',
    23: '€/MWh',
    24: '€',
    152: '%',
    153: ' ',
    159: ' ',
    209: 'tCO2',
    211: '€/tCO2',
    217: 'tCO2/MW',
    238: '€/ton',
  };
  return units[id] || '';
};
export const getDecimal = (magnitud) => {
  var decimals = {
    24: 2,
    23: 2,
    152: 6,
    153: 0,
    159: 0,
    211: 3,
    217: 3,
    20: 0,
    13: 1, // for graph it should be 0
    238: 2,
  };
  return decimals[magnitud];
};

export const getDecimalAnalysisSumm = (magnitud) => {
  var decimals = {
    13: 1, //LISTO
    20: 1, // LISTO
    21: 1, // LISTO
    22: 0, // Pdte.
    23: 2, // LISTO
    24: 2, // Pdte.
    152: 6, // LISTO
    153: 0, // Pdte.
    159: 0, // Pdte.
    209: 1, // LISTO
    211: 3, // LISTO
    217: 3, // LISTO
    238: 2, // LISTO
  };
  let toReturn =
    decimals[magnitud] || decimals[magnitud] == 0 ? decimals[magnitud] : 1;
  return toReturn;
};
